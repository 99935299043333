// ActionAreaCard.jsx
import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { blue } from '@mui/material/colors';
import { makeStyles } from '@material-ui/core/styles';
import { fontSize } from '@mui/system';
const useStyles = makeStyles((theme) => ({
  customTypography: {
    fontFamily: 'Poppins, sans-serif',
    fontSize: '1.75em',
    fontStyle: 'bold',
    fontWeight: 'bold',
    lineHeight: 'normal',
    letterSpacing: '1px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.25em',
    },
  },
  image: {
    maxHeight: '100px', // Default max height
    objectFit: 'contain',
    [theme.breakpoints.down('lg')]: {
      maxHeight: '40px', // Adjust max height for large screens
    },
    [theme.breakpoints.down('md')]: {
      maxHeight: '200px', // Adjust max height for medium screens
    },
    [theme.breakpoints.down('sm')]: {
      maxHeight: '10px', // Adjust max height for small screens
    },
    [theme.breakpoints.down('xs')]: {
      maxHeight: '5px', // Adjust max height for extra small screens
    },
  },
}));

export default function ActionAreaCard({ title, value, content,  image, width, color}) {
  const classes = useStyles();
  return (
    <Card sx={{ width: width, p: '0.5%', height:'19vh' }}>
      <CardActionArea>
        <CardContent>
        
          <Typography className={classes.customTypography} fontSize="25px" color={color} component="div">
          <img src={image} alt="Description"  className={classes.image} />&nbsp;
            <strong>{value}</strong> <span style={{color:'#626F86', fontSize:"15px"}}>{content}</span>
          </Typography>
          
          <br/>
          <Typography variant="body2" color="#626F86">
          {title}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
