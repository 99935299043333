import { createSlice } from "@reduxjs/toolkit";
import { getatsJoblistThunk, submitJobThunk } from "../services/atsthunk";

const initialState = {
  status: 'idle',
  error: null,
  jobs: []
};

export const atsSlice = createSlice({
  name: 'ats',
  initialState,
  reducers: {
  },
  extraReducers: builder => {
    builder
      
      .addCase(getatsJoblistThunk.pending, (state) => {
        state.status = 'loading';
        state.specificState = 'loading';
      })
      .addCase(getatsJoblistThunk.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.jobs = action.payload;
        state.specificState = 'succeeded';
      })
      .addCase(getatsJoblistThunk.rejected, (state, action) => {
        state.status = 'failed';
        state.specificState = 'failed';
        state.error = action.error.message;
      })
      .addCase(submitJobThunk.pending, (state) => {
        state.status = 'loading';
        state.specificState = 'loading';
      })
      .addCase(submitJobThunk.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.specificState = 'succeeded';
      })
      .addCase(submitJobThunk.rejected, (state, action) => {
        state.status = 'failed';
        state.specificState = 'failed';
        state.error = action.error.message;
      });
  }
});

export default atsSlice.reducer;
