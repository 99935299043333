import React, { useState, useEffect } from 'react';
import JSZip from 'jszip';
import { Button, Typography, Box } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { styled } from '@mui/system';
import Tooltip from '@mui/material/Tooltip';


const CustomTypography = styled(Typography)`
  color: var(--lt-text-dark, #2F3645);
  font-family: 'Poppins', sans-serif; /* Make sure the Poppins font is available */
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* equivalent to 183.333% */
`;
const SupportedFormatsText = styled('div')`
  color: var(--text-colour-light-text, #8F8F8F);
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
`;
const ResumeUploader = ({ onFilesChange, previouszip }) => {
  const [resumeFileNames, setResumeFileNames] = useState([]);
  const [zip,setZip] = useState(new JSZip());
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'application/pdf, application/msword',
    onDrop: async (acceptedFiles) => {
      if(previouszip){
        setZip(previouszip)
      }
      setResumeFileNames(acceptedFiles.map(file => file.name));

      await Promise.all(acceptedFiles.map(async file => {
        const blob = await file.arrayBuffer();
          zip.file(file.name, blob)
      }));

      onFilesChange(zip);
    }
  });

  return (
    <div className="resumesUploader">
      <Box sx={{ width: '100%' }}>
        <Typography variant="subtitle1" gutterBottom>
          <br/>
          Resumes 
        </Typography>
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <Box
            sx={{
              border: '2px dashed #ccc',
              padding: 3,
              minHeight: 100,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              bgcolor: isDragActive ? '#f0f0f0' : 'rgba(71, 135, 210, 0.10)',
            }}
          >
            <Box sx={{ textAlign: 'center'}}>
              <svg width="24" height="24" viewBox="0 0 24 24">
                <circle cx="12" cy="12" r="10" fill="#4787D2" />
                <line x1="12" y1="6" x2="12" y2="18" stroke="white" strokeWidth="2" />
                <line x1="6" y1="12" x2="18" y2="12" stroke="white" strokeWidth="2" />
              </svg>
              {isDragActive ? (
              <CustomTypography variant="body1"> Drop the files here ...</CustomTypography>
            ) : (
              
              <CustomTypography variant="body1">  Drag & drop resumes here or <span style={{ color: '#4787D2' }}>browse</span>
              <br/>
              <Tooltip title="pdf and word" placement="top">
          <SupportedFormatsText>
            View supported formats ⓘ
          </SupportedFormatsText>
        </Tooltip>
              </CustomTypography>
            )}
          </Box>
              
            </Box>
            
        </div>
        <div className="uploaded-files-list">
          {resumeFileNames.map((fileName) => (
            <div key={fileName}>{fileName}</div>
          ))}
        </div>
      </Box>
    </div>
  );
};

export default ResumeUploader;
