import React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

import Badge from '@mui/material/Badge';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function BadgedCardTemplate({ title, borderColor, children, comingSoon = false, badges = [] }) {
  return (
    <Card
      variant="outlined"
      style={{
        borderLeft: borderColor ? `6px solid ${borderColor}` : '6px solid #EAF0F9',
        borderTopLeftRadius: '10px',
        borderBottomLeftRadius: '10px',
        backgroundColor: "#F8FAFD",
        paddingBottom: "1px",
        height: "77px",
        position: 'relative',
      }}
    >
      <CardHeader
        style={{ padding: "0px" }}
        title={
          <Box display="flex" alignItems="center">
            <Typography variant="h6" component="div">
              {title}
            </Typography>
            <Box display="flex" ml={1}>
              {badges.map((badge, index) => (
                <Badge
                  key={index}
                  overlap="circular"
                  badgeContent=" "
                  style={{
                    backgroundColor: badge.color,
                    width: '30px',
                    height: '22px',
                    borderRadius: '50%',
                    marginLeft: index > 0 ? '12px' : 0,
                    display: 'flex',            // Use flexbox
                    alignItems: 'center',       // Center vertically
                    justifyContent: 'center',   // Center horizontally
                  }}
                >
                  <Typography variant="caption" style={{ color: 'white', fontSize: '12px' }}>
                    {badge.value}
                  </Typography>
                </Badge>
              ))}
            </Box>
          </Box>
        }
      />
      <CardContent
        style={{
          padding: "0px",
          paddingLeft: "5px"
        }}
      >
        {children}
      </CardContent>
      {comingSoon && (
        <div style={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.35)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'black',
          zIndex: 1,
          padding: "0px"
        }}>
          Coming Soon...
        </div>
      )}
    </Card>
  );
};


function BadgeAndContentCardTemplate({ title, borderColor, alterBackground = false, children, comingSoon = false, badges = [] }) {
  return (
    <Card
      variant="outlined"
      style={{
        borderLeft: borderColor ? `6px solid ${borderColor}` : '6px solid #EAF0F9',
        borderTopLeftRadius: '10px',
        borderBottomLeftRadius: '10px',
        backgroundColor: alterBackground ? "lightgray" : "#F8FAFD",
        paddingBottom: "1px",
        height: "auto",  // Adjust to fit content
        position: 'relative',
        display: 'flex',
        alignItems: 'flex-start',  // Align items at the start
      }}
    >
      <Box display="flex" flexDirection="column" alignItems="flex-start" padding="0px" width="40%">
        <CardHeader
          style={{
            padding: "0px",
            marginBottom: '2px',  // Space between title and badges
            width: '100%',
          }}
          title={
            <Typography variant="h6" component="div">
              {title}
            </Typography>
          }
        />
        <Box
          sx = {{ ml: "2px"}}
          display="flex"
          flexWrap="wrap"  // Allow badges to wrap to new lines if needed
          justifyContent="center"  // Center badges horizontally
          gap="8px"  // Space between badges
        >
          {badges.map((badge, index) => (
            <Box key={index} display="flex" flexDirection="column" alignItems="center">
              <Badge
                overlap="circular"
                badgeContent=" "
                style={{
                  backgroundColor: badge.color,
                  width: '22px',
                  height: '22px',
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography variant="caption" style={{ color: 'white', fontSize: '9px' }}>
                  {badge.value}
                </Typography>
              </Badge>
              <Typography variant="caption" style={{ marginTop: '4px', fontSize: '9px' }}>
                {badge.title}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>

      <CardContent
        style={{
          padding: "0px",
          paddingLeft: "5px",
          width: "60%",  // Take the remaining width
        }}
      >
        {children}
      </CardContent>

      {comingSoon && (
        <div style={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.35)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'black',
          zIndex: 1,
        }}>
          Coming Soon...
        </div>
      )}
    </Card>
  );
}

function BadgesTemplate({ title, borderColor, alterBackground = false, children, comingSoon = false, badges = [] }) {
  return (
    <Card
      variant="outlined"
      style={{
        borderLeft: borderColor ? `6px solid ${borderColor}` : '6px solid #EAF0F9',
        borderTopLeftRadius: '10px',
        borderBottomLeftRadius: '10px',
        backgroundColor: alterBackground ? "lightgray" : "#F8FAFD",
        paddingBottom: "1px",
        height: "auto",  // Adjust to fit content
        position: 'relative',
        display: 'flex',
        alignItems: 'flex-start',  // Align items at the start
      }}
    >
      <Box display="flex" flexDirection="column" alignItems="flex-start" padding="0px" width="100%">
        <CardHeader
          style={{
            padding: "0px",
            marginBottom: '2px',  // Space between title and badges
            width: '100%',
          }}
          title={
            <Typography variant="h6" component="div">
              {title}
            </Typography>
          }
        />
        <Box
          sx = {{ ml: "2px"}}
          display="flex"
          flexWrap="wrap"  // Allow badges to wrap to new lines if needed
          justifyContent="center"  // Center badges horizontally
          gap="8px"  // Space between badges
        >
          {badges.map((badge, index) => (
            <Box key={index} display="flex" flexDirection="column" alignItems="center">
              <Badge
                overlap="circular"
                badgeContent=" "
                style={{
                  backgroundColor: badge.color,
                  width: '22px',
                  height: '22px',
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography variant="caption" style={{ color: 'white', fontSize: '9px' }}>
                  {badge.value}
                </Typography>
              </Badge>
              <Typography variant="caption" style={{ marginTop: '4px', fontSize: '9px' }}>
                {badge.title}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Card>
  );
}

function CardTemplate({ title, borderColor, alterBackground, children, comingSoon = false }) {
  return (
    <Card 
      variant="outlined" 
      style={{ 
        borderLeft: borderColor ? `6px solid ${borderColor}` : '6px solid #EAF0F9', 
        borderTopLeftRadius: '10px', 
        borderBottomLeftRadius: '10px', 
        backgroundColor: alterBackground ? "lightgray" : "#F8FAFD", 
        paddingBottom: "1px", 
        height: "77px",
        position: 'relative' // added this line
      }} 
    >
      <CardHeader style={{ padding: "0px" }} title={<Typography variant="h6" component="div">
              {title}
            </Typography>} />
      <CardContent style={{
          padding:"0px",
          paddingLeft:"5px"
        }} >
        {children}
      </CardContent>
      {comingSoon && (
        <div style={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.35)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'black',
          zIndex: 1,
          padding:"0px"
        }}>
          Coming Soon...
        </div>
      )}
    </Card>
  );
}

function EducationCard(props) {
  return <CardTemplate title="Education" {...props} />;
}

function ExperienceCard(props) {
  return <BadgeAndContentCardTemplate title="Experience" {...props} />;
}

function TasksCard(props) {
  return <BadgeAndContentCardTemplate title="Concerns"  {...props} />;
}

function ConcernsCard(props) {
  return <BadgesTemplate title="Concerns"  {...props} />;
}

function HopsCard(props) {
  return <CardTemplate {...props} />;
}

function CultureCard(props) {
  return <CardTemplate title="Culture" {...props} />;
}

function LocationCard(props) {
  return <CardTemplate title="Location" {...props} />;
}

export { EducationCard, ExperienceCard, TasksCard, HopsCard, CultureCard, LocationCard, ConcernsCard };
