import { createAsyncThunk } from "@reduxjs/toolkit";
import * as service from "./searchservice";  


export const searchJobsThunk = createAsyncThunk(
    'jobs/search',
    async (searchText) => {
      const jobs = await service.searchJobs(searchText);
      return jobs;
    }
);
