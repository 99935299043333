import React, { useState, useEffect } from 'react';
import { Box, Typography, Radio } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { getatsJoblistThunk } from '../services/atsthunk';
import { DataGrid } from '@mui/x-data-grid';

const ATSlist = ({handlejobchange}) => {
  const [selectedReqId, setSelectedReqId] = useState('');
  const jobsListRedux = useSelector((state) => state.ats.jobs || []);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getatsJoblistThunk());
  }, [dispatch]);

  const handleChange = async (event) => {
    await setSelectedReqId(JSON.parse(event.target.value).req_id);
    await handlejobchange(JSON.parse(event.target.value))
  };

  const columns = [
    {
      field: 'select',
      headerName: '',
      width: 50,
      renderCell: (params) => (
        <Radio
          checked={selectedReqId === params.row.req_id.toString()}
          onChange={handleChange}
          value={JSON.stringify(params.row)}
        />
      ),
    },
    { field: 'req_id', headerName: 'Req ID', width: 80 },
    { field: 'title', headerName: 'Title', width: 350 },
    { field: 'recruiter', headerName: 'Recruiter', width: 150},
    {field: 'job_id', headerName: 'job ID', width:200 }
  ];

  const rows = jobsListRedux.map((job, index) => ({
    id: index, // DataGrid requires each row to have an 'id' property
    req_id: job.req_id,
    title: job.title,
    recruiter: job.recruiter,
    job_id : job.job_id
  }));

  return (
    <div style={{ height: 400, width: '100%' }}>
      <Typography variant="subtitle1" gutterBottom>
        Job Description
      </Typography>
      <Box sx={{ height: 300, width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
        />
      </Box>
    </div>
  );
};

export default ATSlist;
