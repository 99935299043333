import { createAsyncThunk } from "@reduxjs/toolkit";
import * as service from "./resume_service"; // Changed to camelCase
function isMissingYear(value) { 
  const yearRegex = /(?:\D|^)(\d{2}|\d{4})(?:\D|$)/;
  if ( typeof value === 'string') {
    return ( value.toLowerCase().trim() === 'not specified' || 
        !yearRegex.test(value) 
      );
  }
  let strValue = value.toString();
  return !yearRegex.test(strValue);
  }

export const getResumesThunk = createAsyncThunk(
    'resume/getResumes', // Changed to camelCase
    async (jobId) => { // Changed to camelCase
      const resumes = await service.getResumes(jobId); // Changed to camelCase
    // Calculate displayTitle for each resume
    const updatedResumes = resumes.map( (row, index) => {
      console.log(`Processing row ${index}:`, row);
      let workExperience = row.resumeData?.extracted?.workExperience || [];
      
      // Initialize an empty object for the industry experience hash
      let industryExperienceHash = {};
      let numberOfJobs = 0;
      let numberOfJobsMissingStartDate = 0;
      let avgTenure = 0.0;
      let tenureDenom = 0;
      // Iterate through workExperience and accumulate years of experience by industry
      if (typeof workExperience === 'object' && workExperience !== null && !Array.isArray(workExperience)) {
        workExperience = [ workExperience ];
      }

      const validateStartDateYears = (data) => {
        let lastCompany = null;
        let lastRoleWasValid = false;
        let numberMissingStartDate = 0;
        let thisRoleIsValid = false;
        data.forEach( (item) => {
          const date = new Date(item.corrected_startDateOrYear);
          if (!(isNaN(date) || isNaN(item.corrected_years_of_experience)) ) {
            thisRoleIsValid = true;
          }
          else {
            thisRoleIsValid = false;
          }
          if (!thisRoleIsValid) {
            // Was last role with same company, and that was valid?
            if ((lastCompany === item.companyName) && lastRoleWasValid) {
              thisRoleIsValid = true;
            }
            else { 
              numberMissingStartDate += 1;
            }
          }
          lastCompany = item.companyName;
          lastRoleWasValid = thisRoleIsValid;
        });
        return numberMissingStartDate;
      };
      
     const missingDateCount = validateStartDateYears(workExperience);

      const filteredData = workExperience.filter(item => {
        const date = new Date(item.corrected_startDateOrYear);
        return ! (isNaN(date) || isNaN(item.corrected_years_of_experience));
      })
      const sortedData = filteredData.sort((a, b) => {
        // First, compare by corrected_startDateOrYear
        const dateA = new Date(a.corrected_startDateOrYear);
        const dateB = new Date(b.corrected_startDateOrYear);
      
        if (dateA < dateB) return -1;
        if (dateA > dateB) return 1;
      
        // If dates are equal, then compare by corrected_years_of_experience
        return a.corrected_years_of_experience - b.corrected_years_of_experience;
        });


      sortedData.forEach(item => {
//        console.log("Processing workexperience:", item);
        numberOfJobs += 1;
        avgTenure += item.corrected_years_of_experience;
        tenureDenom += 1;
//        console.log("computed numberOfJobs:", numberOfJobs, "numberOJobsMissingStartDate:", numberOfJobsMissingStartDate, "avgTenure:", avgTenure, "tenureDenom:", tenureDenom);
        let industry = 'unknown';
        if ('companyIndustry' in item) {
          industry = item.companyIndustry.toLowerCase();
        }
        if (!industryExperienceHash[industry]) {
          industryExperienceHash[industry] = 0;
        }
        industryExperienceHash[industry] += item.corrected_years_of_experience || 0;
      });
      const careerDuration = avgTenure;
      if (tenureDenom > 1) {
        avgTenure /= tenureDenom;
      }
      const updatedRow = {
      ...row,
      displayTitle:
        row.resumeData?.extracted?.workExperience?.[0]?.role ||
        row.resumeData?.extracted?.workExperience?.role ||
        row.resumeData?.components?.atsCandidateInfo?.title,
      lowerDisplayTitle: 
        (row.resumeData?.extracted?.workExperience?.[0]?.role ||
        row.resumeData?.extracted?.workExperience?.role ||
        row.resumeData?.components?.atsCandidateInfo?.title)?.toLowerCase(),
      jobs: numberOfJobs,
      tenure: avgTenure,
      career: careerDuration,
      industryExperienceHash:industryExperienceHash,
      numberOfJobsMissingStartDate: missingDateCount,
      };
      console.log(`Updated row ${index}:`, updatedRow);
      return updatedRow;
    });      
      return updatedResumes;
    }
);

export const getCandidatesThunk = createAsyncThunk(
    'resume/getCandidates', // Changed to camelCase
    async (path) => {
      const candidates = await service.getCandidates(path); // Changed to camelCase
      return candidates;
    }
    
);
export const getCandidateonScreenThunk = createAsyncThunk(
'resume/getcandidateonscreen',
async (path)=>{
  if (path) {
 //   console.log("Got here in resume_thunk for path", path)
    const candidates = await service.getCandidateonScreen(path); // Changed to camelCase
    return candidates;
  }
  else {
    return null;
  }
}

)

export const updatedecisionThunk = createAsyncThunk(
  'resume/Decision',
  async({jobId,resumeId,decision,userName,reason,reasonId, templateName, templateId, schedule, targetStage=null, unrejectFirst=false}) => {
    console.log("log from update ", jobId,resumeId,decision,reason,templateName,schedule, targetStage, unrejectFirst)
    const j = await service.updatedecision(jobId,resumeId,decision,userName,reason,reasonId,templateName, templateId, schedule, targetStage, unrejectFirst);
    return j;
  }
); 

export const bulkUpdatedecisionThunk = createAsyncThunk(
  'resume/Decision',
  async({jobId,resumeIds,decision,userName,reason,reasonId, templateName, templateId, schedule, bulkAction}) => {
    console.log("log from update ", jobId,resumeIds,decision,reason,templateName,schedule)
    const j = await service.bulkUpdatedecision(jobId,resumeIds,decision,userName,reason,reasonId,templateName, templateId, schedule, bulkAction);
    return j;
  }
); 