import React, { useState, useRef, useEffect } from 'react';
import ReactSlider from 'react-slider';

import './ControlledTextInput.css';

const ControlledTextInput = ({ initialTextValue, initialSliderValue, placeholder, saveTextValue, saveSliderValue, sliderMax }) => {
  const [textValue, setTextValue] = useState(initialTextValue);
  const [sliderValue, setSliderValue] = useState(initialSliderValue);
  const [containerWidth, setContainerWidth] = useState('90%');
  const inputRef = useRef(null);
  const sliderRef = useRef(null);
  const min = 0;
  useEffect(() => {
    if (inputRef.current) {
      setContainerWidth(`${inputRef.current.offsetWidth}px`);
    }
  }, [inputRef.current?.offsetWidth]);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setTextValue(newValue);
    saveTextValue(newValue, sliderValue);
  };

  const handleSliderChange = (newSliderValue) => {
    setSliderValue(newSliderValue);
    saveSliderValue(textValue, newSliderValue);
  };


  return (
    <div>
      <input
        ref={inputRef}
        type="text"
        value={textValue}
        onChange={handleInputChange}
        placeholder={placeholder}
        style={{  marginTop: "4px",  width: "160px" , color: textValue ? 'black' : 'gray' }}
      />
      <div className="slider-container">
        <ReactSlider
          className="horizontal-slider"
          thumbClassName="thumb"
          trackClassName="track"
          ref={sliderRef}
          min={0}
          max={sliderMax}
          value={sliderValue}
          onChange={handleSliderChange}
          style={{ color: 'white'}}
          renderThumb={(props, state) => (
            <div {...props}
            style={{...props.style, 
            backgroundColor: "#1976d2"}}>
            {state.valueNow}
           </div>
            
          )}
        />
      </div>
    </div>
  );
};

export default ControlledTextInput;
