import React, { useState, useEffect } from "react";

import axios from "axios";

import {
  Typography,
  Box,
  Grid,
  Button,
  InputBase,
  IconButton,
  Card,
  CardContent,
  Avatar,
} from "@mui/material";

import { makeStyles } from "@material-ui/core/styles";
import { submitJobThunk } from "../services/atsthunk";

import DialogComponent from "../components/dialoguebox/dialogue";

import { useDispatch } from "react-redux";

import {
  createJobThunk,
  getJobThunk,
} from "../services/jobthunk";

import ATSlist from "../utilities/atslist";

import { Search as SearchIcon } from "@mui/icons-material";
import { Close as CloseIcon } from "@mui/icons-material";
import { current } from "@reduxjs/toolkit";


const useStyles = makeStyles({
  root: {
    width: "100%", // Reducing the length
    marginLeft: 0, // Aligning to the left
    backgroundColor: "rgba(71, 135, 210, 0.10)",
    padding: "2%",
    paddingRight: "45%",
    textAlign: "left",
  },
});

const CreateJob = ({ show, handleClose }) => {
  const [btn, setbtn] = useState(true)
  const [activeStep, setActiveStep] = useState(0);
  const [file, setfile] = useState("");
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const API_BASE = process.env.REACT_APP_API;
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [currentjob,setcurrentjob] = useState({});
  

  const handlejobchange=async (job)=>
  {
    if(job){
      console.log("currgot",job)
      await setcurrentjob(job)
    }
  }

  const API = `${API_BASE}/main/get-users`;

  const dispatch = useDispatch();

  // Fetch users from the server
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const token = localStorage.getItem('token');

        const response = await axios.get(API, {
            headers: {
                'Authorization': token
            }
        });
        setUsers(response.data.data);
      } catch (error) {
        console.error("Failed to fetch users", error);
      }
    };
    fetchUsers();
  }, []);



  const handleClose2 = () => {
    // Call handleClose from the prop
    handleClose();
    setcurrentjob({})
    // Reset all fields
    setActiveStep(0);
    setUsers([]);
    dispatch(getJobThunk());
  };

  

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    if (value) {
      const filtered = users.filter(
        (s) =>
          (s.userName.toLowerCase().includes(value.toLowerCase()) || 
          s.email.toLowerCase().includes(value.toLowerCase())) &&
          !selectedUsers.some((u) => u.userId === s.userId) 
      );
      setFilteredSuggestions(filtered);
    } else {
      setFilteredSuggestions([]);
    }
  };

  const handleCardClick = (user) => {
    // Check if the user is already selected
    if (
      !selectedUsers.some((selectedUser) => selectedUser.userId === user.userId)
    ) {
      setSelectedUsers((prevUsers) => [...prevUsers, user]);
      // Re-filter suggestions to remove the selected user
      const updatedSuggestions = filteredSuggestions.filter(
        (s) => s.userId !== user.userId
      );
      setFilteredSuggestions(updatedSuggestions);
    }
  };

  const handleRemoveUser = (userId) => {
    setSelectedUsers((prevUsers) =>
      prevUsers.filter((u) => u.userId !== userId)
    );
  };

  const getInitials = (name) => {
    return name
      .split(" ")
      .slice(0, 2)
      .map((word) => word[0])
      .join("")
      .toUpperCase();
  };

  const getColorForName = (name) => {
    let hash = 0;
    let i;

    for (i = 0; i < name.length; i += 1) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
  };

  const handleSubmit = () => {
    setbtn(false)
    if (currentjob && Object.keys(currentjob).length > 0) {
      let collaborators = selectedUsers.map((user) => ({
        userId: user.userId,
        role: "collaborator",
      }));
      let data = {
        ...currentjob,
        collaborators: collaborators,
      };
      console.log("Collaborators", collaborators);

      const temp = dispatch(submitJobThunk(data));
      console.log("currsubmit",data)
      handleClose2();
      setbtn(true)
    } else {
      console.log("All fields are required");
      setbtn(true)
    }
  };

  

  const stepActions =
    (
      <>
        <Button onClick={handleClose2}>Cancel</Button>
        <Button
          variant="contained"
          style={{ backgroundColor: "#4787D2", color: "#FFF" }}
          onClick={() => {
            if (btn) {
             
              handleSubmit();
            }
          }}
          disabled={!btn}
        >
          {!btn ? 'Submitting...' : 'Submit'}
        </Button>
      </>
    );

  return (
    <DialogComponent show={show} title="Add New Job From ATS" actions={stepActions}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          {activeStep === 0 && (
            <>
              <Grid item xs={8} sm={8}>
                <ATSlist handlejobchange={handlejobchange}/>
              </Grid>

                <Grid item xs={8}>
                <Typography variant="subtitle1" gutterBottom>
                  Add Collaborators
                </Typography>
                <InputBase
                  fullWidth={true}
                  startAdornment={
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  }
                  placeholder="Search for User Name, Email ID"
                  onChange={handleSearchChange}
                />
                {filteredSuggestions.length > 0 && (
                  <div>
                    {filteredSuggestions.map((suggestion) => (
                      <Card
                        key={suggestion.userId}
                        style={{ margin: "8px 0" }}
                        onClick={() => handleCardClick(suggestion)}
                      >
                        <CardContent
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <Avatar
                            src={suggestion.image}
                            sx={{
                              backgroundColor: getColorForName(suggestion.userName),
                              marginRight: "8px",
                            }}
                          >{getInitials(suggestion.userName)}
                          </Avatar>
                          <div>
                            <div>{suggestion.userName}</div>
                            <small>{suggestion.email}</small>
                          </div>
                        </CardContent>
                      </Card>
                    ))}
                  </div>
                )}
              </Grid>
              <Grid item xs={8}>
                {selectedUsers.length > 0 && (
                  <div style={{ marginTop: "20px" }}>
                    <Typography variant="subtitle1" gutterBottom>
                      Your Collaborators
                    </Typography>
                    {selectedUsers.map((user) => (
                      <Card
                        key={user.userId}
                        style={{

                          margin: "8px 0",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Avatar
                          src={user.image}
                          sx={{
                            backgroundColor: getColorForName(
                              user.userName
                            ),
                            marginRight: "8px",
                          }}
                        >
                           {getInitials(user.userName)}
                          </Avatar>
                        <div style={{ flexGrow: 1 }}>
                          <div>{user.userName}</div>
                          <small>{user.email}</small>
                        </div>
                        <IconButton
                          onClick={() => handleRemoveUser(user.userId)}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Card>
                    ))}
                  </div>
                )}
              </Grid>
            </>
          )}

          
        </Grid>
      </Box>
    </DialogComponent>
  );
};

export default CreateJob;
