import { createSlice } from "@reduxjs/toolkit";
import { insightThunk } from "../services/insightThunk"; // Changed to camelCase

const initialState = {
  insight: {},
  status: 'idle',
  error: null,
};

export const insightSlice = createSlice({
    name: 'insight',
    initialState,
    reducers: {
    },
    extraReducers: builder => {
      builder
        
        .addCase(insightThunk.pending, (state) => { // Changed to camelCase
          state.status = 'loading';
          state.insight=[{}];
          state.specificState = 'loading'; // Fixed typo
        })
        .addCase(insightThunk.fulfilled, (state, action) => { // Changed to camelCase
          state.status = 'succeeded';
          state.insight = action.payload[0]; 
        })
        .addCase(insightThunk.rejected, (state, action) => { // Changed to camelCase
          state.status = 'failed';
          state.error = action.error.message;
        })
    }
});
  
export default insightSlice.reducer;
