import React, { useState, useEffect } from 'react';
import { Container, Typography, RadioGroup, FormControlLabel, Radio, Switch, Button, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from "react-redux";
import { updatebasicThunk } from '../services/settingsThunk';
import { dispatch } from 'd3';


const useStyles = makeStyles({
    title3: {
      color: "var(--text-colour-dark, #172B4D) !important",
      fontFamily: "Poppins !important",
      fontSize: "30px !important",
      fontStyle: "bold !important",
      fontWeight:"700 !important",
      lineHeight: "22px !important", 
      backgroundColor:"inherit",
    },
    heading: {
        color: "var(--text-colour-dark, #172B4D) !important",
        fontFamily: "Poppins !important",
        fontSize: "22px !important",
        fontWeight: "600 !important" ,
        lineHeight: "20px !important", 
      },
      customFont: {
        color: '#172B4D !important',
        fontFamily: 'Poppins !important',
        fontSize: '14px !important',
        fontStyle: 'normal !important',
        fontWeight: "400 !important",
        lineHeight: 'normal !important',
        letterSpacing: '-0.26px !important',
      },
  });

function Basicsettings() {
  const [userType, setUserType] = useState(null);
  const [multipleProfiles, setMultipleProfiles] = useState(null);
  const [hasChanged, setHasChanged] = useState(false);
  const settings = useSelector((state) => state.settings.basic);
  const classes = useStyles();
  const dispatch = useDispatch()

  useEffect(() => {

    if (settings) {
      
      setUserType(settings?.inviteUsers);
      setMultipleProfiles(settings?.multipleprofiles); // Assuming your store has 'True' or 'False' as strings
    }
  }, [settings]);
  useEffect(() => {
    setHasChanged(false);
  }, []);

  const handleUserTypeChange = (event) => {
    setUserType(event.target.value);
    setHasChanged(true);
  };

  const handleMultipleProfilesChange = () => {
    setMultipleProfiles((prev) => !prev);
    setHasChanged(true);
  };

  const handleSave = async () => {
   await dispatch(updatebasicThunk({update:{"inviteUsers":userType,"multipleprofiles":multipleProfiles}}))
    // After saving, you would want to set hasChanged back to false
    setHasChanged(false);
    window.location.reload();
  };

  return (
    <>
    <br/>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography className={classes.title3} gutterBottom>
          Basics
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          disabled={!hasChanged} // This will disable the button when hasChanged is false
        >
          Save
        </Button>
      </Box>
      <br/>
      <Container style={{ backgroundColor: "#FFFFFF" }}>
      <br/>
      <Typography className={classes.heading} gutterBottom>
          Inviting New Users
        </Typography>
        <Typography classes={classes.customFont} paragraph>
          The following user types are allowed to invite new users to blujin:
          </Typography>
        <RadioGroup value={userType} onChange={handleUserTypeChange}>
          <FormControlLabel value="administrators" control={<Radio />} label="Administrators" />
          <FormControlLabel value="all_users" control={<Radio />} label="All users" />
        </RadioGroup>
        
        <hr />
        <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography className={classes.heading} gutterBottom>
          Multiple Profiles
        </Typography>
        <Switch checked={multipleProfiles} onChange={handleMultipleProfilesChange} />
        </Box>
        <Typography classes={classes.customFont} paragraph>
          Enable users to create multiple profiles for each job.
        </Typography>
        <br/>
        <br/>
      </Container>
    </>
  );
}

export default Basicsettings;
