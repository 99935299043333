import axios from 'axios';

//const API_BASE = 'http://127.0.0.1:8000';
const API_BASE = process.env.REACT_APP_API;
const API = `${API_BASE}/main/`;

// Create an axios instance with default headers
const instance = axios.create({
  baseURL: API,
  headers: {}
});
// Add a request interceptor to the instance
instance.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = token;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export const searchJobs = async (searchText) => {
    try {
      console.log('Searching for:', searchText);
      const response = await instance.get('search', { params: { searchtext: searchText }});
      return response.data; 
    } catch (error) {
      console.error("Error in searchJobs:", error);
      throw error;
    }
  };
  
