// InsightsTable.jsx
import React, { useEffect, useRef, useState } from 'react';
import { Grid } from '@mui/material';
import { insightThunk } from '../services/insightThunk';
import { useDispatch, useSelector } from 'react-redux';
import ActionAreaCard from '../components/actionareacard/actionareacard'; // Adjust path as necessary
import insight1 from "../assets/images/job_insight1.svg";
import insight2 from "../assets/images/newCandidates.svg";
import insight3 from "../assets/images/insight3.svg";
import insight4 from "../assets/images/insight4.svg";
import insight5 from  "../assets/images/insight5.svg";

const InsightsTable = () => {
  const gridContainerRef = useRef(null);
  const [gridItemWidth, setGridItemWidth] = useState('auto');
  const dispatch = useDispatch();
  const currentinsight = useSelector((state) => state.insight.insight);

  useEffect(() => {
    dispatch(insightThunk());
  }, [dispatch]);
  
  useEffect(() => { if (gridContainerRef.current) { 
    const containerWidth = gridContainerRef.current.clientWidth; 
    const itemWidth = (containerWidth / 5) - 7; 
    setGridItemWidth(itemWidth + 'px'); } 
    
  }, []);
  

  

  const insightsData = [
    { title: 'Open > 90 Days', value: currentinsight?.Insight_1, content: "jobs", image: insight1, color: "#4787D2" },
    { title: 'Contain New Qualified Candidates ', value: currentinsight?.Insight_2, image: insight2,color: "#E39B55",content: "jobs"},
    { title: 'Contain Candidates that Needs Attention', value: currentinsight?.Insight_3,image: insight3, color: "#45BBED", content: "jobs"},
    { title: 'To Review', value: currentinsight?.Insight_4, image:insight4, color:"#E3677C",content: "jobs" },
    { title: 'Need More Candidates', value: currentinsight?.Insight_5, image:insight5, color:"#45A623", content: "jobs" },
    
  ];

  return (
    <div style={{marginTop:"8px"}}>
      <Grid container ref={gridContainerRef} columns={10} >
        {insightsData.map((insight, index) => (
          <Grid item xs={2} key={index} style={{ height: "100%" }}>
            <ActionAreaCard
              title={insight.title}
              content={insight.content}
              image={insight.image}
              width={gridItemWidth}
              value={insight?.value}
              color={insight.color}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default InsightsTable;
