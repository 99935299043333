import axios from 'axios';
import mammoth from 'mammoth'


//const API_BASE = 'http://127.0.0.1:8000';
const API_BASE = process.env.REACT_APP_API;
const API = `${API_BASE}/main/resume/`;

// Create an axios instance with default headers
const instance = axios.create({
  baseURL: API,
  headers: {}
});
// Add a request interceptor to the instance
instance.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = token;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export const getResumes = async (jobId) => {
  try {
    console.log('getting resumes for jobId: ', jobId);
    const response = await instance.get('getresumes', { params: { jobId: jobId }});
    return response.data.data;
  } catch (error) {
    console.error("Error in getResumes:", error);
    throw error;
  }
};

export const getCandidates = async (path) => {
  try {
    const response = await instance.get('preview', {
      params: { filepath: path },
      responseType: 'blob'
    });

    const file = new Blob([response.data], { type: response.data.type });
    console.log("type", path)
    
    const fileURL = URL.createObjectURL(file);
    if (response.data.type === 'application/pdf' ) {
      window.open(fileURL);
      
    } else {
      // Create an anchor element and trigger download
      const downloadLink = document.createElement('a');
      downloadLink.href = fileURL;
      downloadLink.download = path.split('/').pop(); // Extract filename from path and use it as the download name
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      // If not a Word document, open in a new tab
      
    }
    return fileURL;
  } catch (error) {
    console.error("Error in getCandidates:", error);
    throw error;
  }
};
export const getCandidateonScreen = async (path) => {
  console.log("Got here in resume_service for path", path)
  try {
    const response = await instance.get('preview', {
      params: { filepath: path },
      responseType: 'blob'
    });

    const fileExtension = path.split('.').pop().toLowerCase();
    let fileType = 'application/pdf';

    // if (fileExtension === 'doc') {
    //   fileType = 'application/msword';
    // } else if (fileExtension === 'docx') {
    //   fileType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    // }

    const file = new Blob([response.data], { type: fileType });
    const fileURL = URL.createObjectURL(file);

    // if (fileExtension === 'doc' || fileExtension === 'docx') {
    //   const arrayBuffer = await response.data.arrayBuffer();
    //   const htmlContent = await mammoth.convertToHtml({ arrayBuffer: arrayBuffer })
    //     .then(result => result.value)
    //     .catch(error => console.error('Error converting DOCX to HTML:', error));

    //   const blob = new Blob([htmlContent], { type: 'text/html' });
    //   const htmlURL = URL.createObjectURL(blob);
    //   return htmlURL;
    // }

    return fileURL;
  } catch (error) {
    console.error("Error in getCandidates:", error);
    throw error;
  }
};

export const updatedecision = async (jobId,resumeId, decision,userName, reason, reasonId, templateName, templateId, schedule, targetStage, unrejectFirst) => {
  console.log("in updatedecision got template id", templateId)
  const job = { 'jobId': jobId, 'resumeId':resumeId, 'decision': decision,"userName": userName, "reason":reason,"reasonId":reasonId, "templateName": templateName, "templateId": templateId, "schedule": schedule};
  if (decision === 'Advanced') {
    job['targetStage'] = targetStage
    job['unrejectFirst'] = unrejectFirst
  }
  console.log("job data being passed to candidate-decision is", job);
  const response = await instance.post('candidate-decision', job);
  console.log(response);
  return response;
};

export const bulkUpdatedecision = async (jobId,resumeIds, decision,userName, reason, reasonId, templateName, templateId, schedule, bulkAction) => {
  console.log("in updatedecision got template id", templateId)
  const job = { 'jobId': jobId, 'resumeIds':resumeIds, 'decision': decision,"userName": userName, "reason":reason,"reasonId":reasonId, "templateName": templateName, "templateId": templateId, "schedule": schedule, "bulkAction": bulkAction};
  const response = await instance.post('bulk-candidate-decision', job);
  console.log(response);
  return response;
};
