import React, { useState, useEffect, useRef } from 'react';
import { useSelector} from "react-redux";
import axios from "axios";
import DialogComponent from '../components/dialoguebox/dialogue';
import { Box, Grid, Button,Paper,FormControl,InputLabel,Menu,MenuItem,Select, Switch, menuItemClasses} from '@mui/material';
import { getCandidateonScreenThunk } from '../services/resume_thunk';
import { useDispatch } from 'react-redux';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import GraphCard from './graphcard';
//import EmailTemplatePopup from './emailTemplatePopup';
import RecordDecision from '../recordDecision/recordDecision.js';
import { makeStyles } from '@material-ui/core/styles';
import calender from "../../src/assets/images/calender.svg";
import greenhouse_icon from "../../src/assets/images/GREENHOUSE_ICON_GREEN.svg";
import email from "../../src/assets/images/email.svg";
import CircularProgress from '@mui/material/CircularProgress';
import ExplanationTable from './explanationtable';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import SparklineChart from '../components/charts/SparkLineComponent';
import VerticalBarChart from '../components/charts/VerticalBarComponent';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { updatedecisionThunk } from '../services/resume_thunk';

import { EducationCard, ExperienceCard, HopsCard, TasksCard, CultureCard, LocationCard, ConcernsCard} from './othercards';
import { Navigate } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import { ConstructionOutlined, NoBackpackSharp } from '@mui/icons-material';
import { maxWidth } from '@mui/system';




const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 200,
    border: 'none',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  select: {
    border: 'none',
    margin: 0,
  },
}));


function CandidateView({ show, selectedCandidate, decisionchange, onClose, onNext, alert, alertBeg, trans, handleskillsvireChange, onPrev, jobTitle,
  setSelectedCandidate,
  savedReason, savedAdvancedSchedule, savedAdvancedTemplate, savedDeclinedSchedule, savedDeclinedTemplate,
  setSavedReason, setSavedAdvancedSchedule, setSavedAdvancedTemplate, setSavedDeclinedSchedule, setSavedDeclinedTemplate,
  myStorage, resetCandidate, fromSearch, setFromSearch, jobDetails
  }) {
  const classes = useStyles();
  const [numChanges,setNumChanges]=useState(0);
  const API_BASE = process.env.REACT_APP_API;
  const [selectedOption, setSelectedOption] = useState('secondGrid');
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const [pdfSrc, setPdfSrc] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [index, setindex] = useState(0);
  const [filters, setFilters] = useState(['High', 'Medium', 'Low']);
  const [recencyFilters, setRecencyFilters] = useState(['0', '1', '2', '3', '4+']);
  const [decisionType, setDecisionType] = useState('');
  const [anchorReason, setAnchorReason] = useState(null);
  const [anchorEmailTemplate, setAnchorEmailTemplate] = useState(null);
  const [decisionDialogOpen, setDecisionDialogOpen] = useState(false);
  const [advancedStagesMenuOpen, setAdvancedStagesMenuOpen] = useState(false);
  const [selectedStage, setSelectedStage] = useState(null);
  const [advancePossible, setAdvancePossible] = useState(false);
  const settings = useSelector((state) => state.settings.basic);
  const reasonOpen = Boolean(anchorReason);
  const emailTemplateOpen = Boolean(anchorEmailTemplate);
  const [decisionData, setDecisionData] = useState({});  
  const API = `${API_BASE}/main/resume/get-candidate-data`;
  const [detailedCandidateData, setDetailedCandidateData] = useState({});
  const [avgTenureInRole, setAvgTenureInRole] = useState(0.0);
  const [avgTenureInCompany, setAvgTenureInCompany] = useState(0.0);
  const [totalTenure, setTotalTenure] = useState(0.0);
  const [numberOfCompanies, setNumberOfCompanies] = useState(0);
  const [numberOfJobRoles, setNumberOfJobRoles] = useState(0);
  const [numberOfRoles, setNumberOfRoles] = useState(0);
  const [jobOverlap, setJobOverlap] = useState(0);
  const [jobGaps, setJobGaps] = useState(0);
  const [jobHops, setJobHops] = useState(0);
  const [HOP_THRESHOLD, setHOP_THRESHOLD] = useState(2.0);
  const [formattedExperienceData, setFormattedExperienceData] = useState({});
  const [cultureTable, setCultureTable] = useState(null);
  const [notWorkingSince, setNotWorkingSince] = useState(0);
  const [missingDateCount, setMissingDateCount] = useState(0);

  const millisecondsInAYear = 1000 * 60 * 60 * 24 * 365.25; // Account for leap years
  const navigate = useNavigate();

  const atsDeepLinkRef = useRef(null);
  
  const triggerATSLinkClick = () => {
    if (atsDeepLinkRef.current) {
      atsDeepLinkRef.current.click();
    }
  };

  const anchorAdvancedButtonRef = useRef(null);

  const svgStyle = {
    verticalAlign: 'middle', // Align the SVG vertically with the text
    width: '1em', // Set width equal to the font size
    height: '1em', // Set height equal to the font size
    fill: 'currentColor', // Use the current text color for SVG fill
  };

  function isMissingYear(value) { 
    const yearRegex = /(?:\D|^)(\d{2}|\d{4})(?:\D|$)/;
    if ( typeof value === 'string') {
      return ( value.toLowerCase().trim() === 'not specified' || 
          !yearRegex.test(value) 
        );
      }
    let strValue = value.toString();
    return !yearRegex.test(strValue);
    }
  
  function parseDate(dateOrYear) {
    // Regular expression for year only
    const yearOnlyRegex = /^\d{4}$/;
    // Regular expression for month/year with month in digits (e.g., "08/2022" or "08/22")
    const monthYearRegex = /^\d{1,2}\/\d{2,4}$/;
    // Regular expression for month in text and year (e.g., "August 2022")
    const monthTextYearRegex = /^(January|February|March|April|May|June|July|August|September|October|November|December)\s\d{2,4}$/i;
    // Regular expression for short month in text and year (e.g., "Aug 2022")
    const monthShortTextYearRegex = /^(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{2,4}$/i;
  
    if (yearOnlyRegex.test(dateOrYear)) {
      // Year only (e.g., "2022")
      return new Date(`${dateOrYear}-01-01`);
    } else if (monthYearRegex.test(dateOrYear)) {
      // Month and Year with month in digits (e.g., "08/2022" or "08/22")
      let [month, year] = dateOrYear.split('/');
      if (year.length === 2) {
        // Assume years "00" to "49" are in the 21st century (2000s), "50" to "99" are in the 20th century (1900s)
        year = parseInt(year, 10) < 50 ? `20${year}` : `19${year}`;
      }
      return new Date(`${year}-${month.padStart(2, '0')}-01`);
    } else if (monthTextYearRegex.test(dateOrYear)) {
      // Month in text and Year (e.g., "August 2022")
      return new Date(dateOrYear + '-01');
    } else if (monthShortTextYearRegex.test(dateOrYear)) {
      // Month in short text and Year (e.g., "Aug 2022")
      return new Date(dateOrYear + '-01');
    } else {
      // Try parsing as a full date or invalid format
      return new Date(dateOrYear);
    }
  }
        
    
        

  function computeTenure(startDateOrYear, endDateOrYear) {
    const startDate = parseDate(startDateOrYear);
  
    let endDate;
    if (!endDateOrYear || ! /.*\d+.*/.test(endDateOrYear)) {
      endDate = new Date(); // Current date
    } else {
      endDate = parseDate(endDateOrYear);
    }
  
    const diffTime = endDate.getTime() - startDate.getTime();
    const diffYears = diffTime / (1000 * 60 * 60 * 24 * 365.25); // Accounting for leap years
    console.log('start ', startDateOrYear, ' => ', startDate,  ' end ', endDateOrYear, ' => ', endDate, ' diffYears ', diffYears );
    return diffYears; // Return result rounded to two decimal places
  }

  useEffect( () => { 
    // Sort the array by corrected_startDateOrYear first to ensure proper grouping
    let workExperience = detailedCandidateData.resumeData?.extracted?.workExperience || [];
    const validateStartDateYears = (data) => {
      let lastCompany = null;
      let lastRoleWasValid = false;
      let numberMissingStartDate = 0;
      let thisRoleIsValid = false;
      data.forEach( (item) => {
        const date = new Date(item.corrected_startDateOrYear);
        if (!(isNaN(date) || isNaN(item.corrected_years_of_experience)) ) {
          thisRoleIsValid = true;
        }
        else {
          thisRoleIsValid = false;
        }
        if (!thisRoleIsValid) {
          // Was last role with same company, and that was valid?
          if ((lastCompany === item.companyName) && lastRoleWasValid) {
            thisRoleIsValid = true;
          }
          else { 
            numberMissingStartDate += 1;
          }
        }
        lastCompany = item.companyName;
        lastRoleWasValid = thisRoleIsValid;
      });
      console.log("Computed missingStartDate as ", numberMissingStartDate);
      return numberMissingStartDate;
    };
    
   setMissingDateCount(validateStartDateYears(workExperience));


    const filteredData = workExperience.filter(item => {
        const date = new Date(item.corrected_startDateOrYear);
        return ! (isNaN(date) || isNaN(item.corrected_years_of_experience));
    })
    const sortedData = filteredData.sort((a, b) => {
          // First, compare by corrected_startDateOrYear
      const dateA = new Date(a.corrected_startDateOrYear);
      const dateB = new Date(b.corrected_startDateOrYear);

      if (dateA < dateB) return -1;
      if (dateA > dateB) return 1;

      // If dates are equal, then compare by corrected_years_of_experience
      return a.corrected_years_of_experience - b.corrected_years_of_experience;
    });

    const numberFiltered = workExperience.length - filteredData.length;
    const companySummary = [];
    let overallTenure = 0.0
    let overallRoles = 0
    let currentCompany = null;
    

    sortedData.forEach(item => {
        overallRoles += 1;
        if (currentCompany && currentCompany.companyName === item.companyName) {
            // Update existing entry if company name matches
            currentCompany.overlapping_years_of_experience += item.corrected_years_of_experience;
            if (currentCompany.startDateOrYear > item.corrected_startDateOrYear) {
              currentCompany.startDateOrYear = item.correct_startDateOrYear;
            }
            if (currentCompany.endDateOrYear < item.corrected_endDateOrYear) {
              currentCompany.endDateOrYear = item.corrected_endDateOrYear;
            }
            currentCompany.number_of_roles += 1;
        } else {
            // Push previous entry to summary and start a new one
            if (currentCompany) {
                currentCompany.sequential_years_of_experience = ((new Date(currentCompany.endDateOrYear) - new Date(currentCompany.startDateOrYear))/millisecondsInAYear);
                overallTenure += currentCompany.sequential_years_of_experience;
                companySummary.push(currentCompany);
            }
            currentCompany = {
                companyName: item.companyName,
                startDateOrYear: item.corrected_startDateOrYear,
                endDateOrYear: item.corrected_endDateOrYear,
                overlapping_years_of_experience: item.corrected_years_of_experience,
                number_of_roles: 1
            };
        }
    });

    // Push the last entry
    if (currentCompany) {
      currentCompany.sequential_years_of_experience = ((new Date(currentCompany.endDateOrYear) - new Date(currentCompany.startDateOrYear))/millisecondsInAYear);
      overallTenure += currentCompany.sequential_years_of_experience;
      companySummary.push(currentCompany);
    }

    setTotalTenure(overallTenure);
    setNumberOfCompanies(companySummary.length);

    const sortedSummary = companySummary.sort((a, b) => {
      // First, compare by corrected_startDateOrYear
       const dateA = new Date(a.startDateOrYear);
       const dateB = new Date(b.startDateOrYear);

       if (dateA < dateB) return -1;
       if (dateA > dateB) return 1;

        // If dates are equal, then compare by corrected_years_of_experience
        return a.sequential_years_of_experience - b.sequential_years_of_experience;
    });


    if (sortedSummary.length) {
      console.log("Computed company summary", sortedSummary);
      setAvgTenureInCompany(overallTenure/sortedSummary.length);
      const formattedData =  sortedSummary.map((item, index) => ({
            x: item.companyName + item.startDateOrYear.slice(2,4),
            value: item.sequential_years_of_experience,      // Use sequential_years_of_experience as Y
            y: item.sequential_years_of_experience,
            startYear: "'" + item.startDateOrYear.slice(2,4),
            endYear: item.endDateOrYear.slice(2,4),
            company: item.companyName, 
          }));
      setFormattedExperienceData(formattedData);
      console.log("Formatted Data has been setup", formattedData);
    }
    else {
      setAvgTenureInCompany(0.0);
      setFormattedExperienceData([{ id: 'experience', data: []}]);
      console.log("Formatted Data has been setup as blank");
    }
    const calculateGapsAndHops = (jobs) => { 
      let totalGaps = 0;
      let totalHops = 0;
      
      if (jobs.length) {
        for (let i = 0; i < jobs.length-1; i++) {
          let a = new Date(jobs[i+1].startDateOrYear); 
          let b = new Date(jobs[i].endDateOrYear);
          if ( a > b) {
            totalGaps += a - b;
          }
          console.log("At company ", jobs[i].companyName, " and gap is now: ", totalGaps);
          if (jobs[i].sequential_years_of_experience < HOP_THRESHOLD) {
            totalHops += 1;
          } 
        }
      }
      totalGaps /= millisecondsInAYear;
      return { gaps: totalGaps, hops: totalHops};
    };
    const gapsAndHops = calculateGapsAndHops(sortedSummary);
    setJobGaps(gapsAndHops.gaps);
    setJobHops(gapsAndHops.hops);
    
    function monthsBetweenDates(startDate, endDate) {
      // Calculate the difference in years and months
      const yearDifference = endDate.getFullYear() - startDate.getFullYear();
      const monthDifference = endDate.getMonth() - startDate.getMonth();
  
      // Total number of months difference
      const totalMonths = yearDifference * 12 + monthDifference;
  
      return totalMonths;
  }
    const calculateNotWorkingSince = (jobs) => {
      let x_notWorkingSince = 99;
      if (jobs.length > 0)  {
        const lastWorked = new Date (jobs[jobs.length-1].endDateOrYear);
        const today = new Date();
        x_notWorkingSince = monthsBetweenDates(lastWorked, today);
        setNotWorkingSince(x_notWorkingSince);
      }
      // return x_notWorkingSince;
    };
    calculateNotWorkingSince(sortedSummary);
    // setNotWorkingSince(calculateNotWorkingSince(sortedSummary));

    const calculateTotalOverlap = (jobs) => {
      let totalOverlap = 0;
      for (let i = 0; i < jobs.length; i++) {
        for (let j = i + 1; j < jobs.length; j++) {
          const job1 = jobs[i];
          const job2 = jobs[j];
    
          const start1 = new Date(job1.startDateOrYear);
          const end1 = new Date(job1.endDateOrYear);
          const start2 = new Date(job2.startDateOrYear);
          const end2 = new Date(job2.endDateOrYear);
    
          if (start1 <= end2 && start2 <= end1) { // Check if jobs overlap
            const overlapStart = new Date(Math.max(start1, start2));
            const overlapEnd = new Date(Math.min(end1, end2));
    
            const overlap = (overlapEnd - overlapStart) / (1000 * 60 * 60 * 24 * 365); // Overlap in years
            totalOverlap += Math.max(0, overlap);
          }
        }
      }
      return totalOverlap;
    };
    
    const overlapYears = calculateTotalOverlap(sortedSummary);
    console.log(`Total overlap across all jobs: ${overlapYears.toFixed(2)} years`);
    setJobOverlap(overlapYears);

    if (overlapYears > 0) { 
      overallTenure -= overlapYears;
      setTotalTenure(overallTenure);
      setAvgTenureInCompany(overallTenure/sortedSummary.length);
    }

    const lengthCompanySortedData = sortedSummary.sort((a, b) => {
      // First, compare by length of service 
      if (a.sequential_years_of_experience < b.sequential_years_of_experience) {
        return 1;
      }
      if (a.sequential_years_of_experience > b.sequential_years_of_experience) {
        return -1;
      }
      return new Date(b.startDateOrYear) - new Date(a.startDateOrYear);
    });
    const cultureSummary =  () => {
      // Ensure we only take the first one or two rows, depending on availability     
      const cultureRows = lengthCompanySortedData.slice(0,2);
      return (
        <div >
          {cultureRows.length > 0 ? (
            <table border="0" cellPadding="2" style={{fontSize: "12px"}}>
              <tbody>
                {cultureRows.map((row, index) => (
                  <tr key={index}>
                    <td>{row.companyName.slice(0,15)}</td>
                    <td>{row.sequential_years_of_experience.toFixed(1)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No data available.</p>
          )}
        </div>
      );
    };   
    setCultureTable(cultureSummary);

    setNumberOfRoles(overallRoles);
    if (overallRoles) {
      setAvgTenureInRole(overallTenure/overallRoles);
    }
    else {
      setAvgTenureInRole(0.0);
    }


  }, [detailedCandidateData]);

//   useEffect( () => { 

//     const stats = {
//       workExperiences: {},
//       numberOfRecords: 0,
//       numberOfCompanies: 0,
//       numberOfRoles: 0,
//       totalTenureInRoles: 0.0,
//       totalTenureInCompanies: {},
//       companies: new Set(),
//       roles: new Set(),
//     };
//     let  nJobsMissingStartdate = 0; 
//     let workExperience = detailedCandidateData.resumeData?.extracted?.workExperience || [];
//     let prevCompany = "";
// //    console.log("Before workExperience: ", workExperience);
//     // Iterate through workExperience and accumulate years of experience by industry
//     if (!Array.isArray(workExperience) && typeof workExperience === 'object' && workExperience !== null) {
//       workExperience = [ workExperience ];
//     }
// //    console.log("After  workExperience: ", workExperience);
//     workExperience.forEach(exp => {
//       if (!('corrected_years_of_experience' in exp) || isMissingYear(exp.startDateOrYear )) { 
//         nJobsMissingStartdate += 1;
//         }
//       else {
//         stats.numberOfRecords += 1;
//         stats.companies.add(exp.companyName);
//         stats.roles.add(exp.role);
// //        let tenureInRole = computeTenure(exp.startDateOrYear, exp.endDateOrYear);
//         let tenureInRole = exp.corrected_years_of_experience;
//         stats.totalTenureInRoles += tenureInRole;
//         if (!stats.totalTenureInCompanies[exp.companyName]) {
//           stats.totalTenureInCompanies[exp.companyName] = 0;
//           }
//         stats.totalTenureInCompanies[exp.companyName] += tenureInRole;
//         }
//       });

//     const sortedData = [...workExperience].sort((a, b) => 
//         new Date(a.corrected_startDateOrYear) - new Date(b.corrected_startDateOrYear)
//     );

//     const formattedData = [
//       {
//         id: 'experience',
//         data: sortedData.map((item, index) => ({
//           x: item.corrected_startDateOrYear || index, // Use corrected_startDateOrYear or index as X
//           y: item.corrected_years_of_experience,      // Use corrected_years_of_experience as Y
//         })),
//       },
//     ];
    
//     setFormattedExperienceData(formattedData);

//     const numberOfCompanies = stats.companies.size;
//     const numberOfRoles = stats.roles.size;
    
//     const averageTenureInRole = stats.totalTenureInRoles / workExperience.length;
//     const averageTenureInCompany = Object.values(stats.totalTenureInCompanies).reduce((acc, tenure) => acc + tenure, 0) / numberOfCompanies;
    
//     setAvgTenureInRole(averageTenureInRole);
//     setNumberOfRoles(numberOfRoles);
//     setAvgTenureInCompany(averageTenureInCompany);
//     setNumberOfCompanies(numberOfCompanies);
//     setTotalTenure(stats.totalTenureInRoles);
//     setNumberOfJobRoles(stats.numberOfRecords);
//   }, [detailedCandidateData]);

  const handleDecisionInATS = async (decision, decisionType, targetStage) => { 
    const thisCandidate=detailedCandidateData
    const currentDate = new Date();  
    const formattedDate = currentDate.toDateString(); 
    const userDetails = JSON.parse(localStorage.getItem('userDetails')) 
    const userName = userDetails.userName;
    let dataUpdate = {};
    dataUpdate = { 'decision': decisionType, 'decisionDate': formattedDate, 'userName': userName,
    'jobId': thisCandidate?.jobId , 'resumeId':thisCandidate?._id};
    if (decisionType === 'Advanced') {
      dataUpdate['targetStage'] = targetStage
    }
    else if (decisionType === 'Undeclined and Advanced') {
      dataUpdate['decision'] = "Undeclined"
      const resp1 = await dispatch(updatedecisionThunk(dataUpdate));
      dataUpdate['decision'] = "Advanced"
      dataUpdate['targetStage'] = targetStage
    }
    
    const response = await dispatch(updatedecisionThunk(dataUpdate));
    updateDecisionData(thisCandidate)
  };
  
  const handleClickm = (event,decision) => {
//    if (settings?.ATS === true) {
    if (jobDetails?.blujinSource === "ATS") {
      if (decision === "Declined") {
        if (detailedCandidateData?.resumeData?.decision?.decision === "Declined") {
          setDecisionType("Undeclined");
          handleDecisionInATS(decision, "Undeclined");      
        }
        else {
          setDecisionType("Declined");
          setDecisionDialogOpen(true);
        }
      }
      else {
        if (decision === "Advanced") {
          const anchorRect = event.target.getBoundingClientRect();
          // setMenuPosition({ top: anchorRect.bottom + window.scrollY, left: anchorRect.left + window.scrollX });
          setMenuPosition({ top: event.target.offsetTop + event.target.offsetHeight + 5, left: event.target.offsetLeft });
          if (detailedCandidateData?.resumeData?.decision?.decision === "Declined") {
            setDecisionType("Undeclined and Advanced");
          }
          else {
            setDecisionType("Advanced");
          }
          setAdvancedStagesMenuOpen(true);      
        }
      }
    }
    else {
      let this_decision_type = decision;
      if (decision === "Declined") {
          if (detailedCandidateData?.resumeData?.decision?.decision === "Declined") {
            this_decision_type = "Undeclined";
          }
      }
      else if (decision === "Advanced") {
        if (detailedCandidateData?.resumeData?.decision?.decision === "Declined") {
          this_decision_type = "Undeclined and Advanced";
        }
      }
      handleDecision(this_decision_type);   
    }
  };


  function showThisTemplateForThisReason(reasonId, templateId, settings) {
    // Find the item in the Reason_Template_Mappings array that matches the reasonId and templateId
    const mapping = settings.Reason_Template_Mappings.find(
      item => item.reasonId === reasonId && item.templateId === templateId
    );
  
    // If a matching item is found, return true and the default value if present
    if (mapping) {
      return {
        show: true,
        default: mapping.default
      };
    } else {
      // If no matching item is found, return false
      return {
        show: false,
        default: null
      };
    }
  }
  

  
  function CircularProgressWithLabel(props) {
    return (
      <Box position="relative" display="inline-flex">
        <CircularProgress variant="determinate" {...props} />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="caption" component="div" color="textSecondary">
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    );
  }
  

  const handleRecencyFilterChange = (event) => {
    const value = event.target.value;
    if (event.target.checked) {
      // If the checkbox is checked, add the value to the recencyFilters array
      setRecencyFilters(prev => [...prev, value]);
    } else {
      // If the checkbox is unchecked, remove the value from the recencyFilters array
      setRecencyFilters(prev => prev.filter(item => item !== value));
    }
  };
  const handleFilterChange = (event) => {
    const value = event.target.value;
    if (event.target.checked) {
      // If the checkbox is checked, add the value to the filters array
      setFilters(prev => [...prev, value]);
    } else {
      // If the checkbox is unchecked, remove the value from the filters array
      setFilters(prev => prev.filter(item => item !== value));
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleChange = (value) => {
    setSelectedOption(value);
  };

  const handleClose = () => {
    setMenuOpen(false);
  };

  const handleMenuItemClick = (index) => {
    setindex(index)
    // Handle the logic when an option is clicked
    // For example, update the selectedOption based on the clicked menu item

    handleClose(); // close the menu after selecting an option
  };

  const handleDecision= async (decision,reason, template, schedule) => {
      const userDetails = JSON.parse(localStorage.getItem('userDetails')) 
      const userName = userDetails?.userName
      const response = await dispatch(updatedecisionThunk({ "jobId": selectedCandidate?.resumeData?.extracted?.jobId , "resumeId":selectedCandidate?.resumeData?.extracted?.resumeId, "decision": decision, "userName": userName, "reason":reason?.name, "reasonId": reason?.id , "templateName": template?.name, "templateId": template?.id, "schedule": schedule?.name}))
      updateDecisionData(selectedCandidate);
      onNext()

  }


  useEffect(() => {
    console.log("candidate", selectedCandidate);
    const fetchPDF = async () => {
    const redirectMessage = "<h2>The resume is of old format of Word<br/>This is not currently supported in Candidate review.<br />Please download the resume for your evaluation.</h2>";
    const b64_redirectMessage = btoa(redirectMessage);
      console.log('path', selectedCandidate?.resumeData?.components?.blobPath);
      // if (selectedCandidate?.resumeData?.components?.blobPath 
      //    && selectedCandidate.resumeData.components.blobPath.endsWith('.doc')) {
      //     setPdfSrc("data:text/html;base64,"+b64_redirectMessage);
      //    }
      // else {         
        try {
          const pdf = await dispatch(getCandidateonScreenThunk(selectedCandidate?.resumeData?.components?.blobPath));
          console.log("PDF Source:", pdf);
          setPdfSrc(pdf.payload);
        } 
        catch (error) {
          console.error("Error while fetching PDF:", error);
        }
    //  }
    };

    fetchPDF();

  }, [selectedCandidate, dispatch]);


  const fetchCandidateData = async () => {
    try {
      setDetailedCandidateData({})
      const token = localStorage.getItem('token');

      // Set up the query parameters
      const params = {};
      if (selectedCandidate) {
        params.resumeId = selectedCandidate?._id;
        params.jobId = selectedCandidate?.resumeData?.components?.jobId;
      }

      // Make the GET request with query parameters
      const response = await axios.get(API, {
        headers: {
          'Authorization': token
        },
        params: params  // Add the params to the request
      });
      if(response.data.data) {
        setDetailedCandidateData(response.data.data[0]);
      }
    } catch (error) {
      console.error("Failed to fetch users", error);
    }
  };
    // Fetch candidate data from the server
  useEffect(() => {
    if(selectedCandidate) {
        fetchCandidateData();
    }
  }, [selectedCandidate, API]);

  function getPriorityById(stages, id) {
    // Loop through the stages array
    for (let i = 0; i < stages.length; i++) {
        // Check if the current stage has the given ID
        if (stages[i].id === id) {
            // If found, return its priority
            return stages[i].priority;
        }
    }
    // If no stage with the given ID is found, return null or handle as needed
    return 0; // Or any default value
  }
  
  // function AdvancedStagesMenu ({stages, current_stage }) { 
  //   const dropdownRef = useRef(null);

  //   useEffect(() => {
  //     function handleClickOutsideMenu(event) {
  //       if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
  //         setAdvancePossible(false);
  //       }
  //     }
  
  //     document.addEventListener('mousedown', handleClickOutsideMenu);
  //     return () => {
  //       document.removeEventListener('mousedown', handleClickOutsideMenu);
  //     };
  //   }, []);
  
  //   const handleAdvancedStageChange = (selectedId) => {
  //     // Your implementation
  //   };
  
  //   const renderMenu = () => {
  //     if (!current_stage || !Array.isArray(stages) || stages.length === 0) {
  //       return (
  //         <li onClick={() => handleAdvancedStageChange(-1)} style={{ cursor: 'not-allowed', listStyle: 'none' }}>
  //           <button style={{ width: '100%', textAlign: 'left', padding: '5px', border: 'none', backgroundColor: 'grey', borderRadius: '3px' }}>No stages available</button>
  //         </li>
  //       );
  //     }
  
  //     const sortedStages = [...stages].sort((a, b) => a.priority - b.priority);
  //     const current_stage_priority = getPriorityById(stages, current_stage.id);
  //     const filteredStages = stages.filter(stage => stage.priority >= current_stage_priority);
  
  //     if (filteredStages.length === 0) {
  //       return (
  //         <li onClick={() => handleAdvancedStageChange(-1)} style={{ cursor: 'not-allowed', listStyle: 'none' }}>
  //           <button style={{ width: '100%', textAlign: 'left', padding: '5px', border: 'none', backgroundColor: 'grey', borderRadius: '3px' }}>At last stage</button>
  //         </li>
  //       );
  //     }
  
  //     return filteredStages.map((stage, index) => (
  //       <li key={stage.id} onClick={() => handleAdvancedStageChange(stage.id)} style={{ cursor: 'not-allowed', listStyle: 'none' }}>
  //         <button style={{ width: '100%', textAlign: 'left', padding: '5px', border: 'none', backgroundColor: index === 0 ? 'grey' : index === 1 ? 'darkblue' : 'rgb(115,115,234)', borderRadius: '3px' }}>{stage.name}</button>
  //       </li>
  //     ));
  //   };
  
  //   return (
  //     <div style={{ position: 'relative', display: 'inline-block' }}>
  //       {advancePossible && (
  //         <div style={{ position: 'absolute', top: 'calc(100% + 5px)', left: 0 }}>
  //           <ul ref={dropdownRef} style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: 0 }}>
  //             {renderMenu()}
  //           </ul>
  //         </div>
  //       )}
  //     </div>
  //   );
  // };

  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  // useEffect(() => {
  //   if (anchorAdvancedButtonRef?.current) {
  //     const anchorRect = anchorAdvancedButtonRef.current.getBoundingClientRect();
  //     setMenuPosition({ top: anchorRect.bottom + window.scrollY, left: anchorRect.left + window.scrollX });
  //   }
  // }, [anchorAdvancedButtonRef?.current]); // Depend on 'stages' so that the menu position is recalculated when 'stages' change
  

  function AdvancedStagesMenu ({ stages, current_stage }) {
    const dropdownRef = useRef(null);
    const [hoveredIndex, setHoveredIndex] = useState(-1);
    
    useEffect(() => {
      function handleClickOutsideMenu(event) {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setAdvancedStagesMenuOpen(false); // Close menu when clicking outside
        }
      }
  
      document.addEventListener('mousedown', handleClickOutsideMenu);
      return () => {
        document.removeEventListener('mousedown', handleClickOutsideMenu);
      };
    }, []);
    
    
    const handleAdvancedStageChange = (selectedId) => {
        // Your implementation
        // Assuming setSelectedStage is defined elsewhere
        setSelectedStage(selectedId);        
        handleDecisionInATS("Advanced", decisionType, selectedId);    
        setAdvancedStagesMenuOpen(false); // Close menu after selecting an option
    };
    
    const renderMenu = () => {
      if (!current_stage) {
        return (
          <li onClick={() => handleAdvancedStageChange(-1)} style={{ cursor: 'not-allowed', listStyle: 'none' }}>
            <button style={{ width: '300px', height: '30px', textAlign: 'left', padding: '3px', border: 'none', backgroundColor: 'lightgray', margin: '3px', borderRadius: '12px' }}>No current stage. Is this a prospect?</button>
          </li>
        );
      }
      if (!Array.isArray(stages) || stages.length === 0) {
        return (
          <li onClick={() => handleAdvancedStageChange(-1)} style={{ cursor: 'not-allowed', listStyle: 'none' }}>
            <button style={{ width: '300px', height: '30px', textAlign: 'left', padding: '3px', border: 'none', backgroundColor: 'lightgray', margin: '3px', borderRadius: '12px' }}>No stages available for job</button>
          </li>
        );
      }

      const sortedStages = [...stages].sort((a, b) => a.priority - b.priority);
      const current_stage_priority = getPriorityById(stages, current_stage.id);
      const filteredStages = stages.filter(stage => stage.priority >= current_stage_priority);
  
      if (filteredStages.length === 0) {
        return (
          <li onClick={() => handleAdvancedStageChange(-1)} style={{ cursor: 'not-allowed', listStyle: 'none' }}>
            <button style={{ width: '300px', height: '30px', textAlign: 'left', padding: '3px', border: 'none', backgroundColor: 'lightgray',  margin: '3px', borderRadius: '12px' }}>At last stage</button>
          </li>
        );
      }
  
      return filteredStages.map((stage, index) => (
        <li key={stage.id} onClick={() => handleAdvancedStageChange(stage.id)} style={{ cursor: 'pointer', listStyle: 'none' }}>
          <button 
            style={{ 
              width: '300px', 
              height: '30px', 
              textAlign: 'left', 
              padding: '3px', 
              border: hoveredIndex === -1 ? (index === 1 ? "3px solid" : 'none') : (index === hoveredIndex ? "3px solid" : 'none'), // Transparent border initially
              borderColor: hoveredIndex === -1 ? (index === 1 ? "darkblue" : 'none') : (index === hoveredIndex ? "darkblue" : 'none'), // Transparent border initially
              color: 'white', 
              backgroundColor: index === 0 ? 'grey' : 'rgb(115,115,234)', 
              borderRadius: '12px', 
              margin: '3px', // Add margin for spacing
              transition: 'border-color 0.3s' // Smooth transition for border color change
            }}
            onMouseEnter={() => setHoveredIndex(index)} // Track hovered item index
            onMouseLeave={() => setHoveredIndex(-1)} // Reset hovered index when mouse leaves
          >
            {stage.name}
          </button>
        </li>
      ));
    };
  
    return (
          <div style={{ position: 'absolute', top: menuPosition.top, left: menuPosition.left, maxWidth: null, zIndex: 9999}}>
            <ul ref={dropdownRef} style={{ backgroundColor: '#fff', padding: 0 }}>
              {renderMenu()}
            </ul>
          </div>
    );
}
  


  const StageButtons = ({ stages, currentStage}) => { 
    if (currentStage) {
    console.log("With currentStage as ", currentStage);
    console.log("Creating stage buttons for stages: ", stages);
    if (!Array.isArray(stages) || stages.length ===0 ) {
      return <div>No stages available</div>;
    }
    const sortedStages = [...stages].sort((a, b) => a.priority - b.priority);
    let reachedActiveStage = false;
    return (
    <div>
    { sortedStages.map((stage) => {
        const isActive = reachedActiveStage;
        if (stage.id === currentStage.id) { 
          reachedActiveStage = true;
        }
        const isSelected = stage.id === selectedStage;
        return (
          <>
          <button 
            key={stage.id}
            className={`stage-button ${isActive ? 'active' : ''} ${isSelected ? 'selected' : ''}` }
            disabled ={!isActive}
            onClick={() => {
                if (isActive) {
                  console.log(`Selected stage: ${stage.name}`);
                  setSelectedStage(stage.id); // Set the selected stage
                  // setDecisionDetailsSelected(true);
                }
              }}
          >
          {stage.name}
          </button>
          <br />
          </>
          );
        })}
    </div>
    );
  }; 
  }
  

  const closeDownScreen = () => { 
    if (fromSearch) {
      setFromSearch(false);
    }
    if (numChanges > 0) {
      if (decisionchange) { 
        decisionchange();
      };
    }
    const currentUrl = window.location.href;
    const urlBeforeQuestionMark = currentUrl.split('?')[0];
    if (currentUrl.includes("tab=resumes&candidate")) {
      if (resetCandidate) {
        resetCandidate();
      }
      console.log("url: ", urlBeforeQuestionMark);
      navigate("?tab=resumes");
//      navigate(-1);
    }
    onClose();
  }
  
  const updateDecisionData = (candidate) => {
    setSelectedCandidate(candidate);
    setNumChanges( (prevState) => {return prevState + 1;})
    if (settings?.ATS === true && jobDetails?.blujinSource && jobDetails?.blujinSource === 'ATS') {
      triggerATSLinkClick();
    }    
  };

  const formatDate = (dateString) => {
    if (!dateString) return null;
  
    // Extracting the decision date
    const decisionDateTime = new Date(dateString);
    // Formatting the date to mm-dd-yy
    const month = ('0' + (decisionDateTime.getMonth() + 1)).slice(-2);
    const day = ('0' + decisionDateTime.getDate()).slice(-2);
    const year = decisionDateTime.getFullYear().toString().slice(-2);
    return `${month}-${day}-${year}`;
  };

  const actions = [
    <Button onClick={closeDownScreen} color="primary">
      Close
    </Button>
  ];

  const [candidatePageRefresh, setCandidatePageRefresh] = useState(false);

  const handleRefreshCandidatePage = () => {
    setCandidatePageRefresh(prevState => !prevState);
  };

  return (
    <DialogComponent
      key={candidatePageRefresh}
      show={show}
      title={

          <span >
            Candidates Review&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {jobTitle}
          </span>
         
      }
      actions={actions}
    >  <>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={1}>
          <Grid item xs={12} sm={12} style={{ justifyContent: 'center', alignItems: 'center', placeItems: 'center' }}>
          {alert && <Alert severity="success">This is the end!</Alert>}
          {alertBeg && <Alert severity="success">This is the beginnning!</Alert>}
            <Card  style={{backgroundColor: "#F8FAFD"}}>
              <CardHeader style={{paddingBottom: "0px"}} title={
                 <div>
                    {selectedCandidate?.resumeData?.extracted?.personalDetails?.name 
                    || selectedCandidate?.resumeData?.components?.atsCandidateInfo?.first_name +" "+selectedCandidate?.resumeData?.components?.atsCandidateInfo?.last_name} 
                    &nbsp; 
                    <CircularProgressWithLabel 
                       value={trans ? selectedCandidate?.resumeData?.totalTransferable : selectedCandidate?.resumeData?.totalDirect} 
                    /> 
                    { selectedCandidate?.resumeData?.components?.atsDeepLink  && ( <> &nbsp; &nbsp; &nbsp; <a ref={atsDeepLinkRef} target="greenhouse_window" href={selectedCandidate.resumeData.components.atsDeepLink}><img alt="gh" style={svgStyle} src={greenhouse_icon} /></a></> )}
                    { detailedCandidateData?.resumeData?.components?.atsApplicantInfo?.current_stage?.name  && ( <> &nbsp; &nbsp; &nbsp; {detailedCandidateData?.resumeData?.components?.atsApplicantInfo?.current_stage?.name} </> )}
                </div>
              } />
            <CardContent style={{paddingTop: "3px"}}>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6} style={{ justifyContent: 'center', alignItems: 'center', placeItems: 'center' }}>
                  {detailedCandidateData?.resumeData?.extracted?.workExperience?.[0]?.role ? 
                      detailedCandidateData.resumeData.extracted.workExperience?.[0].role.slice(0,40)  : (
                        detailedCandidateData?.resumeData?.extracted?.workExperience?.role ? 
                          detailedCandidateData.resumeData.extracted.workExperience.role.slice(0,40)  : 
                            selectedCandidate?.resumeData?.components?.atsCandidateInfo?.title ?
                              selectedCandidate?.resumeData?.components?.atsCandidateInfo?.title.slice(0,40) :
                                ""
                  )} 
                  &nbsp;at&nbsp; 
                  {detailedCandidateData?.resumeData?.extracted?.workExperience?.[0]?.companyName ? 
                      detailedCandidateData.resumeData.extracted.workExperience?.[0].companyName.slice(0,35) : (
                        detailedCandidateData?.resumeData?.extracted?.workExperience?.companyName ? 
                          detailedCandidateData.resumeData.extracted.workExperience.companyName.slice(0,35) : 
                            selectedCandidate?.resumeData?.components?.atsCandidateInfo?.company ?
                              selectedCandidate?.resumeData?.components?.atsCandidateInfo?.company.slice(0,35) :
                                ""
                  )}
                  {/* {selectedCandidate?.resumeData?.components?.atsCandidateInfo?.title} @ {selectedCandidate?.resumeData?.components?.atsCandidateInfo?.company } */}
                  <br/>
                  {/* {selectedCandidate?.resumeData?.extracted?.personalDetails?.location} &nbsp; */}
                  <Box>
                    <img src={email} alt="email icon" /> &nbsp; {selectedCandidate?.resumeData?.extracted?.personalDetails?.email || selectedCandidate?.resumeData?.components?.atsCandidateInfo?.email_addresses[0]?.value}
                  </Box>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={calender} alt="calendar icon" /> &nbsp; Applied on : {selectedCandidate?.resumeData?.components?.loadDate}
                  </div>
                </Grid>
                <Grid ref={anchorAdvancedButtonRef} item xs={6} sm={6} style={{ justifyContent: 'center', alignItems: 'center', placeItems: 'center' }}>
  
                <div style={{ display: 'flex', gap: '15px' }}> 
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Button 
                      variant="contained" 
                      onClick={onPrev}
                      size="small"
                      disabled={fromSearch ? true: false}
                      sx={{
                        backgroundColor: '#E4EEFC',
                        '&:hover': { backgroundColor: '#D0E1FA' },
                        width: 100,
                        marginRight: 1,
                        color: "black",
                            }}
                    >
                    Previous
                    </Button>
                  </div>
                  <div tyle={{ display: 'flex', flexDirection: 'column', position: 'relative'}}>
                    <Button 
                      variant="contained" 
                      onClick={(event) => handleClickm(event, 'Advanced')}
                      size="small"
                      sx={{
                        backgroundColor: detailedCandidateData?.resumeData?.decision?.decision === "Advanced" ? '#D0E1FA' : '#D0E1FA',
                        '&:hover': { backgroundColor: '#D0E1FA' },
                        marginRight: 1,
                        width: 100,
                        color: "black",
                        borderColor: detailedCandidateData?.resumeData?.decision?.decision === "Advanced" ? 'green' : 'transparent', // Set border color based on condition
                        borderWidth: detailedCandidateData?.resumeData?.decision?.decision === "Advanced" ? 2 : 0, // Apply border width if decision is "Advanced"
                        borderStyle: 'solid',
                        pointerEvents: detailedCandidateData?.resumeData?.decision?.decision === "Advanced" ? 'none' : 'auto', // Prevent clicking if decision is "Advanced"
                      }}
                      disabled={detailedCandidateData?.resumeData?.decision?.decision === "Advanced"} // Disable button based on condition
                    >
                    {detailedCandidateData?.resumeData?.decision?.decision === "Advanced" ? 'Advanced' : 'Advance'}
                    </Button>
                    {detailedCandidateData?.resumeData?.decision?.decision === "Advanced" && (
                    <div style= {{ wordWrap: 'break-word', width: 100 }}>{detailedCandidateData?.resumeData?.decision?.userName || "ATS"}<br />{(detailedCandidateData?.resumeData?.decision?.decisionDate && formatDate(detailedCandidateData?.resumeData?.decision?.decisionDate)) || ""}
                    </div>)
                    }
                  </div>

                  <div style={{ display: 'flex', flexDirection: 'column' }}>

<Button 
  id="decline-button"
  variant="contained" 
  onClick={(event) => handleClickm(event, 'Declined')}
  size="small"
  sx={{
    backgroundColor: detailedCandidateData?.resumeData?.decision?.decision === "Declined" ? '#D0E1FA' : '#D0E1FA',
    '&:hover': { backgroundColor: '#D0E1FA' },
    width: 100,
    marginRight: 1,
    color: "black",
    borderColor: detailedCandidateData?.resumeData?.decision?.decision === "Declined" ? 'red' : 'transparent', // Set border color based on condition
    borderWidth: detailedCandidateData?.resumeData?.decision?.decision === "Declined" ? 2 : 0, // Apply border width if decision is "Declined"
    borderStyle: 'solid',
    pointerEvents: detailedCandidateData?.resumeData?.decision?.decision === "Declined" ? 'auto' : 'auto', // Prevent clicking if decision is "Decline"
  }}
  // // disabled={selectedCandidate?.resumeData?.decision?.decision === "Declined"
  // } // Disable button based on condition
>
{detailedCandidateData?.resumeData?.decision?.decision === "Declined" ? 'Unreject' : 'Reject'}
</Button>
{detailedCandidateData?.resumeData?.decision?.decision === "Declined" && (
  <div style= {{ wordWrap: 'break-word', width: 115 }}>{detailedCandidateData?.resumeData?.decision?.reason || "None" } <br/>{detailedCandidateData?.resumeData?.decision?.userName || "ATS"}<br/>{(detailedCandidateData?.resumeData?.decision?.decisionDate && formatDate(detailedCandidateData?.resumeData?.decision?.decisionDate)) || ""}
</div>)
}

</div>
<div style={{ display: 'flex', flexDirection: 'column' }}>

<Button 
    variant="contained" 
    onClick={onNext}
    disabled={fromSearch ? true: false}
    size="small"
    
    sx={{
      backgroundColor: '#E4EEFC',
      '&:hover': { backgroundColor: '#D0E1FA' },
      color: "black",
      width: 100,
          }}
    
            
          >
            Next     
          </Button>
          </div>
</div>
</Grid>
</Grid>

      

      </CardContent>

            </Card>
            </Grid>
            <Grid item xs={12} sm={6} style={{ justifyContent: 'center', alignItems: 'center', placeItems: 'center' }}>
                <iframe
                 src={pdfSrc}
                 style={{ width: '100%', height: '650px', border: 'none' }}
                 title="Embedded content"
                 onLoad = {(event) => {
                  const iframe = event.target;
                  if (iframe && iframe.contentDocument && iframe.contentDocument.contentType !== 'application/pdf') {
                    iframe.contentDocument.body.innerHTML = '<h2>Cannot display old Word .doc files</h2>';
                  }
                }}
                onError = {(event) => { 
                  const iframe = event.target;
                  iframe.contentDocument.body.innerHTML = '<h2>Cannot display old Word .doc files</h2>';
                }}
              ></iframe> 
            </Grid>

            <Grid item xs={12} sm={6} style={{ justifyContent: 'center', alignItems: 'center', placeItems: 'center' }}>
            <Grid container spacing={1}>
            <Grid item xs={3} style={{ justifyContent: 'center', alignItems: 'center', placeItems: 'center' }}> 
      {selectedCandidate?.resumeData?.scores?.length > 1 && (
        <FormControl fullWidth>

          <InputLabel id="profile-select-label">Profile</InputLabel>
          <Select
            labelId="profile-select-label"
            id="profile-select"
            value={index}
            label="Profile"
            onChange={(event) => handleMenuItemClick(event.target.value)}
            sx={{
              '.MuiSelect-select': { 
                paddingTop: '6px', 
                paddingBottom: '6px', 
                fontSize: '0.875rem' // Example for a smaller font size
              },
              '.MuiOutlinedInput-root': {
                height: '45px' // Adjust the height of the Select field
              }
            }}
          >
            {selectedCandidate?.resumeData?.scores?.map((score, index) => (
              <MenuItem key={index} value={index} onClick={() => handleMenuItemClick(index)} >
                {score.result[0].profileName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </Grid>
    <Grid item xs={3.5} style={{ justifyContent: 'center', alignItems: 'center', placeItems: 'center' }}> 
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Switch
        checked={trans}
        onChange={handleskillsvireChange}
      />
      <Typography
        style={{
          marginLeft: 8,
          color: trans ? '#4687CF' : '#000000' // Color changes based on the 'direct' state
        }}
      >
        Transferrable
      </Typography>
    </div>
    </Grid>
    {missingDateCount ?  
      <Grid item xs={4.5} style={{ justifyContent: 'center', alignItems: 'center', placeItems: 'center' }}> 
      <div style={{ display: 'flex', alignItems: 'center' }}>
      <Box
      sx={{
        display: 'inline-block',   // Adjust to fit the content
        padding: '2px 16px',       // Adjust padding as needed
        backgroundColor: 'lightgray', // Background color of the rectangle
        borderRadius: '12px',      // Rounded corners
        color: '#fff',             // Text color
      }}
    >
        <Typography
          align="center"
          style={{
            fontSize: '12px',    // Smaller font size
            lineHeight: '1.2',   // Tighter line height
            textJustify: "center",
            justifyContent: "center",
            marginLeft: 0,
            marginTop: 0,
            marginBottom: 0,
            marginRight: 0,
            backgroundColor: "lightgray",
            color: 'red' // Color changes based on the 'direct' state
          }}
        >
        Data issues detected <br />Analytics may be incorrect
        </Typography>
        </Box>
      </div>
      </Grid>
      :
      <div> </div>
  }

    <Grid item xs={6} style={{ justifyContent: 'center', alignItems: 'center', placeItems: 'center' }}>

    <EducationCard>
    <Typography variant="body" sx={{ display:"block",  marginTop: '1px', fontSize: '12px', lineHeight: '15px'  }}>
  { Array.isArray(detailedCandidateData?.resumeData?.extracted?.education) ? (
    detailedCandidateData?.resumeData?.extracted?.education?.[0]?.level && detailedCandidateData?.resumeData?.extracted?.education[0]?.major ? (
      `${detailedCandidateData.resumeData.extracted.education?.[0]?.level} in ${detailedCandidateData.resumeData.extracted.education[0].major}`
    ) : detailedCandidateData?.resumeData?.extracted?.education?.[0]?.level ? (
      detailedCandidateData.resumeData.extracted.education?.[0]?.level
    ) : detailedCandidateData?.resumeData?.extracted?.education?.[0]?.major ? (
      detailedCandidateData.resumeData.extracted.education?.[0]?.major
    ) : (
      "No education data available"
    )
  ) : (
    detailedCandidateData?.resumeData?.extracted?.education?.level && detailedCandidateData?.resumeData?.extracted?.education?.major ? (
      `${detailedCandidateData.resumeData.extracted.education.level} in ${detailedCandidateData.resumeData.extracted.education.major}`
    ) : detailedCandidateData?.resumeData?.extracted?.education?.level ? (
      detailedCandidateData.resumeData.extracted.education.level
    ) : detailedCandidateData?.resumeData?.extracted?.education?.major ? (
      detailedCandidateData.resumeData.extracted.education.major
    ) : (
      "No education data available"
    )
  )}
  </Typography>
</EducationCard>
    </Grid>
    <Grid item xs={6} style={{ justifyContent: 'center', alignItems: 'center', placeItems: 'center' }}>
    <LocationCard >
    <div>
    <Typography variant="body" sx={{ display:"block",  marginTop: '1px', fontSize: '12px', lineHeight: '15px'  }}>
    {detailedCandidateData?.resumeData?.extracted?.personalDetails?.location || detailedCandidateData?.resumeData?.components?.atsCandidateInfo?.addresses?.[0]?.value} {detailedCandidateData?.resumeData?.extracted?.job_driving_distance ? <>({detailedCandidateData.resumeData.extracted.job_driving_distance} miles) </>: ""}
    </Typography>
    </div>   
  </LocationCard>
    </Grid>
    <Grid item xs={6} style={{ justifyContent: 'center', alignItems: 'center', placeItems: 'center' }}>
    <ExperienceCard alterBackground={missingDateCount} badges={[
        { value: numberOfCompanies.toString(), color: "#70D7F6", title: "Jobs" },
        { value: avgTenureInCompany.toFixed(1), color: avgTenureInCompany > 2 ? "#4caf50" : "red", title: "Tenure"},
        { value: totalTenure.toFixed(1), color: "#3B5980" , title: "Career"},
  ]}>

      {formattedExperienceData && <VerticalBarChart formattedData={formattedExperienceData} />}

  </ExperienceCard>
    </Grid>

    <Grid item xs={3} style={{ justifyContent: 'center', alignItems: 'center', placeItems: 'center' }}>
    <ConcernsCard alterBackground={missingDateCount} badges={[
      {value: jobOverlap.toFixed(1), color: jobOverlap / totalTenure > 0.2 ? "red" : jobOverlap / totalTenure > 0 ? "#FFC000" : "#4caf50" , title: "Ovrlp" },
      {value: jobGaps.toFixed(1), color: jobGaps > 2 ? "red" : jobGaps > 1 ? "#FFC000" : "#4caf50" , title: "Gaps" },
      {value: jobHops.toFixed(0), color: jobHops > 2 ? "red" : jobHops > 1 ? "#FFC000" : "#4caf50" , title: "Hops" },
      {value: notWorkingSince.toFixed(0), color: notWorkingSince > 6 ? "red" : notWorkingSince > 3 ? "#FFC000" : "#4caf50" , title: "OOW" },
    ]} >
    
    </ConcernsCard>
 
    </Grid>
    <Grid item xs={3} style={{ justifyContent: 'center', alignItems: 'center', placeItems: 'center' }}>
    <CultureCard alterBackground={missingDateCount} >
       {cultureTable}
    </CultureCard>
       </Grid>
  </Grid>
  <br/>
            <GraphCard 
                selectedOption={selectedOption} 
                handleChange={handleChange} 
                key={`${selectedCandidate}_${trans}`}
                recencyFilters={recencyFilters} 
                handleRecencyFilterChange={handleRecencyFilterChange} 
                selectedCandidate={detailedCandidateData} 
                filters={filters} 
                handleFilterChange={handleFilterChange} 
                handleClick={handleClick} 
                anchorEl={anchorEl} 
                menuOpen={menuOpen} 
                handleClose={handleClose} 
                handleMenuItemClick={handleMenuItemClick} 
                index={index} 
                direct = {trans}
              />
</Grid>

          </Grid>
        </Box>
      </>
      {(settings?.ATS === true) && <RecordDecision show={decisionDialogOpen} decisionType={decisionType} myDecisionChange={updateDecisionData}  
        handleClose={() => setDecisionDialogOpen(false)} trans={trans} candidate={detailedCandidateData} 
      currentStage={detailedCandidateData?.resumeData?.components?.atsApplicantInfo?.current_stage} 
     savedReason={savedReason} 
     savedAdvancedSchedule={savedAdvancedSchedule} savedAdvancedTemplate={savedAdvancedTemplate} savedDeclinedSchedule={savedDeclinedSchedule} savedDeclinedTemplate={savedDeclinedTemplate}
     setSavedReason={setSavedReason} 
     setSavedAdvancedSchedule={setSavedAdvancedSchedule} setSavedAdvancedTemplate={setSavedAdvancedTemplate} setSavedDeclinedSchedule={setSavedDeclinedSchedule} 
     setSavedDeclinedTemplate={setSavedDeclinedTemplate}
     myStorage={myStorage} 
     jobDetails={jobDetails} 
    /> }
    {advancedStagesMenuOpen && (<AdvancedStagesMenu stages={jobDetails.job_stages} current_stage={detailedCandidateData?.resumeData?.components?.atsApplicantInfo?.current_stage } />)}

    </DialogComponent>
  );
}

export default CandidateView;
