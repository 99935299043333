import { createSlice } from "@reduxjs/toolkit";
import { basicThunk, updatebasicThunk } from "../services/settingsThunk"; 
import { updatebasic } from "../services/settingsservice";

const initialState = {
  basic: {},
  status: 'idle',
  error: null,
};

export const settingSlice = createSlice({
  name: 'setting',
  initialState,
  reducers: {
  },
  extraReducers: builder => {
    builder
      .addCase(basicThunk.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(basicThunk.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.basic = action.payload;
      })
      .addCase(basicThunk.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(updatebasicThunk.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updatebasicThunk.fulfilled, (state, action) => {
        state.status = 'succeeded';
        
      })
      .addCase(updatebasicThunk.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
      ;
  }
});

export default settingSlice.reducer;
