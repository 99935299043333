import React, { useState, useEffect } from 'react';
import { ResponsiveRadar } from '@nivo/radar';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import "./candidate.css";
import { color } from '@mui/system';

const ChartComponent = ({ skillsData, secondSkillsData, width, height, type }) => {
    const criticalityColors = {
        High: '#003366',
        Medium: '#8EDBEC',
        Low: '#DDDDDD',
    };
    const recencyColors = {
        0: '#003366',  
        1: '#006699',   
        2: '#8EDBEC',   
        3: '#BBBBBB',   
        4: '#DDDDDD'    
    };
    
    function getColorByRecency(value) {
        console.log(`Received value: ${value}, type: ${typeof value}`);
    
        if (typeof value === 'string') {
            value = Number(value);
        }
    
        if (value >= 0 && value <= 4) {
            console.log(`Returning color: ${recencyColors[value]}`);
            return recencyColors[value];
        }
        
        console.log('Returning default color: #DDDDDD');
        return recencyColors[4];
    }

    const criticalityOrder = {
        'High': 1,
        'Medium': 2,
        'Low': 3
    };

    const sortSkillsByCriticality = (skills, type = 'candidate') => {
        console.log("skills of criticality", skills);
    
        if (!skills) return [];
    
        // Create a shallow copy of the skills array
        const skillsCopy = [...skills];
    
        if (type === 'candidate') {
            return skillsCopy.sort((a, b) => {
                const aValue = criticalityOrder[a?.criticality] || 9999;
                const bValue = criticalityOrder[b?.criticality] || 9999;
                return aValue - bValue;
            });
    
        } else if (type === 'job') {
            console.log("skills",skills)
            return skillsCopy.sort((a, b) => {
                const aValue = a?.recency || 9999;
                const bValue = b?.recency || 9999;
                return aValue - bValue;
            });
        }
    
        return skillsCopy;
    }
    const TransitionUp = (props) => <Slide {...props} direction="up" />;
    const [labelThreshold, setLabelThreshold] = useState(30);
    const [explanation, setexplanation] = useState("");
    const [currentSkill, setCurrentSkill] = useState("");
    const [filteredLabels, setFilteredLabels] = useState("");
    const [openSnackBar, setOpenSnackBar] = React.useState(false);
    const [skillType, setSkillType] = React.useState('CandidateSkill');
    const hasSecondSkill = !!secondSkillsData && secondSkillsData.length > 0;
    const [chartData, setChartData] = React.useState();
    const [skillrecency,setskillrecency]= useState("unknown");
    const [isExpanded, setIsExpanded] = useState(false);

    const handleExpandClick = () => {
        setIsExpanded(!isExpanded);
    };

    const contentStyle = {
        maxHeight: isExpanded ? 'none' : '100px', // Example fixed height
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: isExpanded ? 'normal' : 'nowrap',
        width:300
    };
    function splitSkillName(skillName) {
        // Split the skill name into words
        let words = skillName.split(' ');
    
        // If there's more than one word, find a suitable place to split into two lines
        if (words.length > 1) {
            let midpoint = Math.floor(words.length / 2);
            return words.slice(0, midpoint).join(' ') + '<br/>' + words.slice(midpoint).join(' ');
        }
    
        return skillName;
    }

    useEffect(() => {
        if (hasSecondSkill) {
            setSkillType('Job Required Skill');
        }
        setChartData(sortSkillsByCriticality(skillsData,type).map((skill, index) => {
            let dataObject = {};
            dataObject["taste"] = skill.skillName;

            if (hasSecondSkill) {
//                console.log("secondskill",secondSkillsData)
                const correspondingSecondSkill = secondSkillsData.find(s => {
//                    console.log('Checking skill:', s.skillName?.toLowerCase(), skill.skillName?.toLowerCase()); // Log each skill in the loop
                    return s.skillName?.toLowerCase() === skill.skillName?.toLowerCase();
                  });
//                console.log("corres",correspondingSecondSkill)
                dataObject["Candidate Skill"] = correspondingSecondSkill ? correspondingSecondSkill.satisfyingMastery : 0;
                dataObject['Job Required Skill'] = skill.onetSkillMastery;
            } else {
                if(type==='job'){
                    dataObject['CandidateSkill'] = skill.onetSkillMastery;
                }
                
                else {dataObject['Job Required Skill'] = skill.onetSkillMastery;}
            }

            return dataObject;
        }));
    }, []);

    const filterData = () => {
        if (skillsData.length > labelThreshold) {
            setFilteredLabels(skillsData.filter((_, index) => index % 2 === 0).map(skill => skill.skillName));
        } else {
            setFilteredLabels(skillsData.map(skill => skill.skillName));
        }
        return filteredLabels;
    };

    const handleCloseSnackBar = (event, reason) => {
        
        setOpenSnackBar(false);
        setexplanation("");
        setCurrentSkill("");
    };

    const renderArrays = (obj) => {
        const elements = [];
        for (const key in obj) {
            if (Array.isArray(obj[key])) {

                let content;
            // Check if the array has only one item and that item is "none"
            if (obj[key].length === 1 && obj[key][0] === "none") {
                content = <span>{key === "Direct" ? (<>Directly relevant skills (0 skills)</>) : (<>Closely related skills (0 skills)</>)}</span>;
            } else {
                content = <span>{key === "Direct" ? (<>Directly relevant skills ({obj[key].length} skills)</>) : (<>Closely related skills ({obj[key].length} skills)</>)}</span>;
            }

            elements.push(
                <div key={key}>
                    {content}
                    <ul>
                        {/* Filter out "none" from being displayed */
                         obj[key].map((item, index) => item !== "none" ? <li key={index}>{item}</li> : null)}
                    </ul>
                </div>
            );
            } else if (typeof obj[key] === 'object') {
                elements.push(this.renderArrays(obj[key]));
            }
        }
        return elements;
    }

    const handleMouseLeave = () => {
        setexplanation("");
        setCurrentSkill("");
    };

    const customTooltip = (data) => {
    const skill = skillsData.find(skill => skill.skillName === data.index);
    const skillCriticality = skill ? skill.criticality : "Unknown";
    
    if(type==='job'){
    setskillrecency(skill? skill?.recency : "unknown")
}
        if (data?.data[1]?.id) {
            const explanations = secondSkillsData.find(skill => skill.skillName === data?.index)?.explanation_json || "not available";
            setexplanation(explanations);
            setOpenSnackBar(true);
            setCurrentSkill(data.index);
        }

        return (
          <div style={{ background: 'white', padding: '12px', border: '1px solid #ccc' }}>
            <strong> {data.index} { type==='job'?(<>Recency - {skillrecency}</>) :(<> Criticality - {skillCriticality} </>) } </strong>
          
          <p style={{ color: data.data[0].color }}> {data.data[0].id} : {data.data[0].formattedValue}</p>
          {data.data[1] && (<p style={{ color: data.data[1].color }}> {data.data[1].id} : {data?.data[1].formattedValue}</p>) }
      </div>
        );
    }

    return (
        <>
            <div style={{ height: height || "300px", width: width || 'auto' }}>
                <ResponsiveRadar
                    data={chartData}
                    sliceTooltip={customTooltip}
                    onMouseLeave={handleMouseLeave}
                    keys={
                        hasSecondSkill 
                        ? ['Job Required Skill', 'Candidate Skill'] 
                        : (type === 'job' ? ['CandidateSkill'] : ['Job Required Skill'])
                    }
                    
                    indexBy="taste"
                    margin={{ top: 70, right: 80, bottom: 40, left: 80 }}
                    borderColor={{ from: 'color', modifiers: [['darker', 0.3]] }}
                    gridLevels={5}
                    gridShape="circular"
                    gridLabelOffset={36}
                    enableDots={false}
                    dotSize={10}
                    dotColor={(d) => {
                        
                      if(type==='job'){
                        const skill = skillsData.find(skill => skill.skillName === d?.index);
                        return getColorByRecency(skill?.recency) || '#000';
                      }
                      else {
                        const skill = skillsData.find(skill => skill.skillName === d?.index);
                        return criticalityColors[skill?.criticality] || '#000'; 
                      }
                    }}  
                    dotBorderWidth={0}
                    dotBorderColor={{ from: 'color', modifiers: [['darker', 0.3]] }}
                    colors={
                        hasSecondSkill 
                        ? ['rgba(189, 126, 190, 0.6)', 'rgba(121, 103, 232, 0.6)'] 
                        : ['rgba(121, 103, 232, 0.6)']
                    }
                    
                    colorBy="key"
                    fillOpacity={0.25}
                    animate={true}
                    curve='linearClosed'
                    maxValue={10}
                    motionStiffness={90}
                    motionDamping={15}
                    isInteractive={true}
                    legends={[
                        {
                            anchor: 'top-left',
                            direction: 'row',
                            translateX: -80,
                            translateY: -72,
                            itemWidth: 120,
                            itemHeight: 20,
                            itemTextColor: '#999',
                            symbolSize: 11,
                            symbolShape: 'circle',
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemTextColor: '#888'
                                    }
                                }
                            ]
                        }
                    ]}
                    axisBottom={{
                        tickValues: filteredLabels,
                        tickRotation: 0, 
                    }}
                   
                    
                    radarGridLabel={(props) => {
                        const { index, axis, x, y } = props;
                        if (index % 2 === 0) {
                            return (
                                <g transform={`translate(${x},${y - 16})`}>
                                    <text fontSize={12} textAnchor="middle" fill="#999">
                                        {axis}
                                    </text>
                                </g>
                            );
                        }
                        return null;
                    }}
                />
    
                {secondSkillsData && explanation &&  (
                    <Snackbar
                    open={openSnackBar}
                    onClose={handleCloseSnackBar}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    class="explanation-snackbar"
                >
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <SnackbarContent
                            class="explanation-snackbar-content" 
                            style={{...contentStyle, borderBottomLeftRadius: 0,borderBottomRightRadius: 0, marginBottom:'0px'}}
                            message={
                                explanation === "not available" ? (
                                    <>
                                        <span id="fit-explanation-title" style={{ fontWeight: 600 }}>
                                            Explanation of {currentSkill} fit 
                                        </span>
                                        <br />
                                        No explanation available.
                                    </>
                                ) : (
                                    <>
                                        <span id="fit-explanation-title" style={{ fontWeight: 600 }}>
                                            Explanation of {currentSkill} fit 
                                        </span>
                                        <span style={{ fontWeight: 500 }}>
                                        {renderArrays(explanation)}
                                        </span>
                                        <br />
                                    </>
                                )
                            }
                        />
                        <SnackbarContent
                            class="explanation-snackbar-content" 
                            style={{...contentStyle,borderTopLeftRadius: 0,borderTopRightRadius: 0, textAlign: 'right', marginTop:'0px'}}
                            message={
                                
                            <span onClick={handleExpandClick} style={{ marginTop: '8px', color: 'blue',cursor: 'pointer', marginBottom:'0px', textDecoration: 'underline' }}>
                            {isExpanded? (<>Less details...</>):(<>More details...</>)}
                        </span>
                            }
                        />

                    </div>
                </Snackbar>)}
            </div>
        </>
    );
};

export default ChartComponent;
