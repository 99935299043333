import React, { useState,useEffect } from 'react';
import { Paper, Table, TableBody,Typography, TableCell, TableContainer, TableHead, TableRow, Button, Box, IconButton,
    Menu,
    MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import InviteUsersDialog from './InviteUsersDialog';
import { useSelector, useDispatch } from "react-redux";
import { userThunk,deleteThunk,updateThunk } from '../services/userserviceThunk';

const useStyles = makeStyles({
    title3: {
      color: "var(--text-colour-dark, #172B4D) !important",
      fontFamily: "Poppins !important",
      fontSize: "30px !important",
      fontStyle: "bold !important",
      fontWeight:"700 !important",
      lineHeight: "22px !important", 
    },
    heading: {
        color: "var(--text-colour-dark, #172B4D) !important",
        fontFamily: "Poppins !important",
        fontSize: "22px !important",
        fontWeight: "600 !important" ,
        lineHeight: "20px !important", 
      },
      customFont: {
        color: '#172B4D !important',
        fontFamily: 'Poppins !important',
        fontSize: '14px !important',
        fontStyle: 'normal !important',
        fontWeight: "400 !important",
        lineHeight: 'normal !important',
        letterSpacing: '-0.26px !important',
      },
      menuOpenButton: {
        background: '#4787D2',
        width: '39px',
        height: '32px',
        borderRadius: '5px 5px 0px 0px',
      },
  });


function ManageUsers() {
    const [anchorEl, setAnchorEl] = useState(null);
    const [activeRow, setActiveRow] = useState(null); 
    const [inviteDialogOpen, setInviteDialogOpen] = useState(false);
    const dispatch = useDispatch();
    const handleupdate = (e,action)=>{
      dispatch(updateThunk(action))
      handleClose()
      dispatch(userThunk());
    }
    const handleClose = () => {
        setAnchorEl(null);
        setActiveRow(null);
      };
    const temp = useSelector(state => state.users);
    const [users,setusers] = useState(useSelector(state => state.users.users))

    console.log(users)
    useEffect(() => {
      console.log(temp.users)
      setusers(temp.users)
  }, [temp.users]);

    useEffect(() => {
      dispatch(userThunk());
  }, [dispatch]);

    const classes = useStyles();
    const handleClick = (event, user) => {
        setAnchorEl(event.currentTarget);
        setActiveRow(user);
      };

    const handledelete = (e,userId) => {
      dispatch(deleteThunk(userId))
      handleClose()
      dispatch(userThunk());
    }
      const handleOpenInviteDialog = () => {
        setInviteDialogOpen(true);
    };

    const handleCloseInviteDialog = () => {
        setInviteDialogOpen(false);
    };
    return (<>
    <br/>
    <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography className={classes.title3} gutterBottom>
          Manage Users
        </Typography>
        <Button
          variant="contained"
          color="primary"// This will disable the button when hasChanged is false
          onClick={handleOpenInviteDialog}
        >
          + Users
        
        </Button>
        <InviteUsersDialog open={inviteDialogOpen} onClose={handleCloseInviteDialog} />
      </Box>
    <br/>
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>User Name</TableCell>
                        <TableCell>User Email</TableCell>
                        <TableCell>Last Login</TableCell>
                        <TableCell>Role</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users.map((user) => (
                        <TableRow key={user.email}>
                            <TableCell>{user.userName}</TableCell>
                            <TableCell>{user.email}</TableCell>
                            <TableCell>{user.lastlogin}</TableCell>
                            <TableCell > <div style={{ borderRadius: '5px', background: '#D9D9D9', textAlign:"center" }}>{user.role}</div></TableCell>
                            <TableCell>{user.status}</TableCell>
                            <TableCell>
                    <IconButton onClick={(e) => handleClick(e, user)} 
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"style={activeRow === user? {
                        background: '#4787D2',
                        width: '39px',
                        height: '32px',
                        borderRadius: '5px 5px 0px 0px'
                      } : {}}>
                        
                    <MoreVertIcon  style={{ transform: 'rotate(90deg)' }}/>
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={(e) => handledelete(e, activeRow?.userId)}>Remove User</MenuItem>
                    {activeRow?.status === 'active' && (
                                <MenuItem onClick={(e)=>handleupdate(e,{"userId":activeRow?.userId, "action":{"status":"suspended"}})}>Suspend User</MenuItem>
                            )}
                    {activeRow?.status === 'suspended' && (
                                <MenuItem onClick={(e)=>handleupdate(e,{"userId":activeRow?.userId, "action":{"status":"active"}})}>unSuspend User</MenuItem>
                            )}
                    {activeRow?.role === 'user' && (
                                <MenuItem onClick={(e)=>handleupdate(e,{"userId":activeRow?.userId, "action":{"role":"admin"}})}>Make Administrator</MenuItem>
                            )}
                    
                    {activeRow?.status === 'invited' && (
                                <MenuItem onClick={handleClose}>Resend Invite</MenuItem>
                            )}
                  </Menu>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        
        </>
    );
}

export default ManageUsers;
