import landingImage from "../assets/images/Vector.svg";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from '@material-ui/core';
import CardMedia from '@mui/material/CardMedia';
import Box from '@mui/material/Box';

import {
  Grid,
  Typography,
  TextField,
  Button,
  Snackbar,
} from "@material-ui/core";
import axios from "axios";
import { useMsal } from "@azure/msal-react";


const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    background: "linear-gradient(180deg, #418CD1 0%, #9C41D1 100%)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    padding: "25%"
  },
  loginContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(3),
  },
}));

const LoginPage = ({instance = instance}) => {
//  const { instance } = useMsal();
  const API_BASE = process.env.REACT_APP_API
  const APP_AUTHORITY = process.env.REACT_APP_authority
  const loginURL = `${API_BASE}/main/login`;
  const signupURL = `${API_BASE}/main/signup`;
  const signupAuthority = `${APP_AUTHORITY}`; //"https://blujindev.b2clogin.com/blujindev.onmicrosoft.com/B2C_1_RESUME_SCREENER_SIGNUP"
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const navigate = useNavigate();


  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    console.log("usetoken", storedToken)

    if (storedToken) {
      axios.defaults.headers.common["Authorization"] = storedToken;
      callLoginAPI();
    }
    else {
      localStorage.removeItem("userDetails");
      localStorage.removeItem("token");


    }

  }, []);


  useEffect(() => {
    const clientName = localStorage.getItem("clientName");
    if (error) {

      localStorage.removeItem("userDetails");
      localStorage.removeItem("token");
      instance.logout({
        postLogoutRedirectUri: `${process.env.REACT_APP_postLogoutRedirectUri}${clientName}/`
      }).catch(e => {
        console.error(e);
      })
    }

  }, [error]);

  const callLoginAPI = async () => {
    try {
      // Call your backend API here with the loginURL in the POST request
      console.log("Calling the API at loginURL:", loginURL);
      const response = await axios.post(loginURL);

      // If the response status code is 400, show an error message
      console.log("loginURL returned this status: ", response.status);
      if (response.status === 400) {
        setError(true);
      }
      else if (response.status === 401) {
        setError(true);
      }
      else if (response.status === 404) {
        setError(true);
      }
      else if (response.status === 200) {
        const user = response.data.user;
        console.log("We are good to get started with user", user);
        localStorage.setItem("userDetails", JSON.stringify(user));
        navigate("home");
      }
    } catch (error) {
      // Handle any errors that occurred during the API call
      setError(true);
      console.error("API call error:", error);
    }
  };

  const callSignupAPI = async () => {
    try {
      // Call your backend API here with the email in the POST request
      console.log("API call to signup with URL:", signupURL);
      const response = await axios.post(signupURL);

      // If the response status code is 400, show an error message
      console.log(response.status);
      if (response.status === 400) {
        setError(true);
      } else if (response.status === 200) {
        // If the response is successful (status code 200), store user details in local storage
        const user = response.data.user;
        localStorage.setItem("userDetails", JSON.stringify(user));
        console.log(JSON.stringify(user))
        // Redirect to '/home' route
        navigate("/home");
      }
    } catch (error) {
      // Handle any errors that occurred during the API call
      setError(true);
      console.error("API call error:", error);
    }
  };
  const loginRequest = {
    domainHint: localStorage.getItem("clientName")
  };

  // const handleLogin = async () => {
  //   instance
  //     .loginPopup(loginRequest)
  //     .then((response) => {
  //       console.log("show", response.account); // Print user details
  //       // Configure axios to include the token in the Authorization header
  //       const token = response.idToken;
  //       console.log("response", response)
  //       axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  //       localStorage.setItem("token", `Bearer ${token}`);
  //       instance.setActiveAccount(response.account)
  //       callLoginAPI();
  //     })
  //     .catch((error) => {
  //       setError(true);
  //       console.error("API call error:", error);
  //     });
  // };

  const handleLogin = async () => {
      try {
        const authority = signupAuthority || process.env.REACT_APP_authority;
        const loginRequest = {
          authority: authority,
          scopes: ["openid", "profile", "email"], // Add your specific scopes here
          domainHint: localStorage.getItem("clientName") // Optional: provide domain hint if applicable
      };
      console.log("loginRequest is", loginRequest);
      const response = await instance.loginPopup(loginRequest);
      console.log("Authenticated user:", response.account);
        
      const token = response.idToken;
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      localStorage.setItem("token", `Bearer ${token}`);
      
      instance.setActiveAccount(response.account);
      console.log("Calling loginAPI");
      await callLoginAPI(); // Ensure this is awaited
      console.log("Completed loginAPI");
    } catch (error) {
        setError(true);
        console.error("Authentication error:", error);
    }
};

  // const handleSignup = async () => {
  //   instance.setAuthority(signupAuthority);
  //   instance
  //     .loginPopup()
  //     .then((response) => {

  //       const token = response.idToken;
  //       axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  //       callSignupAPI();
  //     })
  //     .catch((error) => {
  //       setError(true);
  //       console.error("API call error:", error);
  //     });
  // };

  const handleSignup = async () => {
    try {
        // Ensure the authority is set correctly for signup
        instance.setActiveAccount(null); // Clear any active account if needed
        const authority = signupAuthority || process.env.REACT_APP_authority;
        const signupRequest = {
          authority: authority,
          scopes: ["openid", "profile", "email"], // Add your specific scopes here
          domainHint: localStorage.getItem("clientName") // Optional: provide domain hint if applicable
      };
        
      const response = await instance.loginPopup(signupRequest);
      
      const token = response.idToken;
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      
      await callSignupAPI(); // Ensure this is awaited
    } catch (error) {
        setError(true);
        console.error("Signup error:", error);
    }
};

  return (
    <Grid container component="main" className={classes.root}>
      <Grid item xs={false} sm={6} md={6} className={classes.image} />
      <Grid item xs={6} sm={6} md={6} className={classes.loginContainer}>
      <Box display="flex" justifyContent="center" alignItems="center">
        
        <div>
          <div>
            <CardMedia
            component="img"
            image={landingImage}
            alt="Your Image Description"
            width="200px"
            height="200px"
            
          />

          </div>
          <Box display="flex" justifyContent="center" alignItems="center"> 
          <Typography variant="subtitle1" gutterBottom>
            Sign in to your account with SSO
          </Typography>
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center">
          <Button
            variant="contained"
            color="primary"
            style={{ width: '60%' }}
            onClick={handleLogin}
          >
            Login
          </Button>
          </Box>
        </div>
        </Box>
      </Grid>
    </Grid>
  );
};

export default LoginPage;
