import React, { useState, useEffect, useRef } from 'react';
import { Container, Box} from '@mui/material';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route, useLocation, useParams } from 'react-router-dom';
import LandingPage from './LandingPage';
import ProfileComponent from './profiles/createprofile/profile'; 
import LeftNavBar from './navbar';
import BlujinAppBar from './appbar/blujinAppBar';
import Home from './home/homescreen';
import { Provider, useDispatch } from "react-redux";
import { configureStore } from '@reduxjs/toolkit';
import { basicThunk } from './services/settingsThunk';
import jobreeducers from './reducers/jobreeducers';
import resumereducers from './reducers/resumereducers';
import profilereducers from './reducers/profileReducers';
import insightreducer from './reducers/insightreducer';
import notificationreducer from './reducers/notificationreducer';
import settingsreducers from './reducers/settingsreducers';
import userreducer from './reducers/userreducer';
import atsreducer from './reducers/atsreducer';
import Joblist from './joblist/joblist';
import LoginPage from './loginpage/loginPage.js';
import { msalConfig } from './config/msalConfig';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider, useMsal } from "@azure/msal-react";
import { jwtDecode } from 'jwt-decode';
import axios from "axios";
import ClientRouteValidator from './ClientRouteValidator';
import HealthDataGrid from './analytics/HealthDataGrid';
import PrivacyPolicy from './PrivacyPolicy';
import AdminPage from './admin';
import JobDetails from './jobdetails/jobDetails';
import SearchResultsPage  from './appbar/Searchresults';
import Basicsettings  from './company setttings/basic';
import ManageUsers  from './company setttings/Manageusers';
import Help from './help/Help';
const store = configureStore({
  reducer: {
    job: jobreeducers,
    resume: resumereducers,
    profile: profilereducers,
    notifications: notificationreducer,
    insight: insightreducer,
    settings: settingsreducers,
    users: userreducer,
    ats: atsreducer,
  }
});

const LayoutWithNavBar = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const location = useLocation();
  const { clientName } = useParams();
  const dispatch = useDispatch();
  const API_BASE = process.env.REACT_APP_API;
  const clientApiUrl = `${API_BASE}/main/is-valid-client`;
  const timeoutIdRef = useRef(null);
  const { instance } = useMsal();

  const setLogInStatus = (newStatus) => { 
    setIsLoggedIn(newStatus);
  };
  
  const isAllowedClientName = async (clientName) => {
    const response = await axios.get(clientApiUrl, { params: { "clientName": clientName } });
    console.log("API call with client:",clientName);
    console.log("Response Data for checking client is valid",response.data);
    localStorage.setItem("isClientAllowed", response.data.data);
    return response.data.data;
  };

  const isOnClientMainPage = isAllowedClientName(clientName) && (location.pathname === `/${clientName}/` || location.pathname === `/${clientName}`);

  const hideNavBar = location.pathname === '/404' || isOnClientMainPage;




  useEffect(() => {
    console.log("Got into first useEffect in LayoutWithNavBar");
    const token = localStorage.getItem('token');
    if (token) {
      console.log("LayoutWithNavBar has a token");
      const decodedToken = jwtDecode(token);
      const currentUnixTime = Date.now() / 1000;

      if (decodedToken.exp < currentUnixTime) {
        // Token has expired, acquire a new one
        console.log("token instance", instance);
        instance.acquireTokenSilent({
          scopes: ['openid', 'email'],
          account: instance.getActiveAccount(),
          forceRefresh: false,
          domainHint: localStorage.getItem("clientName"),
        }).then((response) => {
          const newToken = response.idToken;
          if (newToken) { setIsLoggedIn(true);}
          localStorage.setItem("token", `Bearer ${newToken}`);
          console.log("response", response)
          console.log("token",newToken)
          if (timeoutIdRef.current) {
            clearTimeout(timeoutIdRef.current);
          }
          // Close the popup if it's open
          if (window.opener) {
            window.opener.postMessage(response, window.location.origin);
            window.close();
          }
        }).catch(error => {
          console.error("Error acquiring token silently:", error);
        });
      } else {
        // Set timeout to acquire a new token before expiration
        console.log("LayoutWithNavBar does not have a token");
        console.log("timeoutset")
        if (timeoutIdRef.current) {
          clearTimeout(timeoutIdRef.current);
        }
    
        timeoutIdRef.current = setTimeout(() => {
          console.log("token instance", instance);
          instance.acquireTokenSilent({
            scopes: ['openid', 'email'],
            account: instance.getActiveAccount(),
            forceRefresh: false,
            domainHint: localStorage.getItem("clientName"),
          }).then(response => {
            console.log("Finished acquiring token");
            const newToken = response.idToken;
            if (newToken) { setIsLoggedIn(true);}
            localStorage.setItem("token", `Bearer ${newToken}`);
            console.log("response", response)
            console.log("token from timeout",newToken)

            // Close the popup if it's open
            if (window.opener) {
              window.opener.postMessage(response, window.location.origin);
              window.close();
            }
          }).catch(error => {
            setIsLoggedIn(false);
            console.error("Error acquiring token silently:", error);
          });
        }, (decodedToken.exp - currentUnixTime - (5 * 60)) * 1000);
      }
    }

    return () => {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
    };
  }, [instance]);

  useEffect(() => {
    console.log("Got into second useEffect in LayoutWithNavBar");
    if (isLoggedIn) {dispatch(basicThunk())};
  }, [isLoggedIn]);

  return (
    <>
      <Box 
        style={{
          display: "flex", 
          flexDirection: "row", 
          height: "100%", 
          backgroundColor: "#F5F7F9",
          overflowX: "auto",
          overflowY: "hidden"    
        }}
      >
      {!hideNavBar && <Box><LeftNavBar /></Box>}
      <Box flexGrow={1} style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        {!hideNavBar && <Box><BlujinAppBar setLogInStatus={setLogInStatus}/></Box>}
        <Box style={{ flex: 1 }}>
          {hideNavBar
            ? (<Box style={{ height: '100%' }}>{children}</Box>) 
            : (<Container style={{ height: '100%' }}>{children}</Container>)}
        </Box>
      </Box>
    </Box>
    </>
  );
};

const App = ({ instance }) => {
  return (
    <MsalProvider instance={instance}>
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route
              path="/:clientName/*"
              element={
                <ClientRouteValidator>
                  <LayoutWithNavBar>
                    <Routes>
                      <Route path="/" element={<LoginPage instance={instance}/>} />
                      <Route path="/landing" element={<LandingPage />} />
                      <Route path="/createprofile" element={<ProfileComponent />} />
                      <Route path="/home" element={<Home />} />
                      <Route path="/health" element={<HealthDataGrid />} />
                      <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
                      <Route path="/list" element={<Joblist />} />
                      <Route path="/admin" element={<AdminPage />} />
                      <Route path="/job-detail/:id" element={<JobDetails />} />
                      <Route path="/search-results/:term" element={<SearchResultsPage />} />
                      <Route path="/Basic" element={<Basicsettings />} />
                      <Route path="/manage-users" element={<ManageUsers />} />
                      <Route path="/help" element={<Help />} />
                    </Routes>
                  </LayoutWithNavBar>
                </ClientRouteValidator>
              }
            />
          </Routes>
        </BrowserRouter>
      </Provider>
    </MsalProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
const msalInstance = new PublicClientApplication(msalConfig);
await msalInstance.initialize();

root.render(<App instance={msalInstance} />);
