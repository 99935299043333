import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  Typography,
  TextField,
  Box,
  Grid,
  CircularProgress,
  Button,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  InputBase,
  IconButton,
  Card,
  CardContent,
  Avatar,
} from "@mui/material";
import { styled } from "@mui/system";
import { makeStyles } from "@material-ui/core/styles";
import DialogComponent from "../components/dialoguebox/dialogue";
import { useSelector, useDispatch } from "react-redux";
import {
  extractJdThunk,
  createJobThunk,
  getJobThunk,
  postResumesThunk,
} from "../services/jobthunk";
import FileProcessor from "../utilities/fileProcessor";
import ResumeUploader from "../utilities/resume_uploader/resume_uploader";
import { withStyles } from "@mui/styles";
import JSZip from "jszip";
import { Search as SearchIcon } from "@mui/icons-material";
import { Close as CloseIcon } from "@mui/icons-material";

const CustomConnector = withStyles({
  line: {
    borderColor: "#e0e0e0",
    borderTopWidth: 3,
    borderRadius: 1,
    "&:after": {
      content: '"\\2192"',
      position: "absolute",
      right: "-20px",
      top: "-10px",
    },
  },
})(StepConnector);

const LoadingOverlay = styled("div")({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "rgba(0, 0, 0, 0.3)",
  zIndex: (theme) => theme.zIndex.drawer + 1,
});

const useStyles = makeStyles({
  root: {
    width: "100%", // Reducing the length
    marginLeft: 0, // Aligning to the left
    backgroundColor: "rgba(71, 135, 210, 0.10)",
    padding: "2%",
    paddingRight: "45%",
    textAlign: "left",
  },
});

const CreateJob = ({ show, handleClose }) => {
  const [btn, setbtn] = useState(true)
  const currentjob = useSelector((state) => state.job);
  const [activeStep, setActiveStep] = useState(0);
  const previousTempJobRef = useRef();
  const [title, setTitle] = useState("");
  const [jobType, setJobType] = useState("");
  const [department, setDepartment] = useState("");
  const [hiringManager, setHiringManager] = useState("");
  const [description, setDescription] = useState("");
  const [location, setLocation] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const loading = currentjob.status === "loading";
  const [file, setfile] = useState("");
  const [reqId, setreqid] = useState("");
  const [resumeFiles, setResumeFiles] = useState(new JSZip());
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const API_BASE = process.env.REACT_APP_API;
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);

  const API = `${API_BASE}/main/get-users`;

  const dispatch = useDispatch();

  // Fetch users from the server
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const token = localStorage.getItem('token');

        const response = await axios.get(API, {
            headers: {
                'Authorization': token
            }
        });
        setUsers(response.data.data);
      } catch (error) {
        console.error("Failed to fetch users", error);
      }
    };
    fetchUsers();
  }, []);

  useEffect(() => {
    previousTempJobRef.current = currentjob.tempJob;
  }, []);

  useEffect(() => {
    if (file!="") { // This checks if the fileURL is not empty
        handleDescriptionChange() // Call your function here
    }
}, [file]);

  const handleFilesChange = (files) => {
    setResumeFiles(files);
  };
  useEffect(() => {
    if (previousTempJobRef.current !== currentjob.tempJob) {
      console.log(currentjob.tempJob);
      setTitle(currentjob.tempJob["jobTitle"]);
      setDescription(currentjob.tempJob['jobDescription'])
      setJobType(currentjob.tempJob["jobType"]);
      setDepartment(currentjob.tempJob["Department"]);
      setHiringManager(currentjob.tempJob["hiringManager"]);
      setLocation(currentjob.tempJob["Location"]);
      setreqid(currentjob.tempJob["reqId"]);
      previousTempJobRef.current = currentjob.tempJob;
    }
  }, [currentjob.tempJob]);

  const handleFileLoad = (fileURL) => {
      setfile(fileURL);

  };
  const handleClose2 = () => {
    // Call handleClose from the prop
    handleClose();

    // Reset all fields
    setTitle("");
    setActiveStep(0);
    setJobType("");
    setDepartment("");
    setHiringManager("");
    setDescription("");
    setLocation("");
    setfile("");
    setreqid("");
    setResumeFiles("");
    setIsEditMode(false);
    setUsers([]);
    dispatch(getJobThunk());
  };
  const classes = useStyles();

  const handleDescriptionChange = () => {
    
    dispatch(extractJdThunk({ file }));
    setIsEditMode(true); 
    
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    if (value) {
      const filtered = users.filter(
        (s) =>
          (s.userName.toLowerCase().includes(value.toLowerCase()) || 
          s.email.toLowerCase().includes(value.toLowerCase())) &&
          !selectedUsers.some((u) => u.userId === s.userId) 
      );
      setFilteredSuggestions(filtered);
    } else {
      setFilteredSuggestions([]);
    }
  };

  const handleCardClick = (user) => {
    // Check if the user is already selected
    if (
      !selectedUsers.some((selectedUser) => selectedUser.userId === user.userId)
    ) {
      setSelectedUsers((prevUsers) => [...prevUsers, user]);
      // Re-filter suggestions to remove the selected user
      const updatedSuggestions = filteredSuggestions.filter(
        (s) => s.userId !== user.userId
      );
      setFilteredSuggestions(updatedSuggestions);
    }
  };

  const handleRemoveUser = (userId) => {
    setSelectedUsers((prevUsers) =>
      prevUsers.filter((u) => u.userId !== userId)
    );
  };

  const getInitials = (name) => {
    return name
      .split(" ")
      .slice(0, 2)
      .map((word) => word[0])
      .join("")
      .toUpperCase();
  };

  const getColorForName = (name) => {
    let hash = 0;
    let i;

    for (i = 0; i < name.length; i += 1) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
  };

  const handleSubmit = async () => {
    setbtn(false)
    if (
      title &&
      jobType &&
      department &&
      hiringManager &&
      description &&
      location
    ) {
      let collaborators = selectedUsers.map((user) => ({
        userId: user.userId,
        role: "collaborator",
      }));
      let data = {
        jobDescription: description,
        jobTitle: title,
        jobType: jobType,
        department: department,
        hiringManager: hiringManager,
        location: location,
        reqId: reqId,
        collaborators: collaborators,
      };
      console.log("Collaborators", collaborators);

      const temp = await dispatch(createJobThunk({ job: data, file }));
      console.log(temp);
      console.log(resumeFiles);
      const resumeBlob = await resumeFiles.generateAsync({ type: "blob" });
      if (resumeBlob instanceof Blob) {
        const temp2 = await dispatch(
          postResumesThunk({ jobId: temp.payload.data, resumeBlob })
        );
        console.log(temp2);
      }

      handleClose2();
      setbtn(true)
    } else {
      console.log("All fields are required");
    }
  };

  const handleNextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handlePreviousStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const stepActions =
    activeStep === 0 ? (
      <>
        <Button onClick={handleClose2}>Cancel</Button>
        <Button
          variant="contained"
          style={{ backgroundColor: "#4787D2", color: "#FFF" }}
          onClick={handleNextStep}
        >
          Next Step
        </Button>
      </>
    ) : (
      <>
        <Button onClick={handleClose2}>Cancel</Button>
        <Button
          variant="contained"
          style={{
            backgroundColor: "#FFF",
            color: "#4787D2",
            border: "1px solid #4787D2",
          }}
          onClick={handlePreviousStep}
        >
          Back
        </Button>
        <Button
          variant="contained"
          style={{ backgroundColor: "#4787D2", color: "#FFF" }}
          onClick={() => {
            if (btn) {
             
              handleSubmit();
            }
          }}
          disabled={!btn}
        >
          {!btn ? 'Submitting...' : 'Submit'}
        </Button>
      </>
    );

  return (
    <DialogComponent show={show} title="Add New Job" actions={stepActions}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={8} sm={8}>
            <div className={classes.root}>
              <Stepper activeStep={activeStep}>
                <Step key="Step 1">
                  <StepLabel>Step 1</StepLabel>
                </Step>
                <Step key="Step 2">
                  <StepLabel>Step 2</StepLabel>
                </Step>
              </Stepper>
            </div>
          </Grid>
          {activeStep === 0 && (
            <>
              <Grid item xs={8} sm={8}>
                <FileProcessor
                  onAnalyzeText={handleDescriptionChange}
                  onFileLoad={handleFileLoad}
                />
              </Grid>

              <Grid item xs={8}>
                <ResumeUploader
                  onFilesChange={handleFilesChange}
                  previouszip={resumeFiles}
                />
              </Grid>
              <Grid item xs={8}>
                <Typography variant="subtitle1" gutterBottom>
                  Add Collaborators
                </Typography>
                <InputBase
                  fullWidth={true}
                  startAdornment={
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  }
                  placeholder="Search for User Name, Email ID"
                  onChange={handleSearchChange}
                />
                {filteredSuggestions.length > 0 && (
                  <div>
                    {filteredSuggestions.map((suggestion) => (
                      <Card
                        key={suggestion.userId}
                        style={{ margin: "8px 0" }}
                        onClick={() => handleCardClick(suggestion)}
                      >
                        <CardContent
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <Avatar
                            src={suggestion.image}
                            sx={{
                              backgroundColor: getColorForName(suggestion.userName),
                              marginRight: "8px",
                            }}
                          >{getInitials(suggestion.userName)}
                          </Avatar>
                          <div>
                            <div>{suggestion.userName}</div>
                            <small>{suggestion.email}</small>
                          </div>
                        </CardContent>
                      </Card>
                    ))}
                  </div>
                )}
              </Grid>
              <Grid item xs={8}>
                {selectedUsers.length > 0 && (
                  <div style={{ marginTop: "20px" }}>
                    <Typography variant="subtitle1" gutterBottom>
                      Your Collaborators
                    </Typography>
                    {selectedUsers.map((user) => (
                      <Card
                        key={user.userId}
                        style={{
                          margin: "8px 0",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Avatar
                          src={user.image}
                          sx={{
                            backgroundColor: getColorForName(
                              user.userName
                            ),
                            marginRight: "8px",
                          }}
                        >
                           {getInitials(user.userName)}
                          </Avatar>
                        <div style={{ flexGrow: 1 }}>
                          <div>{user.userName}</div>
                          <small>{user.email}</small>
                        </div>
                        <IconButton
                          onClick={() => handleRemoveUser(user.userId)}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Card>
                    ))}
                  </div>
                )}
              </Grid>
            </>
          )}

          {activeStep === 1 && (
            <Grid item xs={12} sm={8}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Grid container spacing={1} style={{ position: "relative" }}>
                    {loading && (
                      <LoadingOverlay>
                        <CircularProgress color="inherit" />
                        <br />
                        AutoFilling.......
                      </LoadingOverlay>
                    )}
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        label="Job Title"
                        value={title}
                        disabled={!isEditMode} // Disable if not in edit mode
                        onChange={(e) => setTitle(e.target.value)} // Allow changes when in edit mode
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        label="Requestion Id"
                        value={reqId}
                        disabled={!isEditMode} // Disable if not in edit mode
                        onChange={(e) => setreqid(e.target.value)} // Allow changes when in edit mode
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        label="Location"
                        value={location}
                        disabled={!isEditMode} // Disable if not in edit mode
                        onChange={(e) => setLocation(e.target.value)} // Allow changes when in edit mode
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        label="Job Type"
                        value={jobType}
                        disabled={!isEditMode} // Disable if not in edit mode
                        onChange={(e) => setJobType(e.target.value)} // Allow changes when in edit mode
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        label="Department"
                        value={department}
                        disabled={!isEditMode} // Disable if not in edit mode
                        onChange={(e) => setDepartment(e.target.value)} // Allow changes when in edit mode
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        label="Hiring Manager"
                        value={hiringManager}
                        disabled={!isEditMode} // Disable if not in edit mode
                        onChange={(e) => setHiringManager(e.target.value)} // Allow changes when in edit mode
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>
    </DialogComponent>
  );
};

export default CreateJob;
