import React, { useState } from 'react';
import ChartComponent from '../../components/charts/ChartComponent';
import ParallelChartComponent from '../../components/charts/ParallelChartComponent';
import DialogComponent from '../../components/dialoguebox/dialogue';
import { Button, TextField, FormControl, IconButton, Tooltip } from '@mui/material';
import { styled } from '@mui/system';
import BarChartIcon from '@mui/icons-material/BarChart';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import SkillsTableComponent from '../../components/skilltable/skilltable';
import AddSkillDialog from './addskill';

const StyledTextField = styled(TextField)({
  width: '100%',
  '& .MuiInputBase-input': {
    fontSize: '14px',
    padding: '8px 10px',
    '@media (max-width: 600px)': {
      fontSize: '12px',
    },
  },
});

const ProfileComponent = ({ initialSkillsData, initialTitle, onSubmit,show,handleClose,type }) => {
  // Assuming you want to show the component directly
  const [title, setTitle] = useState(initialTitle);
  const [skillsData, setSkillsData] = useState(initialSkillsData);
  const [currentChart, setCurrentChart] = useState('ChartComponent');
  const [addSkillDialogOpen, setAddSkillDialogOpen] = useState(false);


    // Function to open the Add Skill Dialog
    const openAddSkillDialog = () => {
      setAddSkillDialogOpen(true);
    };

      // Function to handle the new skill addition
  const handleNewSkillSave = (newSkill) => {
    const skillWithNumberValues = {
      ...newSkill,
      onetSkillMastery: parseFloat(newSkill.onetSkillMastery, 10),
      requiredExperience: parseFloat(newSkill.requiredExperience, 10)
    };
    setSkillsData([skillWithNumberValues,...skillsData]);
    // setAddSkillDialogOpen(false);
  };


  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleSkillChange = (index, field, value) => {
    setSkillsData(prevSkillsData => {
      const updatedSkillsData = [...prevSkillsData];
      updatedSkillsData[index] = {
        ...updatedSkillsData[index],
        [field]: value,
      };
      return updatedSkillsData;
    });
  };
  

  const addSkill = () => {
    const newSkill = {
      skillName: '',
      onetSkillMastery: 0,
      criticality: 'Low',
    };
    setSkillsData([newSkill,...skillsData]);
  };

  const handleDeleteSkill = (index) => {
    const updatedSkillsData = [...skillsData];
    updatedSkillsData.splice(index, 1);
    setSkillsData(updatedSkillsData);
  };
  
  const handleSubmit = () => {
    if (title.trim() === '') {
      alert('Title is required');
      return;
    }
    onSubmit(skillsData, title);
    handleClose();
  };

  const toggleChart = () => {
    setCurrentChart((prev) => (prev === 'ChartComponent' ? 'ParallelChartComponent' : 'ChartComponent'));
  };

  return (
    <DialogComponent show={show} title="Edit Profile" handleClose={handleClose} handleSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12 col-md-6">
          <p>
            <FormControl fullWidth margin="normal">
              <StyledTextField label="Profile Name" value={title} onChange={handleTitleChange} required disabled={type === "edit"} />
            </FormControl>
          </p>
          <Button variant="contained" type="submit" 
          style={{
            borderRadius: '4px',
            border: '1px solid var(--bluejin-primary-color, #4787D2)',
            backgroundColor: '#4787D2',
            marginBottom:'20px'
          }}
          onClick={openAddSkillDialog}>Add Skill</Button>
          <SkillsTableComponent
            skillsData={skillsData}
            handleSkillChange={handleSkillChange}
            handleDeleteSkill={handleDeleteSkill}
            addSkill={addSkill}
          />
        </div>
        <div className="col-12 col-md-6">
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          </div>
          {currentChart === 'ChartComponent' ? (
            <ChartComponent skillsData={skillsData} key={JSON.stringify(skillsData)} />
          ) : (
            <div style={{ height: '500px', width: '100%' }}>
              <ParallelChartComponent skillsData={skillsData} />
            </div>
          )}
        </div>
      </div>
      <AddSkillDialog
        open={addSkillDialogOpen}
        onSave={handleNewSkillSave}
        onClose={() => setAddSkillDialogOpen(false)}
        skillsData={skillsData}
      />
    </DialogComponent>
  );
};

export default ProfileComponent;
