import axios from 'axios';



const API_BASE = process.env.REACT_APP_API;

const API = `${API_BASE}/main/notifications/`;

const instance = axios.create({
  baseURL: API,
  headers: {}
});
// Add a request interceptor to the instance
instance.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = token;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});



export const getnotifications = async () => {
  try {
    const response = await instance.get('get-notifications');
    return response.data.data;
  } catch (error) {
    console.error("Error in getNotifications:", error);
    throw error;
  }
};
