import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Tab, Tabs, Card, CardActionArea } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { searchJobsThunk } from '../services/searchThunk';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import { useLocation } from "react-router-dom";
const useStyles = makeStyles({
  tabRoot: {
    minWidth: 'auto',
    marginRight: '20px'
  },
  tabSelected: {
    fontWeight: 'bold'
  },
  indicator: {
    backgroundColor: 'blue'
  },
  smallHeading: {
    fontWeight: 'bold',
    fontSize: '1.1em',
    margin: '20px 0'
  }
});

const SearchResultsPage = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { term } = useParams();
  const location = useLocation();
  
  const [value, setValue] = useState(0);
  const [results, setResults] = useState({ jobs: [], resume: [] });
  const dispatch = useDispatch();
  const clientName = localStorage.getItem("clientName")
  const handleItemClick = (jobId) => {
    navigate("/"+localStorage.getItem("clientName")+`/job-detail/${jobId}`,  { state: { loc: location.pathname } })
    
  };
  const goToJobDetails = (jobId, candidateId) => {
    navigate("/"+localStorage.getItem("clientName")+`/job-detail/${jobId}?tab=resumes&candidate=${candidateId}`, { state: { loc: location.pathname } });
    
  };
  useEffect(() => {
    dispatch(searchJobsThunk(term))
      .unwrap()
      .then(data => {
        setResults(data);
      })
      .catch(error => {
        console.error("Error while fetching search results:", error);
      });
  }, [term, dispatch]);

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ marginRight: '20px', fontWeight: 'bold', fontSize: '1.2em' }}>
          Search Results for: {term}
        </span>
        <Tabs 
          value={value} 
          onChange={(event, newValue) => setValue(newValue)}
          classes={{ indicator: classes.indicator }}
        >
          <Tab classes={{ root: classes.tabRoot, selected: classes.tabSelected }} label="All" />
          <Tab classes={{ root: classes.tabRoot, selected: classes.tabSelected }} label="Jobs" />
          <Tab classes={{ root: classes.tabRoot, selected: classes.tabSelected }} label="People" />
        </Tabs>
      </div>
      {value === 0 && (
        <>
          <span className={classes.smallHeading}>Jobs</span>
          {results.jobs.length === 0 && <div>No jobs found...</div>}
          {results.jobs.map((job, idx) => (
            <Card key={idx} style={{ borderRadius: '5px', margin: '10px', padding: '5px' }}>
            <CardActionArea onClick={() => handleItemClick(job._id.$oid.toString())}>
              <span style={{ fontWeight: 'bold' }}>{job.jobTitle}</span> <br/>
              <span>Department: {job.department} | Location: {job.location} | Hiring Manager: {job.hiringManager} | Created by: {job.userName}</span>
            </CardActionArea>
          </Card>
          ))}
          <span className={classes.smallHeading}>People</span>
          {results.resume.map((person, idx) => (
            <Card key={idx} style={{ borderRadius: '5px', margin: '10px', padding: '5px' }}>
            <CardActionArea onClick={() => goToJobDetails(person.jobId, person.resumeId)}>
              <span style={{ fontWeight: 'bold' }}>{person.personalDetails.name}</span> in <span style={{ fontWeight: 'bold' }}>{person.jobTitle}</span>
              <br/>
              <span>Department: {person.department} | Location: {person.location} | Hiring Manager: {person.hiringManager} | Created by: {person.userName} </span>
            </CardActionArea>
          </Card>
          ))}
        </>
      )}
      {value === 1 && results.jobs.map((job, idx) => (
        <Card key={idx} style={{ borderRadius: '5px', margin: '10px', padding: '5px' }}>
        <CardActionArea onClick={() => handleItemClick(job._id.$oid.toString())}>
          <span style={{ fontWeight: 'bold' }}>{job.jobTitle}</span> <br/>
          <span>Department: {job.department} | Location: {job.location} | Hiring Manager: {job.hiringManager} | Created by: {job.userName} </span>
        </CardActionArea>
      </Card>
      ))}
      {value === 2 && results.resume.map((person, idx) => (
        <Card key={idx} style={{ borderRadius: '5px', margin: '10px', padding: '5px' }}>
        <CardActionArea onClick={() => goToJobDetails(person.jobId, person.resumeId)}>
          <span style={{ fontWeight: 'bold' }}>{person.personalDetails.name}</span> in <span style={{ fontWeight: 'bold' }}>{person.jobTitle}</span>
          <br/>
          <span>Department: {person.department} | Location: {person.location} | Hiring Manager: {person.hiringManager} | Created by: {person.userName} </span>
        </CardActionArea>
      </Card>
      ))}
    </div>
  );
}


export default SearchResultsPage;
