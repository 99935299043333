import React from 'react';
import './emailTemplatePopup.css';

const EmailTemplatePopup = ({ encodedHtml, onClose }) => {
  const htmlDecode = (content) => {
    content = content.replaceAll("\\/", "/");
    content = content.replaceAll("\\r\\n", "&lt;br /&gt;");
    let e = document.createElement('div');
    e.innerHTML = content;
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
   }

   const step1 = decodeURIComponent(encodedHtml);
   const decodedHtml = htmlDecode(step1);
  return (
      <div className="popup-content">
        <div dangerouslySetInnerHTML={{ __html: decodedHtml }} />
      </div>
  );
};

export default EmailTemplatePopup;