import React, { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import ReconnectingWebSocket from "reconnecting-websocket";
import { ReactComponent as NotificationsIcon } from "../assets/images/NotificationsIcon.svg";
import ListSubheader from "@mui/material/ListSubheader";
import { getnotifcationThunk } from "../services/notificationthunk";
import { useDispatch, useSelector, } from "react-redux";
import { useNavigate } from 'react-router-dom';


const NotificationComponent = () => {
  
  const notifications = useSelector((state) => state.notifications);
  const [messages, setMessages] = useState([]);
  const [allNotifications, setAllNotifications] = useState([]);
  const [numNotifications, setNumNotifications] = useState(0);
  const [numNotifications2, setNumNotifications2] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const WebSocket = process.env.REACT_APP_websocket;
  const token = localStorage.getItem("token");
  const [tabValue, setTabValue] = useState(0); // 0 for "New", 1 for "All"
  
  const dispatch = useDispatch();

  const ws = new ReconnectingWebSocket(
    `${WebSocket}/?token=${encodeURIComponent(token)}`
  );

  const handleClick = (event) => {
    markAsRead();
    setAnchorEl(event.currentTarget);
  };

  const handleItemClick = (jobId) => {
    navigate("/"+localStorage.getItem("clientName")+`/job-detail/${jobId}`);
    
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const markAsRead = () => {
    let timestamp = new Date().toISOString();
    timestamp = timestamp.replace("Z", "+00:00");
    const markReadMessage = {
      action: "mark_as_read",
      timestamp: timestamp,
    };
    ws.send(JSON.stringify(markReadMessage));
    setNumNotifications(0);
  };

  useEffect(() => {
    ws.onopen = () => {
      console.log("Connected to the WebSocket");
    };
    ws.onmessage = (msg) => {
      const data = JSON.parse(msg.data);
      let newMessages = [];
      if (Array.isArray(data)) {
          newMessages = data.map((item) => ({
              message: item.message,
              timestamp: item.timestamp,
              jobId:item.jobId
          }));
      } else {
          newMessages = [
              { message: data.message, timestamp: data.timestamp },
          ];
      }
      const sortedMessages = [...newMessages, ...messages].sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)); // Sort by latest timestamp
      setMessages(sortedMessages);
      setNumNotifications((prevCount) => prevCount + newMessages.length);
      setNumNotifications2(((prevCount) => prevCount + newMessages.length))
  };
    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
    };
    ws.onclose = () => {
      console.log("WebSocket connection closed");
    };
  
    return () => {
      ws.close();
    };
  }, []);

  useEffect(() => {
    const sortedNotifications = [...notifications.notifications].sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
    setAllNotifications(sortedNotifications);
    console.log("allnotifications", allNotifications)
}, [notifications]);



  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    
    if (newValue === 1) {
      dispatch(getnotifcationThunk())
      console.log(notifications)
      }
  
  };

  const displayNotifications = tabValue === 0 ? messages : allNotifications;

  const inactiveTabStyle = {
    borderRadius: '25px',
    margin: '0 5px',
    color: '#626F86',
    background: '#E9F2FF',
    fontSize: '14px',       // Reduced font size
    padding: '1px',
    height:"fit-content",
    width:"fit-content",
    fontType:"Poppins",
    textTransform: 'none'    // Reduced button size by adjusting padding
};
  const activeTabStyle = {
    ...inactiveTabStyle,
    background: '#4787D2',
    color:'#FFFFFF'
  };

  return (
    <div>
      <IconButton
        size="large"
        aria-label="show new notifications"
        color="inherit"
        onClick={handleClick}
      >
        <Badge badgeContent={numNotifications} color="error">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <ListSubheader><strong>Notifications</strong>
        <div style={{ display: 'flex', justifyContent: 'flex-start', margin: '0px' }}>
          <Button 
            style={tabValue === 0 ? activeTabStyle : inactiveTabStyle}
            onClick={() => handleTabChange(null, 0)}
          >
            New ({numNotifications2})
          </Button>
          <Button 
            style={tabValue === 1 ? activeTabStyle : inactiveTabStyle}
            onClick={() => handleTabChange(null, 1)}
          >
            All
          </Button>
        </div>
        </ListSubheader>
        {displayNotifications.map((item, index) => (
          <MenuItem 
            key={index} 
            style={{ 
              flexDirection: 'column', 
              display: 'flex', 
              alignItems: 'flex-start', 
              borderBottom: '1px solid #e0e0e0',
            }}
            onClick={() => handleItemClick(item.jobId)}
          >
            <span>{item.message}</span>
            <div style={{ color: 'grey', fontSize: '12px', marginTop: '4px' }}>
                {new Date(item.timestamp).toLocaleString()}
            </div>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default NotificationComponent;
