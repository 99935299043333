import React from 'react';
import { ResponsiveBar } from '@nivo/bar';

const VerticalBarChart = ({ formattedData, width = 180, height = 85 }) => {
  // Custom tooltip component

  const CustomTooltip = ({ id}) => {
    const company = id.data.company; // Access endYear from data
    const startYear = id.data.startYear; // Access endYear from data
    const endYear = id.data.endYear; // Access endYear from data
    const value = id.value;
    const color = id.color;
    const itemWidth = width / formattedData.length;
    const thisTop = -30; // Adjust as needed for vertical positioning
    const thisLeft = -(itemWidth * id.index + 100); // Adjust based on item width and index
    return (
      <div
        style={{
          position: 'relative',
          top: `{thisTop}px`,
          left: `{thisLeft}px`,
          padding: 10,
          color: color,
          background: 'white',
          border: '1px solid #ccc',
          borderRadius: 6,
          zIndex: 1000, // Ensure it's on top
          pointerEvents: 'none', // Prevent interaction issues
        }}
      >
        <div style={{ fontSize: '10px', fontWeight: 'bold' }}>{company.slice(0,16)}</div>
        {/* <div style={{ fontSize: '6px' }}>{startYear}<strong> to </strong> {endYear}</div> */}
        <div style={{ fontSize: '8px', fontWeight: 'bold' }}>{value.toFixed(1)}&nbsp;Years</div>

      </div>
    );
  };


  const maxValue = Math.max(...formattedData.map(item => item.value));
  const roundedMaxValue = Math.ceil(maxValue) -1 ;  // Round up to the nearest whole number
  const tickValues = Array.from({ length: Math.ceil(maxValue / 5)  }, (_, i) => i * 5);
  // Check if the last tickValue exceeds roundedMaxValue and adjust it
  if (tickValues[tickValues.length - 1] < roundedMaxValue - 1 ) {
    tickValues.push(roundedMaxValue);
  }
  const xValues = formattedData.map(item => item.x);
  const endYear = formattedData.map(item => item.endYear);
  const firstXValue = xValues[0];
  const lastXValue = xValues[xValues.length - 1];
  // Create showValues mapping
  const showValues = xValues.reduce((acc, xValue) => {
    acc[xValue] = (xValue === firstXValue || xValue === lastXValue) ? 1 : 0;
    return acc;
  }, {}); 
  const showTopValues = xValues.reduce((acc, xValue) => {
    acc[xValue] = (xValue === lastXValue) ? 1 : 0;
    return acc;
  }, {}); 
  return (
    <div style={{ position: 'relative', width, height }}>
      <ResponsiveBar
        data={formattedData}
        keys={['value']} // Assuming your data key is 'value'
        indexBy="x"
        margin={{ top: 9, right: 10, bottom: 12, left: 30 }}
        padding={0.4}
        colors={{ scheme: 'category10' }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 0,
          tickRotation: 0, //-23,
          // legend: 'Start Year',
          // legendPosition: 'middle',
          // legendOffset: -20,
          tickValues: (tickValues) => [firstXValue, lastXValue],          
          format: (value) => showValues[value] === 1 ? formattedData.find(item => item.x === value)?.startYear || value : "",
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Years',
          legendPosition: 'middle',
          legendOffset: -27,
          tickValues: tickValues
        }}
        theme={{
          axis: {
              ticks: {
                  text: {
                      fontSize: 8, // Specify the font size here
                  },
              },
          },
      }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        enableLabel={false}
        // label={(d) => (
        //   <text
        //     transform={`translate(-10, ${d.height / 2}) rotate(-90)`}
        //     style={{ fontSize: 8, fill: d.color }}
        //     alignmentBaseline="middle"
        //   >
        //     {d.indexValue}
        //   </text>
        // )}
        tooltip={(id, value, color, data ) => (
          <CustomTooltip id={id} /> 
        )}
      />
    </div>
  );
};

export default VerticalBarChart;
