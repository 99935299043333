import React from "react";
import "./LandingPage.css";


const LandingPage = () => {
  console.log("Got into landing page");
  const handleUpload = (event) => {
    const files = event.target.files;
    console.log("Selected files:", files);
  };

  return (
    <div className="landing-page">
      <div className="side-menu">
        {/* Side menu options */}
        <ul>
          <li>Profile</li>
          <li>Settings</li>
          <li>Job Profiles</li>
          <li>Logout</li>
          

        </ul>
      </div>
      <div className="main-content">
        {/* Form with textbox and folder upload */}
        <form>
          <div>
            <label htmlFor="job-description">JOB DESCRIPTION:</label>
            <input type="text" id="job-description" />
          </div>
          <div>
            <label htmlFor="folder-upload">Upload Folder:</label>
            <input
              type="file"
              id="folder-upload"
              directory=""
              webkitdirectory=""
              onChange={handleUpload}
            />
          </div>
          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default LandingPage;
