import axios from 'axios';


//const API_BASE = 'http://127.0.0.1:8000';
const API_BASE = process.env.REACT_APP_API;

const API = `${API_BASE}/main/jobs/`;

const instance = axios.create({
  baseURL: API,
  headers: {}
});
// Add a request interceptor to the instance
instance.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = token;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});



export const createJob = async (job, file) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('job', JSON.stringify(job));
  const response = await instance.post('upload-job', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  console.log(response);
  return response;
};

export const postResumes = async (jobId, resumeBlob) => {
  const formData = new FormData();

  if (resumeBlob instanceof Blob) {
    formData.append('resumeFiles', resumeBlob, 'resumes.zip');
    formData.append('jobId', JSON.stringify(jobId));
    const response = await instance.post('upload-resumes', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response;
  } else {
    console.log(resumeBlob);
    throw new Error('File is not a Blob');
  }
};

export const extractJd = async (file) => {
  const formData = new FormData();
  formData.append('file', file);
  const response = await instance.post('extract-details', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });

  return response.data['jobDetails'];
};

export const getJob = async () => {
  try {
    const response = await instance.get('get-jobs');
    return response.data.data;
  } catch (error) {
    console.error("Error in getJob:", error);
    throw error;
  }
};

export const getJobDetails = async (jobId) => {
  try {
    const response = await instance.get('get-jobsdetails', { params: { jobId: jobId }});
    return response.data.data;
  } catch (error) {
    console.error("Error in getJobDetails:", error);
    throw error;
  }
};

export const getJobdecisions = async (jobId) => {
  try {
    const response = await instance.get('get-candidate-decisions', { params: { jobId: jobId }});
    return response.data.data;
  } catch (error) {
    console.error("Error in getJobDetails:", error);
    throw error;
  }
};

export const updatejob = async (jobId, action) => {
  const job = { 'jobId': jobId, 'action': action};
  let response;
  if (action === 'Close') {
    response = await instance.post('close-job', job);
  }
  else if (action === 'JobSync') { 
    response = await instance.post('sync-job', job);
  } 
  else if (action === 'JobReSync') { 
    response = await instance.post('resync-job-applicants', job);
  } 
  else if (action === 'NewCandidates') { 
    response = await instance.post('get-ats-candidates', job);
  } 
  console.log(response);
  return response;
};

export const updatejobrange = async (jobId, action) => {
  const job = { 'jobId': jobId, 'data': action};
  const response = await instance.post('update-job', job);
  console.log(response);
  return response;
};
