import * as React from "react";
import { useState } from "react";
import Grid from '@mui/material/Grid';
import Slider from "@mui/material/Slider";
import AreaWithSlider from "./AreaWithSlider";
import './rangeSlider.css';

function convertValueToText(value) {
  return `${value}`;
}

// the data below is used as default if no data has been passed to the charting function
const privateData = [{ 'overall': 10},
{ 'overall': 20},{ 'overall': 20},
{ 'overall': 30},{ 'overall': 30},{ 'overall': 30},
{ 'overall': 40},{ 'overall': 40},{ 'overall': 40},{ 'overall': 40},
{ 'overall': 50},{ 'overall': 50},{ 'overall': 50},{ 'overall': 50},{ 'overall': 50},
{ 'overall': 60},{ 'overall': 60},{ 'overall': 60},{ 'overall': 60},{ 'overall': 60},{ 'overall': 60},
{ 'overall': 70},{ 'overall': 70},{ 'overall': 70},{ 'overall': 70},{ 'overall': 70},
{ 'overall': 80},{ 'overall': 80},{ 'overall': 80},{ 'overall': 80},
{ 'overall': 90},{ 'overall': 90},{ 'overall': 90},
{ 'overall': 100},{ 'overall': 100}];

// We set a default height of 200px  
// this can be overriden by the container where RangeSlider is incldued 
// For example if the container has a set height, then it can pass 100% as the height 
// The reason for this is that the ResponsiveLine doesn't show up without a container that has some specific boundaries 

export default function RangeSlider({data=null, scoreDistribution=null, scoreValues=null, heightValue="200px", scoreField = "overall", xLabel="Score", yLabel="Candidates", onValueChange, range}) {
  console.log("Starting with Rangeslider, with a data length of ", data.length);

  // Check if there is data - otherwise set it to a default data set
  if (data === null) {
      data = privateData;
      console.log("setting data to SAMPLE privateData as no input data has been provided");
    }
  else {
    console.log("Data has been passed in to rangeslider");
  }

  if (scoreValues === null) {
    scoreValues = data 
    .map(candidate => candidate[scoreField]) // get each candidates score in to a list 
    .filter((scoreVal, index, array) => array.indexOf(scoreVal) === index); // ensure we have unique list of scoreValues
    console.log("scoreValues were calculated");
  }
  else {
    console.log("scoreValues were passed in to rangeSlider");  
  }
//  console.log("scoreValues is" + scoreValues);


  if (scoreDistribution === null) {
    scoreDistribution = scoreValues
        .map(scoreVal => ({
            x: scoreVal,
            y: data.filter(candidate => candidate[scoreField] === scoreVal).length
        }));
    console.log("scoreDistribution was calculated");    
  }
  else { 
    console.log("scoreDistribution was passed in to rangeSlider");
  }
  console.log("scoreDistribution is ",scoreDistribution);

  const minMaxScoreValues = [Math.min.apply(Math, scoreValues) , Math.max.apply(Math,scoreValues)];
//  console.log("min " + minMaxScoreValues[0] + " max " + minMaxScoreValues[1]);

  const [value, setValue] = React.useState(range);
  

  const handleChange = (event, newValue) => {
      setValue(newValue);
      if (onValueChange) {
        onValueChange(newValue); 
      }
  };

  

  return (
    <Grid container class="sliderChart" rowSpacing={10} spacing={1} padding={0}  >
        <Grid item xs={12} class = "parent">
        <div style={{ height: "20vh", width: "100%"}}>
          <AreaWithSlider 
            scoreDistribution={scoreDistribution} min={minMaxScoreValues[0]} scoreField={scoreField} max={minMaxScoreValues[1]} value={value} xLabel={xLabel} yLabel={yLabel} />
        </div>
         </Grid>
        <Grid item xs={12} class = "child">
         <div style={{ width: "100%"}}>
             <Slider track={false} 
                 getAriaLabel={() => "Data range"}
                 min={minMaxScoreValues[0]} max={minMaxScoreValues[1]} 
                 value={value}
                 onChange={handleChange}
                 valueLabelDisplay="on"
                 getAriaValueText={convertValueToText}
                 
          />
             </div>
      </Grid>
    </Grid>
);
}