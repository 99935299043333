import React from 'react';
import { Button, Box, Typography,  MenuItem, FormControl,  OutlinedInput,Backdrop, CircularProgress } from '@mui/material';
import { ResponsiveLine } from '@nivo/line';
// import { UserContext } from './UserContext.js';
// const [contextValue, setContextValue] = React.useContext(UserContext);

// Component expects a scoreDistribution array which will contain {x, y} pairs - x: score  and y: number of candidates at that score
// Typically this is passed down from the server side, through RangeSlider
// The X and Y axis lables can be passed through as well
// The default values for the two slider button points is passed through value - and is set up with defaults as Score and Candidates
// The score type on which the scores in x are based is passed as scoreField
// Currently that is not displayed, however in the future this may be displayed on the charts as well

const AreaWithSlider = ({scoreDistribution=null, min=0, max=100, scoreField = "overall", value=[0,100], xLabel="Score", yLabel="Candidates"}) => {
  // Generate data array

  if (scoreDistribution === null) { 
    console.log("no input data has been provided for AreaWithSlider");
 
    return (
      <div >
        No Data has been provided for the chart (candidate distribution by score)
      </div>
    )
  }
  else {
    console.log("Data has been provided into AreaSlider");
  }
  const scoresArray = scoreDistribution.map(function(scoreVal) {return scoreVal['x']; });
  const scoresMin = Math.min.apply(null, scoresArray);
  const scoresMax = Math.max.apply(null, scoresArray);
//  console.log("Scores min: " + scoresMin + "  max: " + scoresMax);

  const candidatesArray = scoreDistribution.map(function(scoreVal) {return scoreVal['y']; });
  const candidatesMin =  0; 
  const candidatesMax = Math.max.apply(null, candidatesArray);

//  console.log("Candidates min: " + candidatesMin + "  max: " + candidatesMax);

  
  const startSilver = (value[0]-scoresMin)/(scoresMax-scoresMin);
  const stopOrange = ((value[0]+ (value[1]-value[0])*0.90 - scoresMin) < scoresMax) ? (value[0]+ (value[1]-value[0])*0.90 - scoresMin)/(scoresMax): 1 ;
  const startGreen = (value[1]*1.1 < scoresMax) ? ((value[1])*1.1/(scoresMax)) : 1;
  const stopGreen = 1;
 
    
  const scoresDataForChart = [ {"id": "Scores Data", "data": scoreDistribution} ];
  // console.log('data is ' + scoresDataForChart[0]["data"].length + " long");
  // console.log('scores data for chart\nx:\n' + scoresDataForChart[0].data[0].x +'\ny:\n' + scoresDataForChart[0]["data"][0]['y']);

   
  return ( 
  <Box sx= {{width:"100%", height:"100%"}} >
      <svg id="colorGradient" width="0" height="0">
        <defs>
          <linearGradient id="colorByScore" x1="0" y1="0" x2="1" y2="0">
          <stop offset={0} stopColor="#FD1D00" stopOpacity={0.66} />
          {/* <stop offset={startSilver} stopColor="#FD1D00" stopOpacity={1} /> */}
          <stop offset={startSilver} stopColor="#532383" stopOpacity={0.66} />
          {/* <stop offset={stopOrange} stopColor="#532383" stopOpacity={1} /> */}
          <stop offset={startGreen} stopColor="#0064F2" stopOpacity={0.66} />
          </linearGradient>
        </defs>
      </svg>
      {/* <svg xmlns="http://www.w3.org/2000/svg" width="1003" height="98" viewBox="0 0 1003 98" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0 0C0 0 10.8092 15.8005 15.9883 19.2384C21.1675 22.6763 25.3936 11.5434 32.753 11.5146C40.1124 11.4857 44.4946 29.4261 52.5284 26.8673C60.5621 24.3085 66.1053 20.5723 69.8318 16.6321C73.5584 12.6919 84.0272 1.10965 92.6971 2.55879C101.367 4.00793 108.74 13.7377 113.091 19.1909C117.441 24.6442 126.686 37.1025 130.409 39.9706C134.132 42.8388 136.699 29.4514 146.599 30.978C156.498 32.5045 159.246 43.3867 163.476 45.0697C167.706 46.7527 178.596 49.8964 184.158 47.3376C189.72 44.7788 200.226 35.8231 203.934 31.9849C207.642 28.1467 216.911 19.1909 228.035 19.1909C239.159 19.1909 268.753 34.2704 277.622 33.5545C286.491 32.8385 290.61 24.2581 294.777 24.3085C298.943 24.3589 304.556 33.8169 309.863 32.2612C315.17 30.7055 327.53 14.0733 338.654 15.3527C349.777 16.6321 353.485 21.7497 357.193 24.3085C360.901 26.8673 371.529 36.7874 375.176 37.5846C378.822 38.3819 383.557 28.3288 391.761 28.3288C399.964 28.3288 401.908 34.8975 408.04 37.5846C414.172 40.2717 415.799 42.4118 423.898 42.4118C431.997 42.4118 431.064 40.7483 440.239 37.5846C449.414 34.4209 441.863 29.3398 460.126 31.2233C464.046 31.6276 489.792 26.099 528.612 25.5727C554.06 25.2276 585.416 31.4537 618.912 27.4564C642.427 24.6502 665.375 3.79896 690.95 1.71445C724.432 8.6207 742.187 17.4106 777.191 23.6891C880.245 19.5891 992.289 -0.797077 1003 1.71433V97.3333H0V0Z" fill="url(#paint0_linear_1286_16092)" fill-opacity="0.41"/>
<defs>
<linearGradient id="paint0_linear_1286_16092" x1="-1.64621e-06" y1="59.4767" x2="1003.45" y2="59.4767" gradientUnits="userSpaceOnUse">
<stop stop-color="#FD1D00"/>
<stop offset="0.510417" stop-color="#532383"/>
<stop offset="1" stop-color="#0064F2"/>
</linearGradient>
</defs>
</svg> */}
      <ResponsiveLine
        data={scoresDataForChart}
        xScale={{ type: 'linear', min: scoresMin, max: scoresMax }}
        yScale={{ type: 'linear', min: candidatesMin, max:candidatesMax}}
        curve={"monotoneX"}
        enableArea={true}
        areaOpacity={0.5}
        colors={['url(#colorByScore)']}
        axisBottom={{
          legend: xLabel,
          legendOffset: -20,
          tickValues: scoresArray,
        }}
        axisLeft={{
          legend: yLabel,
          legendOffset: 10,
          tickValues:candidatesArray
        }}
        markers={[
          {
              axis: 'x',
              value: value[0],
              legend: 'Consideration',
              lineStyle: {
                  stroke: 'black',
                  strokeDasharray: [3,6]
              },
              textStyle: {
                  fill: 'black',
              },
          },
          {
            axis: 'x',
            value: value[1],
            legend: 'Selection',
            lineStyle: {
                stroke: 'green',
                strokeDasharray: [3,6]
              },
            textStyle: {
                fill: 'green',
            },
        },

      ]}
        layers={[
          "axes",
          "areas",
          "markers",
          "mesh",
          "legends",
        ]}
      />
    </Box>
    );
};

export default AreaWithSlider;
