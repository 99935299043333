import { ResponsiveParallelCoordinates } from '@nivo/parallel-coordinates'
import { useState } from 'react';

// const weightageToColor = (weightage, index) => {
//   const colors = ['#f94144', '#f8961e', '#43aa8b', '#577590'];
//   const colorIndex = index % colors.length;
//   return colors[colorIndex];
// };

const ParallelChartComponent = ({ skillsData }) => {
  const data = [
    {

      id: "profileName"
    }
  ];

  const variableList = [];
  const axesList = [];
  
  const [start, setStart] = useState(1);
  for(const skill of skillsData) { 
    data[0][skill.skillName] = skill.onetSkillMastery;
    if (start) {
      axesList.push({orient: 'left', 
           domain: { line: { stroke: '##1f77b4', 
                            strokeWidth: 1,}}, 
                      legend: skill.skillName});
      variableList.push({
        key: skill.skillName,
        id: skill.skillName,
        label: skill.skillName,
        value: skill.skillName,
        type: 'linear',
        curve: 'monotoneX',
        min: 0,
        max: 10,
        legend:  skill.skillName,
        legendPosition: 'start',
        tickValues: 11,
        ticksPosition: 'before'
      });
      setStart(0);
    }
    else {
      axesList.push({orient: 'left', 
           domain: { line: { stroke: 'transparent', 
                          }}, 
                      legend: skill.skillName});
      variableList.push({
        key: skill.skillName,
        type: 'linear',
        min: 0,
        max: 10,
        legend:  skill.skillName,
        legendPosition: 'start'
      
      });
    }
    }
    const variables = variableList;
    const customTheme = {
      axis: {
        ticks: {
          line: {
            stroke: '#777777' // Customize the color of the vertical lines
          }
        }
      }
    }
    // console.log('variables are\n',variableList,"\n","data is \n",data);

  // const CustomSymbol = ({ size, color, borderWidth }) => (
  //   <circle r={size / 2} fill={color} strokeWidth={borderWidth} stroke="black" />
  // );
  

  return (
    <div style={{ height: '500px', width: '100%' }}>
    <ResponsiveParallelCoordinates
        data={data}
        variables={variables}
        axes={axesList}
        axisTop={{
            orient: 'top',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: '',
            legendOffset: 36
        }}
        lineOpacity={0.8}
        colors={{ scheme: 'accent' }}
        axisRight={null}
        margin={{ top: 50, right: 60, bottom: 50, left: 60 }}
        axisBottom={{
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Mastery Level',
            legendPosition: 'middle',
            legendOffset: 36
        }}
        lineWidth={3}
        
        axisLeft={null}
    />
</div>  );
};

export default ParallelChartComponent;
