import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  InputBase,
  IconButton,
  Card,
  CardContent,
  Avatar,
  AvatarGroup,
  Switch,
  TextField,
  MenuItem
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import axios from "axios";

import { getJobDetailsThunk } from "../services/jobthunk";
import JobDescription from "./jobdescription/jobdescription";
import Profiles from "./profiles/profile";
import Resumes from "./resumes/resume";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Search as SearchIcon } from "@mui/icons-material";
import { Close as CloseIcon } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";

function JobDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state?.loc || -1;
  const { id } = useParams();
  const dispatch = useDispatch();
  const [query, setQuery] = useState( () => { return new URLSearchParams(location.search)} );
  const defaultTab = query?.get("tab") === "resumes" ? "3" : "1";
  console.log("Set default tab as: ", defaultTab);
  const [candidate, setCandidate] = useState( () => { 
                                              return query.get("candidate") || null;
                                            });
  console.log("Found candidate: ", candidate);

  const handleskillsvireChange = () => {
    settrans((prev) => !prev);
  };

  useEffect(() => {
    // Update the candidate state whenever the URL search parameters change
    const updatedQuery = new URLSearchParams(location.search);
    const newCandidate = updatedQuery.get("candidate") || null;
    setCandidate(newCandidate);
  }, [location.search]);
  const [value, setValue] = useState(defaultTab);
  const jobDetails = useSelector((reduxState) => reduxState.job.job);
  const [importantTitles, setImportantTitles] = useState([]);
  const [importantIndustries, setImportantIndustries] = useState([]);
  const [yearsOfIndustryExperience, setYearsOfIndustryExperience] = useState(0);
  const [yearsOfTotalExperience, setYearsOfTotalExperience] = useState(0);
  const [rangeOfTotalExperience, setRangeOfTotalExperience] = useState([2,10]);

  const API_BASE = process.env.REACT_APP_API;

  const API_GET_USERS = `${API_BASE}/main/get-users`;
  const API_ADD_COLLABORATORS = `${API_BASE}/main/jobs/edit-colab`;

  const API_SET_IMPORTANT_TITLES = `${API_BASE}/main/jobs/set-important-titles`;
  const API_SET_IMPORTANT_INDUSTRIES = `${API_BASE}/main/jobs/set-important-industries`;
  const API_SET_TOTAL_EXPERIENCE = `${API_BASE}/main/jobs/set-total-experience`;
  const API_SET_RANGE_EXPERIENCE = `${API_BASE}/main/jobs/set-range-experience`;

  const [openDialog, setOpenDialog] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedCollaborators, setSelectedCollaborators] = useState([]);
  const token = localStorage.getItem("token");
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [formattedSyncDateTime, setFormattedSyncDateTime] = useState("");

  // const [jobTitles, setJobTitles] = useState([]);
  // const [selectedJobTitle, setSelectedJobTitle] = useState('ALL');
  // const API = `${API_BASE}/main/get-titles`;
  const [trans, settrans] = useState(true);

  
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(API_GET_USERS, {
          headers: {
            Authorization: token,
          },
        });
        setUsers(response.data.data);
      } catch (error) {
        console.error("Failed to fetch users", error);
      }
    };
    fetchUsers();
  }, []);

  // useEffect(() => {
  //   const fetchJobTitles = async () => {
  //     try {
  //       const token = localStorage.getItem('token');
  //       const response = await axios.get(API, {
  //         headers: {
  //           Authorization: token,
  //         },
  //       });
  //       setJobTitles(response.data.data);
  //     } catch (error) {
  //       console.error("Error fetching job titles:", error);
  //     }
  //   };
  
  //   fetchJobTitles();
  // }, []);

  // const handleJobTitleChange = (event) => {
  //   setSelectedJobTitle(event.target.value);
  // };
  
  

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const currentTab = query.get("tab") === "resumes" ? "3" : "1";
    setValue(currentTab);
  }, [dispatch, id, location.search]);

  useEffect(() => {
    dispatch(getJobDetailsThunk(id)); // Fetch job details
    console.log("job Details are:\n", jobDetails);
    setupSyncTime();
  }, [dispatch, id]);

  // useEffect(() => {
  //   dispatch(getJobDetailsThunk(id)); // Fetch job details
  //   console.log("job Details are:\n", jobDetails);
  // }, [dispatch, id]);

  // useEffect(() => {
  //   console.log("Collaborators are", jobDetails?.collaborators);
  //   if (
  //     jobDetails &&
  //     jobDetails.collaborators &&
  //     jobDetails.collaborators.length > 0
  //   ) {
  //     const mappedCollaborators = users.filter((user) =>
  //       jobDetails.collaborators.some((collab) => collab.userId === user.userId)
  //     );
  //     setSelectedCollaborators(mappedCollaborators);
  //   }
  // }, [id, users]);

  useEffect(() => {
    // Set collaborators after fetching users and job details
    if (users.length > 0 && jobDetails && jobDetails.collaborators) {
      const mappedCollaborators = users.filter(user =>
        jobDetails.collaborators.some(collab => collab.userId === user.userId)
      );
      setSelectedCollaborators(mappedCollaborators);
    }
  }, [users, jobDetails]); // Dependency on users and jobDetails
  
useEffect(() => {
    // Set collaborators after fetching users and job details
    if(!candidate) {
      // handle jobTitle
      if (jobDetails.importantTitles) { 
        setImportantTitles(jobDetails.importantTitles);
      }
      else {
        if (jobDetails && jobDetails.jobTitle) {
          handleSaveImportantTitles([{ label: jobDetails.jobTitle.trim(), value: jobDetails.jobTitle.trim(), selected: true}]);
          const jobTitles = jobDetails.jobTitle.trim().split(' ')
          if (jobTitles.length > 1) {
            jobTitles.forEach(thisTitle => {
              handleSaveImportantTitles([{ label: thisTitle, value: thisTitle, selected: true}]);             
            });
          }
        };
      }

      // handle jobIndustry
      if (jobDetails.importantIndustries) { 
        setImportantIndustries(jobDetails.importantIndustries);
      }
      if (jobDetails.yearsOfIndustryExperience) {
        setYearsOfIndustryExperience(jobDetails.yearsOfIndustryExperience);
      }
      if (jobDetails.yearsOfTotalExperience) {
        setYearsOfTotalExperience(jobDetails.yearsOfTotalExperience);
      }
      if (jobDetails.rangeOfTotalExperience) {
        setRangeOfTotalExperience(jobDetails.rangeOfTotalExperience);
      }

    }
  }, [jobDetails,candidate]); // Dependency on users and jobDetails
  

  const handleBack = () => {
    navigate("/"+localStorage.getItem("clientName") + "/home");
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const getInitials = (name) => {
    return name
      .split(" ")
      .slice(0, 2)
      .map((word) => word[0])
      .join("")
      .toUpperCase();
  };

  const getColorForName = (name) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < name.length; i += 1) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    if (value) {
      const filtered = users.filter(
        (s) =>
          
        (s.userName.toLowerCase().includes(value.toLowerCase()) || 
        s.email.toLowerCase().includes(value.toLowerCase())) &&
          !selectedCollaborators.some((u) => u.userId === s.userId) // Exclude already selected users
      );
      setFilteredSuggestions(filtered);
    } else {
      setFilteredSuggestions([]);
    }
  };

  const handleCardClick = (user) => {
    // Check if the user is already selected
    if (
      !selectedCollaborators.some(
        (selectedUser) => selectedUser.userId === user.userId
      )
    ) {
      setSelectedCollaborators((prevUsers) => [...prevUsers, user]);
      // Re-filter suggestions to remove the selected user
      const updatedSuggestions = filteredSuggestions.filter(
        (s) => s.userId !== user.userId
      );
      setFilteredSuggestions(updatedSuggestions);
    }
  };

  const handleRemoveUser = (userId) => {
    setSelectedCollaborators((prevUsers) =>
      prevUsers.filter((u) => u.userId !== userId)
    );
  };

  const handleSaveCollaborators = () => {
    axios
      .post(
        API_ADD_COLLABORATORS,
        {
          jobId: id,
          collaborators: selectedCollaborators.map((collab) => collab.userId),
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        handleCloseDialog();
        dispatch(getJobDetailsThunk(id));
      })
      .catch((error) => {
        console.error("Error saving collaborators:", error);
      });
  };


  const handleSaveImportantTitles = (importantTitles) => {
    axios
      .post(API_SET_IMPORTANT_TITLES,
        {
          jobId: id,
          importantTitles: importantTitles,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        dispatch(getJobDetailsThunk(id));
      })
      .catch((error) => {
        console.error("Error saving important Titles:", error);
      });
  };

  const handleSaveImportantIndustries = (importantIndustries, yearsOfIndustryExperience) => {
    setImportantIndustries(importantIndustries);
    setYearsOfIndustryExperience(yearsOfIndustryExperience);
    axios
      .post(API_SET_IMPORTANT_INDUSTRIES,
        {
          jobId: id,
          importantIndustries: importantIndustries,
          yearsOfIndustryExperience: yearsOfIndustryExperience
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        dispatch(getJobDetailsThunk(id));
      })
      .catch((error) => {
        console.error("Error saving important Industries:", error);
      });
  };

  const handleSaveTotalExperience = (yearsOfTotalExperience) => {
    setYearsOfTotalExperience(yearsOfTotalExperience);
    axios
      .post(API_SET_TOTAL_EXPERIENCE,
        {
          jobId: id,
          yearsOfTotalExperience: yearsOfTotalExperience
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        dispatch(getJobDetailsThunk(id));
      })
      .catch((error) => {
        console.error("Error saving total Experience:", error);
      });
  };

  const handleSaveRangeExperience = (rangeOfTotalExperience) => {
    setRangeOfTotalExperience(rangeOfTotalExperience);
    axios
      .post(API_SET_RANGE_EXPERIENCE,
        {
          jobId: id,
          rangeOfTotalExperience: rangeOfTotalExperience
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        dispatch(getJobDetailsThunk(id));
      })
      .catch((error) => {
        console.error("Error saving range of Total Experience:", error);
      });
  };

  const resetCandidate = () => {
    setCandidate(null);
    // setQuery(new URLSearchParams("?tab=resumes"));
    // setValue("3");
    
  };

  
  
  const setupSyncTime = () => {
    const formattedLastSyncTime = (jobDetails?.atsLastSyncTime 
      ? new Date(jobDetails.atsLastSyncTime['$date']).toLocaleString(undefined, {
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          month: 'numeric',
          day: 'numeric',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        })
      : '');
      setFormattedSyncDateTime(formattedLastSyncTime
      ? `${formattedLastSyncTime.split(',')[0]} at ${formattedLastSyncTime.split(',')[1]}`
      : '');
  };

  useEffect (() => {
    setupSyncTime();
  }, [jobDetails]);

 
  return (
    <Box sx={{ m: 2, typography: "body1" }}>
      <Typography variant="h5">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton onClick={handleBack}>
              <ArrowBackIcon />
            </IconButton>
            {jobDetails?.jobTitle}&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            {jobDetails?.blujinSource && (
            <Typography variant="body2" > Last Sync: {formattedSyncDateTime}</Typography>)}
          </Box>
          <Box
            sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={handleOpenDialog}
          >
            <AvatarGroup max={3} key = {{id}}>
              {selectedCollaborators.length > 0 ? (
                selectedCollaborators.map((user, index) => (
                  <Avatar
                    key={index}
                    src={user.image}
                    alt={user.userName}
                    sx={{ backgroundColor: getColorForName(user.userName) }}
                  >
                    {getInitials(user.userName)}
                  </Avatar>
                ))
              ) : (
                <Avatar sx={{ backgroundColor: "rgb(71, 135, 210)" }}>+</Avatar>
              )}

              {/* {selectedCollaborators.length > 3 && (
    <Avatar style={{ backgroundColor: '#4787D2' }}>
      +{selectedCollaborators.length - 3}
    </Avatar>)} */}
            </AvatarGroup>
          </Box>
        </Box>
      </Typography>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Box 
          sx={{ 
            display: "flex", 
            justifyContent: "space-between", 
            alignItems: "center" 
          }}
        >
          <TabList
            onChange={handleChange}
            aria-label="job details tabs"
            sx={{
              "& .MuiTab-root": {
                color: "#102844 !important",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "20px",
              },
              "& .MuiTabs-indicator": {
                height: "4px",
              },
            }}
          >
            <Tab label="Job Description" value="1" />
            <Tab label="Profiles" value="2" />
            <Tab label="Resumes" value="3" />
          </TabList>
          <Box sx={{ display: "flex", alignItems: "center", paddingRight: "20px" }}>
          <Switch
        checked={trans}
        onChange={handleskillsvireChange}
      />
      <Typography
        style={{
          marginLeft: 8,
          color: trans ? '#4687CF' : '#000000' // Color changes based on the 'direct' state
        }}
      >
        Transferable
      </Typography>          
      </Box>
        </Box>
        </Box>
        <br />
        <TabPanel value="1" sx={{ p: 0 }}>
          <JobDescription jobDetails={jobDetails} />
        </TabPanel>
        <TabPanel value="2" sx={{ p: 0 }}>
          <Profiles jobId={id} />
        </TabPanel>
        <TabPanel value="3" sx={{ p: 0 }}>
          <Resumes
            jobId={id}
            reqId={jobDetails?.reqId}
            candidate={candidate}
            range={jobDetails?.range || [0, 100]}
            jobTitle={jobDetails?.jobTitle}
            resetCandidate={resetCandidate}
            importantTitles={importantTitles}
            saveImportantTitles={handleSaveImportantTitles}
            jobDetails={jobDetails}
            trans={trans}
            handleskillsvireChange={handleskillsvireChange}
            importantIndustries={importantIndustries}
            yearsOfIndustryExperience={yearsOfIndustryExperience}
            saveImportantIndustries={handleSaveImportantIndustries}
            yearsOfTotalExperience={yearsOfTotalExperience}
            saveTotalExperience={handleSaveTotalExperience}
            rangeOfTotalExperience={rangeOfTotalExperience}
            saveRangeExperience={handleSaveRangeExperience}
          />
        </TabPanel>
      </TabContext>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Add/Edit Collaborators</DialogTitle>
        <DialogContent>
          {/* <Autocomplete
            multiple
            id="collaborator-multiselect"
            options={users}
            getOptionLabel={(option) => option.userName}
            value={selectedCollaborators}
            onChange={(event, newValue) => {
              setSelectedCollaborators(newValue);
            }}
            getOptionSelected={(option, value) => option.userId === value.userId}
            disableCloseOnSelect
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Collaborators"
                placeholder="Select Collaborators"
              />
            )}
          /> */}
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={1}>
              <Grid item xs={8}>
                <Typography variant="subtitle1" gutterBottom>
                  Add Collaborators
                </Typography>
                <InputBase
                  fullWidth={true}
                  startAdornment={
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  }
                  placeholder="Search for User Name, Email ID"
                  onChange={handleSearchChange}
                />
                {filteredSuggestions.length > 0 && (
                  <div>
                    {filteredSuggestions.map((suggestion) => (
                      <Card
                        key={suggestion.userId}
                        style={{ margin: "8px 0" }}
                        onClick={() => handleCardClick(suggestion)}
                      >
                        <CardContent
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <Avatar
                            src={suggestion.image}
                            sx={{
                              backgroundColor: getColorForName(
                                suggestion.userName
                              ),
                              marginRight: "8px",
                            }}
                          >
                            {getInitials(suggestion.userName)}
                          </Avatar>
                          <div>
                            <div>{suggestion.userName}</div>
                            <small>{suggestion.email}</small>
                          </div>
                        </CardContent>
                      </Card>
                    ))}
                  </div>
                )}
              </Grid>
              <Grid item xs={8}>
                {selectedCollaborators.length > 0 && (
                  <div style={{ marginTop: "20px" }}>
                    <Typography variant="subtitle1" gutterBottom>
                      Your Collaborators
                    </Typography>
                    {selectedCollaborators.map((user) => (
                      <Card
                        key={user.userId}
                        style={{
                          margin: "8px 0",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Avatar
                          src={user.image}
                          sx={{
                            backgroundColor: getColorForName(user.userName),
                            marginRight: "8px",
                          }}
                        >
                          {getInitials(user.userName)}
                        </Avatar>
                        <div style={{ flexGrow: 1 }}>
                          <div>{user.userName}</div>
                          <small>{user.email}</small>
                        </div>
                        <IconButton
                          onClick={() => handleRemoveUser(user.userId)}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Card>
                    ))}
                  </div>
                )}
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSaveCollaborators} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default JobDetails;
