import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
} from '@mui/material';
import { gethealth } from '../services/analyticsservice';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Papa from 'papaparse';


const HealthDataGrid = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await gethealth();
        const parsedData = response.map(item => JSON.parse(item));
        setData(parsedData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data: ", error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);
  const [anchorEl, setAnchorEl] = useState(null);

const handleClick = (event) => {
  setAnchorEl(event.currentTarget);
};

const handleClose = () => {
  setAnchorEl(null);
};

const exportToCSV = () => {
  // Prepare the data in the format matching the table's display
  const preparedData = data.map(row => ({
    jobTitle: row.jobTitle,
    hiringManager: row.hiringManager,
    location: row.location,
    status: row.status,
    owner: row.owner,
    daysOpen: row.daysOpen,
    avgDecisionTime: row.overallDecisionInfo?.avgDecisionTime ?? 'N/A',
    ...headerGroups.reduce((acc, group) => ({
      ...acc,
      ...headerGroupFields.reduce((subAcc, field) => ({
        ...subAcc,
        [`${group}_${field}`]: getDecisionInfo(row, group, field)
      }), {})
    }), {})
  }));

  // Unparse the prepared data to CSV format
  const csv = Papa.unparse(preparedData);

  // Rest of the function remains the same
  const blob = new Blob([csv], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'health_data.csv';
  a.click();
  window.URL.revokeObjectURL(url);
  handleClose();
};


  const getDecisionInfo = (row, type, field) => {
    const info = row?.decisionInfo?.find((info) => info._id === type);
    
    if ((field === 'avgScore' || field === 'minScore' || field === 'maxScore') && info && info[field]) {
      return parseFloat(info[field].toFixed(2));  
    }
    
    return info?.[field] ?? 'N/A';
  };
  
  

  const stickyHeaderStyle = { position: 'sticky', top: 0, backgroundColor: '#fff', zIndex: 100, borderRight: 'none', };
  const stickyColumnStyle = {
    position: 'sticky',
    left: 0,
    backgroundColor: '#fff',
    minWidth: '300px',
    zIndex: 100,
    borderRight: 'none',
  };
  const cellWithoutBordersStyle = {
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: 'none'
    
  };
  const headerGroups = ["Advanced", "Declined", "No reviews"];
  const headerGroupsDisplay = ["Advanced", "Rejected", "No reviews"];
  const headerGroupFields = ["avgScore", "maxScore", "minScore", "count"];
  
const headerCellStyle = {
  borderBottom: 'none'
};

  return (
    <>
      <br/>
      <h4 style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
  Job Health Report
  <IconButton
  aria-label="more"
  aria-controls="long-menu"
  aria-haspopup="true"
  onClick={handleClick}
  style={{
    background: '#4787D2',
    width: '39px',
    height: '32px',
    borderRadius: '5px 5px 0px 0px'
  }}
>
<MoreVertIcon style={{ transform: 'rotate(90deg)' }} />
</IconButton>

</h4>
<Menu
  id="long-menu"
  anchorEl={anchorEl}
  keepMounted
  open={Boolean(anchorEl)}
  onClose={handleClose}
>
  <MenuItem onClick={exportToCSV}>Export to CSV</MenuItem>
</Menu>
      <br/>
      <TableContainer component={Paper} style={{ height: 400, width: '100%' }}>
        <Table aria-label="health table" size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className="sticky-column" style={{ ...stickyHeaderStyle, ...stickyColumnStyle, ...headerCellStyle }}>Job Title</TableCell>
              <TableCell style={headerCellStyle}>Hiring Manager</TableCell>
              <TableCell style={headerCellStyle}>Location</TableCell>
              <TableCell style={headerCellStyle}>Status</TableCell>
              <TableCell style={headerCellStyle}>Owner</TableCell>
              <TableCell style={headerCellStyle}>Days Open</TableCell>
              <TableCell style={headerCellStyle}>Avg Decision Time</TableCell>
              {headerGroupsDisplay.map((group) => (
                <TableCell colSpan={4} align="center" style={headerCellStyle}>{group}</TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell className="sticky-column" style={{ ...stickyHeaderStyle, ...stickyColumnStyle, ...cellWithoutBordersStyle }}></TableCell>
              <TableCell style={cellWithoutBordersStyle}></TableCell>
              <TableCell style={cellWithoutBordersStyle}></TableCell>
              <TableCell style={cellWithoutBordersStyle}></TableCell>
              <TableCell style={cellWithoutBordersStyle}></TableCell>
              <TableCell style={cellWithoutBordersStyle}></TableCell>
              <TableCell style={cellWithoutBordersStyle}></TableCell>
              {headerGroups.map(() => (
                headerGroupFields.map((field) => (
                  <TableCell style={cellWithoutBordersStyle}>{field}</TableCell>
                ))
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={19} align="center">
                  Loading...
                </TableCell>
              </TableRow>
            ) : (
              data.map((row) => (
                <TableRow key={row._id}>
                  <TableCell className="sticky-column" style={stickyColumnStyle}>{row.jobTitle}</TableCell>
                  <TableCell>{row.hiringManager}</TableCell>
                  <TableCell>{row.location}</TableCell>
                  <TableCell>{row.status}</TableCell>
                  <TableCell>{row.owner}</TableCell>
                  <TableCell>{row.daysOpen}</TableCell>
                  <TableCell>{row.overallDecisionInfo?.avgDecisionTime ?? 'N/A'}</TableCell>
                  {headerGroups.map((group) => (
                    headerGroupFields.map((field) => (
                      <TableCell>{getDecisionInfo(row, group, field)}</TableCell>
                    ))
                  ))}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <style jsx>{`
        .sticky-column::before {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          right: -3px;
          width: 6px;
          box-shadow: 3px 0 6px -2px rgba(0, 0, 0, 0.3);
          z-index: 99;
        }
      `}</style>
    </>
  );
};

export default HealthDataGrid;
