import { createSlice } from "@reduxjs/toolkit";
import { createJobThunk, extractJdThunk, getJobThunk, getJobDetailsThunk, updatejobThunk, getJobDecisionsThunk, updaterangeThunk} from "../services/jobthunk"; 
import { updatejobrange } from "../services/createjobservice";

const initialState = {
  job: [],
  status: 'idle',
  error: null,
  tempJob: [{ skillName: '', ONETSkillMastery: 0, skillCriticality: 'Low' }],
  jobs: []
};

export const jobSlice = createSlice({
  name: 'job',
  initialState,
  reducers: {
  },
  extraReducers: builder => {
    builder
      .addCase(createJobThunk.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createJobThunk.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(createJobThunk.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(extractJdThunk.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(extractJdThunk.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.tempJob = action.payload;
      })
      .addCase(extractJdThunk.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(getJobThunk.pending, (state) => {
        state.status = 'loading';
        state.specificState = 'loading';
      })
      .addCase(getJobThunk.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.jobs = action.payload;
        state.specificState = 'succeeded';
      })
      .addCase(getJobThunk.rejected, (state, action) => {
        state.status = 'failed';
        state.specificState = 'failed';
        state.error = action.error.message;
      })
      .addCase(getJobDetailsThunk.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getJobDetailsThunk.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.job = action.payload;
      })
      .addCase(getJobDetailsThunk.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(getJobDecisionsThunk.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getJobDecisionsThunk.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.job = action.payload;
      })
      .addCase(getJobDecisionsThunk.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(updatejobThunk.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updatejobThunk.fulfilled, (state, action) => {
        state.status = 'succeeded';
        
      })
      .addCase(updatejobThunk.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(updaterangeThunk.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updaterangeThunk.fulfilled, (state, action) => {
        state.status = 'succeeded';
        
      })
      .addCase(updaterangeThunk.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      ;
  }
});

export default jobSlice.reducer;
