import axios from 'axios';


//const API_BASE = 'http://127.0.0.1:8000';
const API_BASE = process.env.REACT_APP_API;

const API = `${API_BASE}/main/settings/`;

const instance = axios.create({
  baseURL: API,
  headers: {}
});
// Add a request interceptor to the instance
instance.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = token;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});



export const getbasic = async () => {
    try {
      const response = await instance.get('get-basic');
      return response.data.data;

    } catch (error) {
      console.error("Error in getJob:", error);
      throw error;
    }
  };


  export const updatebasic = async (update) => {
    const setting = { 'update': update};
    const response = await instance.post('update-basic', setting);
    return response;
  };



  