import React from 'react';
import pdfFile from './Resume Explorer Help Content.pdf'; // Adjust the path as necessary

function Help() {
  return (
    <iframe
      src={pdfFile}
      style={{ width: '100%', height: '650px', border: 'none' }}
      title="Embedded PDF Content"
    ></iframe>
  );
}

export default Help;
