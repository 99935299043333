import React, { useState, useEffect, useRef } from 'react';
import {
  Box, Grid, Button,
} from '@mui/material';

import { makeStyles } from '@material-ui/core/styles';
import DialogComponent from '../components/dialoguebox/dialogue';
import { useSelector, useDispatch } from "react-redux";
import { getJobThunk, postResumesThunk } from '../services/jobthunk';

import ResumeUploader from '../utilities/resume_uploader/resume_uploader';

import JSZip from 'jszip';


const useStyles = makeStyles({
  root: {
    width: '100%',           // Reducing the length
    marginLeft: 0,           // Aligning to the left
    backgroundColor: 'rgba(71, 135, 210, 0.10)',
    padding: '2%',
    paddingRight: '45%',
    textAlign: 'left',
  },
})

const UploadResumes = ({ show, handleClose,jobId,reqId }) => {
  const [btn, setbtn] = useState(true)
  const [resumeFiles, setResumeFiles] = useState(new JSZip());

  const dispatch = useDispatch();

  const handleFilesChange = (files) => {
    setResumeFiles(files);

  };
  const handleClose2 = () => {
    // Call handleClose from the prop
    handleClose();
    setResumeFiles('');
  };
  const classes = useStyles();

  const handleSubmit = async () => {
    setbtn(false)
    const resumeBlob = await resumeFiles.generateAsync({ type: "blob" })
    if (resumeBlob instanceof Blob) {
      const body = {"status":jobId,"reqId":reqId};
      const temp2 = await dispatch(postResumesThunk({ jobId: body, resumeBlob }));
      console.log(temp2);
    }
    handleClose2();
    setbtn(true)
    
  };

  const stepActions =  
    <>
      <Button onClick={handleClose2}>Cancel</Button>
      <Button variant="contained" style={{ backgroundColor: '#4787D2', color: '#FFF' }} onClick={() => {
        if (btn) {
          handleSubmit();
        }
      }}
      disabled={!btn}
    >
      {!btn ? 'Submitting...' : 'Submit'}</Button>
    </>
;

  return (
    <DialogComponent
      show={show}
      title="Add Resumes"
      actions={stepActions}
    >

      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
              <Grid item xs={8}>
                <ResumeUploader onFilesChange={handleFilesChange} previouszip={resumeFiles} />
              </Grid>
        </Grid>
      </Box>
    </DialogComponent>
  );

};

export default UploadResumes;
