import { createSlice } from "@reduxjs/toolkit";
import { getResumesThunk,updatedecisionThunk } from "../services/resume_thunk"; // Changed to camelCase

const initialState = {
  resumes: [],
  status: 'idle',
  error: null,
};

export const resumeSlice = createSlice({
    name: 'resume',
    initialState,
    reducers: {
    },
    extraReducers: builder => {
      builder
        
        .addCase(getResumesThunk.pending, (state) => { // Changed to camelCase
          state.status = 'loading';
          state.resumes=[];
          state.specificState = 'loading'; // Fixed typo
        })
        .addCase(getResumesThunk.fulfilled, (state, action) => { // Changed to camelCase
          state.status = 'succeeded';
          state.resumes = action.payload; 
        })
        .addCase(getResumesThunk.rejected, (state, action) => { // Changed to camelCase
          state.status = 'failed';
          state.error = action.error.message;
        })
        .addCase(updatedecisionThunk.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(updatedecisionThunk.fulfilled, (state, action) => {
          state.status = 'succeeded';
          
        })
        .addCase(updatedecisionThunk.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.error.message;
        });
    }
});
  
export default resumeSlice.reducer;
