// SkillsTableComponent.jsx

import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  FormControl,
  Paper,
  IconButton,
  TextField,
} from '@mui/material';
import { styled } from '@mui/system';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';


const StyledTableCell = styled(TableCell)({
  fontSize: '14px',
  padding: '6px 10px',
  borderBottom: '1px solid #ddd',
});

const StyledTextField = styled(TextField)({
  width: '100%',
  '& .MuiInputBase-input': {
    fontSize: '14px',
    padding: '8px 10px',
    '@media (max-width: 600px)': {
      fontSize: '12px',
    },
  },
});

const StyledSelect = styled(Select)({
  fontSize: '14px',
  padding: '8px 10px',
  height: '25px',
  width: '125px',
});

const TableHeaderCell = styled(StyledTableCell)({
  fontWeight: 'bold',
});

const StyledTableRow = styled(TableRow)({
  '&:nth-of-type(odd)': {
    backgroundColor: '#f7f7f7',
  },
});

const StyledTable = styled(Table)({
  border: '1px solid #ddd',
});

const SkillsTableComponent = ({ skillsData, handleSkillChange, handleDeleteSkill, addSkill }) => {
  return (
    <TableContainer component={Paper} style={{ border: '1px solid #ddd', height: '50%' }}>
      <StyledTable>
        <TableHead>
          <TableRow>
            <TableHeaderCell>Skill</TableHeaderCell>
            <TableHeaderCell>Mastery</TableHeaderCell>
            <TableHeaderCell>Priority</TableHeaderCell>
            <TableHeaderCell>Actions</TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {skillsData.map((skill, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell>
                <StyledTextField
                  value={skill.skillName}
                  onChange={(event) => handleSkillChange(index, 'skillName', event.target.value)}
                />
              </StyledTableCell>

              <StyledTableCell>
                <StyledTextField
                  type="number"
                  value={skill.onetSkillMastery}
                  onChange={(event) => {
                    const newValue = event.target.value;
                    if (newValue >= 1 && newValue <= 10) {
                      handleSkillChange(index, 'onetSkillMastery', newValue);
                    }
                  }}
                  inputProps={{ min: "1", max: "10", step: "0.5" }}
                />
              </StyledTableCell>

              <StyledTableCell>
                <FormControl>
                  <StyledSelect
                    value={skill.criticality}
                    onChange={(event) => handleSkillChange(index, 'criticality', event.target.value)}
                  >
                    <MenuItem value="Low">Low</MenuItem>
                    <MenuItem value="Medium">Medium</MenuItem>
                    <MenuItem value="High">High</MenuItem>
                  </StyledSelect>
                </FormControl>
              </StyledTableCell>

              <StyledTableCell>
                <IconButton onClick={() => handleDeleteSkill(index)} aria-label="delete">
                  <DeleteIcon />
                </IconButton>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </StyledTable>
      {/* <div>
        <IconButton onClick={addSkill} aria-label="Addskill">
          <AddIcon />
        </IconButton>
        Add skill
      </div> */}
    </TableContainer>
  );
};

export default SkillsTableComponent;
