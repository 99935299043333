import React, { useState, useRef, useEffect } from 'react';
import './DropdownWithCheckboxes.css'; // Import CSS file for styling
import { FaTrash } from 'react-icons/fa'; // Import trash icon from react-icons




function DropdownWithCheckboxes({options, setOptions, propogateOptionChanges, fieldName}) {
  // const initialOptions = [
  //   { label: "Option 1", value: "Option 1", selected: false },
  //   { label: "Option 2", value: "Option 2", selected: false }
  // ]
  // const [options, setOptions] = useState(initialOptions);
  
  const [newOption, setNewOption] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [updatedFlag, setUpdatedFlag] = useState(false);
  const [displayValue, setDisplayValue] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  console.log("Set the updated flag to ", updatedFlag)
  const dropdownRef = useRef(null);

  const toggleOption = (index) => {
    // const updatedOptions = [...options];
    const updatedOptions = JSON.parse(JSON.stringify(options));
    updatedOptions[index].selected = !updatedOptions[index].selected;
    setOptions(updatedOptions);
    setUpdatedFlag(true);
    console.log("Set the updated flag to ", updatedFlag)
  };

  const toggleSelectAll = () => {
    // const updatedOptions = [...options];
    let updatedOptions = JSON.parse(JSON.stringify(options));
    updatedOptions = updatedOptions.map(option => ({
      ...option,
      selected: !selectAll
    }));
    setOptions(updatedOptions);
    setUpdatedFlag(true);
    console.log("Set the updated flag to ", updatedFlag)
  };

  const deleteOption = (index) => {
    const updatedOptions = JSON.parse(JSON.stringify(options));
    updatedOptions.splice(index, 1);
    setOptions(updatedOptions);
    setUpdatedFlag(true);
    console.log("Set the updated flag to ", updatedFlag)
  };

  const updateDisplayValue = () => {
    const selectedOptions = options ? options.filter(option => option.selected) : null;
    setSelectAll(selectedOptions ? selectedOptions.length === options.length : false);

    const current_displayValue = () => {
       if (!selectedOptions || selectedOptions.length === 0) {
         console.log("No selected option");
         return "Add " + fieldName;
       } else if (selectedOptions.length >= 2 && selectAll) {
         console.log("All options have been selected");
         return "ALL";
       } else {
         const firstSelected = selectedOptions[0].label;
        //  console.log("firstSelected option is", firstSelected);
         const otherSelectedCount = selectedOptions.length - 1;
        //  console.log("Other Selected count is", otherSelectedCount);
         const selectedCountString = otherSelectedCount > 0 ? ` +${otherSelectedCount}` : '';
         const maxDisplayLength = 10 - selectedCountString.length;
         return firstSelected.length > maxDisplayLength
           ? firstSelected.substring(0, maxDisplayLength - 1) + '...' + selectedCountString
           : firstSelected + selectedCountString;
       };
      }
      setDisplayValue(current_displayValue());
  };

  useEffect ( () => { 
    updateDisplayValue();
  }, [options]);

  const handleAddOption = () => {
    if (newOption.trim() !== '') {
      const updatedOptions = [...options, { label: newOption, value: newOption, selected: true }];
      setOptions(updatedOptions);
      setNewOption('');
      setUpdatedFlag(true);
      // console.log("Set the updated flag to ", updatedFlag)
    }
  };

  const handleClickOutside = (event) => {
//    console.log("Click detected")
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
//      console.log("Click is outside the dropdown")
      if (updatedFlag) {
        setUpdatedFlag(false);
//        console.log("Set the updated flag to ", updatedFlag)
        // console.log("Propogating changes")
        // propogateOptionChanges();
      }
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDropdownToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div style = {{ marginTop: "18px" }} className="dropdown" ref={dropdownRef}>
      <div className="dropdown-button" onClick={handleDropdownToggle}>
        {displayValue}
        <span className={isOpen ? "arrow-up" : "arrow-down"}></span>
      </div>
      {isOpen && (
        <div className="dropdown-content">
          <div>
            <input
              type="checkbox"
              checked={selectAll}
              onChange={toggleSelectAll}
            />
            <label>Select All</label>
          </div>
          {options && options.map((option, index) => (
            <div key={index} className="option-row">
              <input
                type="checkbox"
                checked={option.selected || false}
                onChange={() => toggleOption(index)}
                style = {{ align: "left" }}
              />
              <label style = {{ minWidth: "260px",  textAlign: "left" }} htmlFor={option.value}>{option.label}</label>
              <span style = {{ align: "right" }} className="delete-icon" onClick={() => deleteOption(index)}>
                <FaTrash />
              </span>
            </div>
          ))}
          <div>
            <input
              type="text"
              value={newOption}
              onChange={(e) => setNewOption(e.target.value)}
            />
            <button onClick={handleAddOption}>Save</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default DropdownWithCheckboxes;
