import { createSlice } from "@reduxjs/toolkit";
import { userThunk, inviteThunk,deleteThunk,updateThunk } from "../services/userserviceThunk"; // Import your user thunk

const initialState = {
  users: [],
  status: 'idle',
  error: null,
};

export const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    // You can add reducers for other non-async actions here
  },
  extraReducers: builder => {
    builder
      .addCase(userThunk.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(userThunk.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.users = action.payload;
      })
      .addCase(userThunk.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(inviteThunk.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(inviteThunk.fulfilled, (state, action) => {
        state.status = 'succeeded';
        
      })
      .addCase(inviteThunk.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(deleteThunk.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteThunk.fulfilled, (state, action) => {
        state.status = 'succeeded';
        
      })
      .addCase(deleteThunk.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      
      .addCase(updateThunk.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateThunk.fulfilled, (state, action) => {
        state.status = 'succeeded';
        
      })
      .addCase(updateThunk.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

export default userSlice.reducer;
