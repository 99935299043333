import React, { useEffect, useState, useRef,useMemo} from 'react';
import {
  Box,
  IconButton,
  TextField,
  MenuItems
} from "@mui/material";
import { NumericColDef } from '@mui/x-data-grid';
import { Slider } from '@mui/material/Slider';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Grid, Paper, Button,Switch, Typography } from '@mui/material';
import { DataGrid, gridClasses  } from '@mui/x-data-grid';
//import DescriptionIcon from '@mui/icons-material/Description';
import DocIcon from '../../assets/icons/Doc Icon.png';
import { getResumesThunk, getCandidatesThunk } from '../../services/resume_thunk';
import { updatejobrange } from '../../services/createjobservice.js';
import pdfIcon from '../../assets/icons/PDF Icon.png';
//import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ControlledTextInput from '../../components/ControlledTextInput.js';
import SliderWithLabel from '../../components/sliderWithALabel.js';
import RangeSliderWithLabel from '../../components/rangeSliderWithLabel.js';
import DropdownWithCheckboxes from '../../components/DropdownWithCheckboxes.js';
import RangeSlider from '../../charts/AreaWithSlider/rangeSlider';
import CandidateView from '../../candidate Details/CandidateView';
import UploadResumes from '../../uploadresume/uploadResumes.js'
import RecordDecision from '../../recordDecision/recordDecision.js';
import { alpha, styled } from '@mui/material/styles';
import selected from "../../assets/images/selected.svg"
import considered from "../../assets/images/considered.svg";
import rejected from "../../assets/images/rejected.svg";
import greenhouse_icon from "../../assets/images/GREENHOUSE_ICON_GREEN.svg";
import { current } from '@reduxjs/toolkit';
import { updaterangeThunk } from '../../services/jobthunk.js';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { isNumeric } from 'jquery';
import axios from "axios";
import { useNavigate, useParams, useLocation } from "react-router-dom";


const ODD_OPACITY = 0.2;
const svgStyle = {
  verticalAlign: 'middle', // Align the SVG vertically with the text
  width: '1em', // Set width equal to the font size
  height: '1em', // Set height equal to the font size
  fill: 'currentColor', // Use the current text color for SVG fill
};

const API_BASE = process.env.REACT_APP_API;


function Resumes({ jobId,reqId,candidate, range, jobTitle, resetCandidate, importantTitles, saveImportantTitles, jobDetails, trans, handleskillsvireChange, importantIndustries, yearsOfIndustryExperience, saveImportantIndustries, yearsOfTotalExperience, saveTotalExperience, rangeOfTotalExperience, saveRangeExperience }) {
//  console.log("candidate was set as: ", candidate)
  const gridApi = useRef(null);  
//  console.log("ranger",range);
  // const [importantTitles, setImportantTitles] = useState([{ label: jobTitle.trim(), value: jobTitle.trim(), selected: true}]);
  const [lookupTable, setLookupTable] = useState([]);
  const prevLookupTable = useRef(lookupTable);
  const [missingDateSwitch, setMissingDateSwitch] = useState(false);
  console.log("importantTitles are:", importantTitles)
  const [specialDisplayDict, setSpecialDisplayDict] = useState({});
  const [open, setOpen] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [anchorElForDialog, setAnchorElForDialog] = useState(false);
  const [decisionDialogOpen, setDecisionDialogOpen] = useState(false);
  const [ranges, setRanges] = useState(range || [0, 100]);
  const [numBulkDeclined,setNumBulkDeclined] = useState(0);
  const sliderMax = 40;
//  const [trans, settrans] = useState(true);
  const [status, setStatus] = useState({
    advanced: candidate? true : false,
    declined: candidate? true : false,
    notReviewed: true,
    prospect: candidate? true : false,
  });
  const prevStatus = useRef(status);
  const [prevIndustry, setPrevIndustry] = useState("");

  const resumesPageRef = useRef(null);
  const [savedSortModel, setSavedSortModel] = useState([]);
  const [jobTitles, setJobTitles] = useState([]);
  const [selectedJobTitle, setSelectedJobTitle] = useState('ALL');
  const API = `${API_BASE}/main/get-titles`;
  const navigate = useNavigate();
  const location = useLocation();
  


  // Preprocessing function to create a lookup table
  const createLookupTable = titlesArray => {
    const lookupTable = [];
    if (titlesArray && titlesArray.length > 0 ) { 
      for (const item of titlesArray) {
        if (item.selected) {
          const lowerTitle  = item.value.toLowerCase(); // get lowercase of the title
          if (lookupTable.indexOf(lowerTitle) === -1) {
              lookupTable.push(lowerTitle);
          }
        }
      }
    }
    return lookupTable;
  };





useEffect ( () => { 
  if ( !importantTitles ) { 
    saveImportantTitles([{ label: jobTitle.trim(), value: jobTitle.trim(), selected: true}])
  }
}, [jobTitle]);

// When important titles change for the job trigger special title filter display processing (to only display resumes that have that title)
useEffect( () => {
  triggerSpecialDisplay();
}, [importantTitles]);

const triggerSpecialDisplay = () => {
  setLookupTable(createLookupTable(importantTitles));
  // if (lookupTable.length > 0) {
  //   setSortedFilteredplaceholder
  // }
};


const isAnyImportantTitlePresentFast = (title, selectedImportantTitles) => {
  if (! title ) {
    return false;
  }
  return selectedImportantTitles.some(value => title.includes(value));
};


// Function to add specialDisplay property to resArray based on title presence
const addSpecialDisplay = (resArray, lookupTable) => {
  const specialDisplayDict_this = {};

  resArray.forEach((item, index) => {
    specialDisplayDict_this[item._id] = isAnyImportantTitlePresentFast(item.lowerDisplayTitle, lookupTable);
  });
  return specialDisplayDict_this;
};



const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
 

    [`& .${gridClasses.row}`]: {
      backgroundColor: "#ffffff", // ({ row }) => importantTitles.includes(row.displayTitle) ? "#DCE2E8" : "#ffffff", // Conditionally set background color based on whether the title is in importantTitles
      //     backgroundColor: "#DCE2E8",
      borderRadius: '10px',
      border: 'none', //'0px solid #F5F7F9',
      marginBottom: '10px',
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
      '&.css-1724yxx-MuiDataGrid-root, &.MuiDataGrid-withBorderColor' :{
        border: 'none'
      },
      '&.Mui-selected': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity,
        ),
        '&:hover, &.Mui-hovered': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity,
          ),
          '@media (hover: none)': {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
          },
        },
      },
    },
    [`& .super-app-theme--normal`]: {
      backgroundColor: "#ffffff", 
      borderRadius: '10px',
      border: 'none', //'0px solid #F5F7F9',
      marginBottom: '10px',
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
      '&.css-1724yxx-MuiDataGrid-root, &.MuiDataGrid-withBorderColor' :{
        border: 'none'
      },
      '&.Mui-selected': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity,
        ),
        '&:hover, &.Mui-hovered': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity,
          ),
          '@media (hover: none)': {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
          },
        },
      },
    },

    [`& .super-app-theme--highlight`]: {
      backgroundColor: "#DCE2E8",
      borderRadius: '10px',
      border: 'none', //'0px solid #F5F7F9',
      marginBottom: '10px',
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
      '&.css-1724yxx-MuiDataGrid-root, &.MuiDataGrid-withBorderColor' :{
        border: 'none'
      },
      '&.Mui-selected': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity,
        ),
        '&:hover, &.Mui-hovered': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity,
          ),
          '@media (hover: none)': {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
          },
        },
      },
    },
    [`& .${gridClasses.row}:after`]: {
      content: '""',
      display: 'none',
      height: '0.5%',
      width: '100%',
      
    },
  }));
  
  const [savedReason, setSavedReason] = useState(localStorage.getItem('savedReason'));
  const [savedAdvancedTemplate, setSavedAdvancedTemplate] = useState(localStorage.getItem('savedAdvancedTemplate'));
  const [savedDeclinedTemplate, setSavedDeclinedTemplate] = useState(localStorage.getItem('savedDeclinedTemplate'));
  const [savedAdvancedSchedule, setSavedAdvancedSchedule] = useState(localStorage.getItem('savedAdvancedSchedule'));
  const [savedDeclinedSchedule, setSavedDeclinedSchedule] = useState(localStorage.getItem('savedDeclinedSchedule'));
  const myStorage = localStorage;

  const handleChange = (event) => {
    setStatus({ ...status, [event.target.name]: event.target.checked });
  };

  const dispatch = useDispatch();




  useEffect( () => {
    localStorage.removeItem('savedSortModel');
    const savedModel =  localStorage.getItem('savedSortModel');
    if (savedModel) {
      setSavedSortModel(JSON.parse(savedModel));
    }
    else {
      setSavedSortModel([{field: "total", sort: "desc"}]);
    }
    setSortedFilteredplaceholder(gridApi.current.getSortedRows());
   }, []);

  useEffect( () => {
    // const modelToSave = JSON.stringify(savedSortModel);
    // localStorage.setItem('savedSortModel', modelToSave);
    setSortedFilteredplaceholder(gridApi.current.getSortedRows());
  }, [savedSortModel]);


  useEffect(() => {
    const fetchJobTitles = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(API, {
          headers: {
            Authorization: token,
          },
        });
        setJobTitles(response.data.data);
      } catch (error) {
        console.error("Error fetching job titles:", error);
      }
    };
  
    fetchJobTitles();
  }, []);

  const handleJobTitleChange = (event) => {
    setSelectedJobTitle(event.target.value);
  };
  


  function updateRanges(values) {
    setRanges(values);
    const response= dispatch(updaterangeThunk({ job_id: jobId , action: {range: values}}))
//    console.log(response)
    
  };

  const handleMissingDateSwitch = () =>  { 
    setMissingDateSwitch(!missingDateSwitch);
  };

  const handleOpenDecisionDialog = (event) => {
    const anchorElement = resumesPageRef.current;
    if (anchorElement) {
      setAnchorElForDialog(anchorElement);
    } 
    setDecisionDialogOpen(true);
  };
   
  const handleCloseDecisionDialog = () => {
    setDecisionDialogOpen(false);
  };
   


  const handleOpenUploadDialog = () => {
    setUploadDialogOpen(true);
  };
  

  const handleOpenDialog = (data) => {
//    console.log("dialogdata",data);
    setSelectedCandidate(data);
    const index = sortedFilteredResumes.indexOf(data);
    
    if (index !== -1) {
      
      setSelectedCandidateIndex(index);
      
    //console.log("index after",selectedCandidateIndex)

    }
    setOpen(true);
  };

  
  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleClick = (event, blob_path) =>{
    event.preventDefault();
    dispatch(getCandidatesThunk(blob_path));
  };

  const actions = (
    <>
      <Button onClick={handleCloseDialog}>
        Close
      </Button>
    </>
  )
// Fetch resumes when the component mounts
  useEffect(() => {
          dispatch(getResumesThunk(jobId));
    }, [dispatch, jobId]);



  // Select resumes from Redux store
  const resumes = useSelector((state) => state.resume.resumes);
  
  // // Parse the resumes and convert strings to JSON
  // Changed in the server side , Sending JSON directly
  const parsedResumes = useMemo(() => {
    // return resumes.map(resume => JSON.parse(resume));
    return resumes
  }, [resumes]);
  const prevParsedResumes = useRef(parsedResumes);
  
  const [displayResumes, setDisplayResumes] = useState([]);
  const [counts, setCounts] = useState({
    advancedCount: 0,
    declinedCount: 0,
    prospectCount: 0,
    notReviewedCount: 0,
  });
  
  useEffect(() => {
    let advancedCount = 0;
    let declinedCount = 0;
    let notReviewedCount = 0;
    let prospectCount = 0;
    let whoaretheseCount = 0;
    let numLookupTitles = lookupTable.length;

    let updatedSpecialDisplayDict = {};
    if (prevParsedResumes.current !== parsedResumes || prevLookupTable.current !== lookupTable) {
      if (parsedResumes.length > 0 && lookupTable.length > 0) { 
        updatedSpecialDisplayDict = addSpecialDisplay(parsedResumes, lookupTable);
      }
      setSpecialDisplayDict(updatedSpecialDisplayDict);
    }
    else {
      updatedSpecialDisplayDict = specialDisplayDict;
    }      
    const lowercaseImportantIndustries = (typeof importantIndustries === 'string') ? importantIndustries.toLowerCase() : "";
    const filtered = parsedResumes.filter(resume => {
      if (  (!missingDateSwitch || resume.numberOfJobsMissingStartDate > 0) &&
            (!lookupTable.length || updatedSpecialDisplayDict[resume._id ]))
          {
          if( 
              ( !lowercaseImportantIndustries || 
                 lowercaseImportantIndustries === "" ||  
                (Object.keys(resume.industryExperienceHash).some(key => key.includes(lowercaseImportantIndustries) 
                                                                   && (
                                                                    !yearsOfIndustryExperience || 
                                                                    yearsOfIndustryExperience === "" ||
                                                                    resume.industryExperienceHash[key] >= yearsOfIndustryExperience
                                                                   )
                                                                )
                )
              )  && 
              (
                !yearsOfTotalExperience || 
                yearsOfTotalExperience === "" ||
                ! resume.career || 
                resume.career >= yearsOfTotalExperience
              )
              && 
              (
                !rangeOfTotalExperience || 
                rangeOfTotalExperience === "" || 
                (
                  ( (rangeOfTotalExperience[0] === 0) ||
                     resume.career >= rangeOfTotalExperience[0] 
                  ) &&
                  ( (rangeOfTotalExperience[1] === sliderMax) ||
                    (resume.career <= rangeOfTotalExperience[1] )
                  )
                )
              )
            )
            {
              const decisionExists = resume?.resumeData?.decision?.decision;
              const decision = decisionExists ? resume.resumeData.decision.decision : null;

              if (decision === 'Advanced') { 
                advancedCount++;
                if (status.advanced) {
                  return 1;
                }
              } 
              else if (decision === 'Declined') { 
                declinedCount++;
                if (status.declined) {
                  return 1;
                }
              }
              else if (resume?.resumeData?.components?.atsApplicantInfo?.prospect && resume?.resumeData?.components?.atsApplicantInfo?.prospect === true) {
                prospectCount++;
                if (status.prospect) {
                  return 1;
                }
              }
              else if ( ! (resume?.resumeData?.components?.atsApplicantInfo?.prospect && resume?.resumeData?.components?.atsApplicantInfo?.prospect === true)) {
                notReviewedCount++;
                if (status.notReviewed) {
                  return 1;
                }
              }
              else {
                whoaretheseCount++;
                return 0;
              }
            }
            else {
              console.log("This resume was not counted: ", resume);
              console.log("lowercaseImportIndustries: ", lowercaseImportantIndustries);
              console.log("Years of experience Slider: ", yearsOfTotalExperience);
              console.log("Range oF Total Experience Slider: ", rangeOfTotalExperience);
            }
          return 0;
        }
    });
  
      setDisplayResumes(filtered);
    //  console.log("we got advanced", advancedCount, " declined", declinedCount, " prospect", prospectCount, " not reviewed", notReviewedCount, " whoarethese", whoaretheseCount);
      setCounts({
        advancedCount,
        declinedCount,
        prospectCount,
        notReviewedCount,
      });
  
  prevLookupTable.current = lookupTable;
  prevParsedResumes.current = parsedResumes;
  prevStatus.current = status;
  }, [status, parsedResumes, lookupTable, importantIndustries, yearsOfIndustryExperience, yearsOfTotalExperience, rangeOfTotalExperience, missingDateSwitch]);

useEffect( () => {
  let countDeclined = 0;
  displayResumes.forEach(item => {
    const score = trans? parseFloat(item.resumeData?.totalTransferable) : parseFloat(item.resumeData?.totalDirect);
    if (score <= ranges[0]) {
      countDeclined++;
    }
  });
  setNumBulkDeclined(countDeclined); 
  // if (gridApi.current) {
  //   const sortColumn = 'total'; // Specify the column to sort by
  //   const sortDirection = 'ASC'; // Specify the sort direction ('ASC' or 'DESC')

  //   gridApi.current.onSort({
  //     column: columns.find(col => col.key === sortColumn),
  //     direction: sortDirection
  //   });
  // }
  }, [ranges,displayResumes, trans]);


  
  
  const data = displayResumes.map((parsedResume, index) => {
    const score = trans ? parsedResume.resumeData.totalTransferable
     : parsedResume.resumeData.totalDirect;

    return {
      name: parsedResume?.resumeData?.extracted?.personalDetails?.name || (parsedResume?.resumeData?.components?.atsCandidateInfo?.first_name +" "+parsedResume?.resumeData?.components?.atsCandidateInfo?.last_name + " @"), // or you can get the name from some other field
      score: score
    };
  });


  const maxScores = Math.max(...displayResumes.map(resume => resume.resumeData.scores.length), 0);
  
// Find the resume with the maximum number of scores
const resumeWithMaxScores = displayResumes.find(resume => resume.resumeData.scores.length === maxScores) || {};
//console.log("test",resumeWithMaxScores)
// Extract the profile type headers
const profileTypeHeaders = resumeWithMaxScores.resumeData?.scores || {};

const mySortFunction = (v1, v2) => {
  const sortDir = savedSortModel[0].sort === 'asc' ? 1 : -1;
  const myv1 = parseFloat(v1);
  const myv2 = parseFloat(v2);
  if (isNaN(myv1) && isNaN(myv2)) return 0;
  if (isNaN(myv1)) return 1 * sortDir; 
  if (isNaN(myv2)) return -1 * sortDir;
  return (myv1 - myv2) ;
}
// Create columns for each score
const scoreColumns = Array.from({ length: maxScores }, (_, i) => ({
  field: `score${i + 1}`,
  headerName: profileTypeHeaders[i]?.result[0]?.profileName || `Score ${i + 1}`, // Use the profile type as the header, or fall back to "Score i"
  width: 150,
  type: 'number',
  align: 'center',
  headerAlign: 'center',    
  renderCell: (params) => <div style={{ textAlign: 'center' }}>{params.value}</div>,
  filterable: true,
  sortComparator: (v1, v2, cellParams1, cellParams2) => mySortFunction(v1,v2), 
  valueGetter: (params) => {
    // Check if the score is defined, otherwise return 'NA'
    const score = trans? params.row?.resumeData?.scores[i]?.score?.Combined:params.row?.resumeData?.scores[i]?.score?.Direct;
    return score ? score : 'NA';
  },
}));


function createScoreValues({ data, scoreField }) {
  // console.log("data is ",data);
  // console.log("Score Field is",scoreField);
  // console.log(data
    // .map(candidate => candidate[scoreField])
    // .filter((scoreVal, index, array) => array.indexOf(scoreVal) === index).sort((a,b) => a-b));
  return data
  .map(candidate => candidate[scoreField]) // get each candidates score in to a list
  .filter((scoreVal, index, array) => array.indexOf(scoreVal) === index)
  .sort((a,b) => a-b)
  ;// ensure we have unique list of scoreValues
};

function createScoreDistribution ({ data, scoreValues, scoreField }) {
//  console.log("scoreValues is",scoreValues);
  return scoreValues
      .map(scoreVal => ({
          x: scoreVal,
          y: data.filter(candidate => candidate[scoreField] === scoreVal).length
      }));
};

function value_counts ({data, columnName}) {
  const distribution = {};
  data.forEach( row => {
    const value = row[columnName];
    distribution[value] = (distribution[value] || 0) + 1;
  });
  return distribution;
};
  
const scoreValues=createScoreValues({data:data,scoreField:"score"});
const [selectedCandidateIndex, setSelectedCandidateIndex] = useState(null);
const [sortedFilteredResumes, setSortedFilteredResumes] = useState([]);
const [sortedFilteredplaceholder, setSortedFilteredplaceholder] = useState([]);
// const [sortModel, setSortModel] = React.useState([
//   {
//     field: "total",
//     sort: "desc"
//   }
// ]);
const scoreDistrubution=createScoreDistribution({data:data, scoreValues:scoreValues,scoreField: "score"});

  // Columns for the DataGrid
  const columns = [
    
    {
      field: 'status',
      headerName: '',
      width: 50,
      renderCell: (params) => {
        const score = trans? parseFloat(params.row.resumeData?.totalTransferable) : parseFloat(params.row.resumeData?.totalDirect);
        if (score > ranges[1]) {
          return <> <img src={selected} alt="selected" /> 
          {/* { params.row.resumeData?.components?.atsDeepLink  && <a target="greenhouse_window" href={params.row.resumeData.components.atsDeepLink}><img style={svgStyle} src={greenhouse_icon} alt="" /></a> } */}
          </>
          ; // Selected
        } else if (score >= ranges[0] && score <= ranges[1]) {
          return <>  <img src={considered} alt="considered" />
          {/* { params.row.resumeData?.components?.atsDeepLink  && <a target="greenhouse_window" href={params.row.resumeData.components.atsDeepLink}><img style={svgStyle} src={greenhouse_icon} alt="" /></a> } */}
          </>
          ; // Considered
        } else {
          return  <> <img src={rejected} alt="rejected" />
          {/* { params.row.resumeData?.components?.atsDeepLink  && <a target="greenhouse_window" href={params.row.resumeData.components.atsDeepLink}><img style={svgStyle} src={greenhouse_icon} alt="" /></a> } */}
          </>
          ; // Rejected
        }
      },
    },
    {
      field: 'Candidate Name',
      headerName: 'Candidate Name',
      width: 180,
      valueGetter: (params) => params.row.resumeData?.extracted?.personalDetails?.name || params.row.resumeData?.components?.atsCandidateInfo?.first_name +" "+params.row.resumeData?.components?.atsCandidateInfo?.last_name + " @",
      renderCell: (params) => (
          <div> <span  style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }} onClick={() => handleOpenDialog(params.row)}>
            {params.row.resumeData?.extracted?.personalDetails?.name || params.row.resumeData?.components?.atsCandidateInfo?.first_name +" "+params.row.resumeData?.components?.atsCandidateInfo?.last_name + " @" }
          </span> <br />
          <span style={{ color: 'black'}}> {params.row.displayTitle}
          </span>
        </div>
      ),
    },
    ...scoreColumns,
    {field: 'total',
    headerName: <div style={{textAlign: 'center', lineHeight:'1.2'}}>Overall <br />Score</div>,
    width: 150,
    type: 'number',
    align: 'center',
    headerAlign: 'center',    
    renderCell: (params) => <div style={{ textAlign: 'center' }}>{params.value}</div>,
    filterable: true,
    sortComparator: (v1, v2, cellParams1, cellParams2) => mySortFunction(v1,v2),
    valueGetter: (params) => trans ? Math.round(params.row.resumeData.totalTransferable,0) : Math.round(params.row.resumeData.totalDirect,0),
    },
  

    {
      field: 'File Name',
      headerName: 'File Name',
      width: 150,
      valueGetter: (params) => params.row.resumeData.components.uploadedFile,
    },
    
    {field: 'Distance',
      headerName: ( <div style={{textAlign: 'center', lineHeight:'1.2'}}>Distance <br />(in miles) </div> ),
      width: 150,
      valueGetter: (params) => { 
        const distance = params.row.resumeData?.extracted?.job_driving_distance;
        return isNumeric(distance)? distance : 99999; 
      },
      type: 'number',
      align: 'center',
      headerAlign: 'center',    
      renderCell: (params) => {
        const value = params.value;
        if (value === null || value === 99999) {
          return 'No Address';
        } else {
          return <div style={{ textAlign: 'center' }}>{value}</div>;
        }
      },
      sortComparator: (v1, v2, cellParams1, cellParams2) => {
        if (v1 === null || v1 === 99999) {
          return 1; // Treat "Not Applicable" as lower than any numeric value
        }
        if (v2 === null || v2 === 99999) {
          return -1; // Treat "Not Applicable" as lower than any numeric value
        }
        return  parseFloat(v1) - parseFloat(v2); // Default numeric sorting
      },
      filterable: true,
    },
    
    
    {field: 'Decision',
      headerName: 'Decision',
      width: 150,
      valueGetter: (params) => { const decision = params.row.resumeData?.decision?.decision;
        if (decision === "Declined") {
          return "Rejected";
        }
        else if (decision === "Undeclined") { 
          return "Unrejected";
        } 
        else {
          return decision || "Not Reviewed";
        }
    }},


    { 
      field: 'blob_path', 
      headerName: 'File', 
      width: 100, 
      renderCell: (params) => (
        <a href={params.row.fileLink} target="_blank" rel="noopener noreferrer" onClick={(event) => handleClick(event, params.row.resumeData.components.blobPath)}>
          {params.row.resumeData.components.blobPath.endsWith('.pdf') ? (
            <img src={pdfIcon} alt="PDF" />
          ) : (
            <img src={DocIcon} alt="PDF" />
          )}
        </a>
      ),
      
    },


  ];

  useEffect(() => {
//    setSortedFilteredResumes(displayResumes);
    setSortedFilteredplaceholder(gridApi.current.getSortedRows());
//    console.log("change");
  }, [displayResumes]);

  useEffect(() => {
  //  const query = new URLSearchParams(location.search);
  //   if (!candidate) {
  //    candidate = query.get("candidate") || null;
  //   }
    if (candidate) {
//        console.log(candidate, "ID");
        const candidateFound = sortedFilteredResumes.find(resume => 
            resume?._id === candidate.toString()
        );
        if(candidateFound){
          handleOpenDialog(candidateFound)

        }
//        console.log("candidatefound", candidateFound);
    }
    else {
      console.log("no candidate has been specified");
    }
}, [sortedFilteredResumes,jobId, candidate, handleOpenDialog]);
  

  const [alertVisible, setAlertVisible] = useState(false);
  const [alertBegVisible, setAlertBegVisible] = useState(false);

  const setSelectedCandidateToNext = () => {

    const url = new URL(window.location.href);
    if (url.search) {
      url.search = ''; // Remove all search parameters
      window.history.replaceState({}, '', url);
      resetCandidate();
    }
    if (selectedCandidateIndex !== null && sortedFilteredResumes) {
      setSelectedCandidateIndex((prevIndex) => {
        if (prevIndex < sortedFilteredResumes.length - 1) {
          setSelectedCandidate(sortedFilteredResumes[prevIndex + 1]);
          return prevIndex + 1;
        } else {
          setAlertVisible(true);
          setTimeout(() => {
            setAlertVisible(false);
            handleCloseDialog();
          }, 2000); // delay of 1000 milliseconds
          return prevIndex;
        }
      });
    } else {
      console.log("Error");
    }
  };

  const setSelectedCandidateToPrev = () => {
    const url = new URL(window.location.href);
    if (url.search) {
      url.search = ''; // Remove all search parameters
      window.history.replaceState({}, '', url);
      resetCandidate();
    }
    if (selectedCandidateIndex !== null && sortedFilteredResumes) {
      setSelectedCandidateIndex((prevIndex) => {
        if (prevIndex > 0) {
          setSelectedCandidate(sortedFilteredResumes[prevIndex -1]);
          return prevIndex -1;
        } else {
          setAlertBegVisible(true);
          setTimeout(() => {
            setAlertBegVisible(false);
          }, 2000); // delay of 1000 milliseconds
          return prevIndex;
        }
      });
    } else {
      console.log("Error");
    }
  };

  const decisionchange = () => {
    dispatch(getResumesThunk(jobId));
  };
  
  useEffect(() => {
    setSortedFilteredResumes(sortedFilteredplaceholder);
  }, [sortedFilteredplaceholder]);
  
  useEffect(() => {
    console.log('Component mounted and ready for interaction');
  }, []);

  const handleSortModelChange = (model) => {
    console.log('Sort model changed:', model);
    setSavedSortModel(model);
  }; 

  const getClassNameFromSpecialDict = (id) => { 
//    console.log("trying specialDisplayDict for ", id, "which has value", specialDisplayDict[id]); 

    if (specialDisplayDict[id]) {
//      console.log('returning the value: ', "super-app-theme--highlight");
      return "super-app-theme--highlight";
    }
    console.log('returning the value: ', "super-app-theme--normal");
//    return "super-app-theme--normal";
  };

  return (
    <div ref={resumesPageRef} style={{ maxHeight: '100%', width: '100%', display: 'flex', flexDirection: 'column', position: 'relative', backgroundColor:"#F5F7F9"}}>


<Grid container spacing={2} alignItems="center">
        {/* <div style={{ display: 'flex', alignItems: 'top' }}>

      &nbsp; &nbsp; */}
      <Grid item xs={2}>
           <RangeSliderWithLabel textLabel="Range of Experience" initialSliderValue={rangeOfTotalExperience} saveSliderValue={saveRangeExperience} sliderMax={sliderMax}/>
      </Grid>
      {/* &nbsp;&nbsp;&nbsp;  */}

      <Grid item xs={2}>
      <ControlledTextInput width="200" initialTextValue={importantIndustries} initialSliderValue={yearsOfIndustryExperience} placeholder="Add Industry" saveTextValue={saveImportantIndustries} saveSliderValue={saveImportantIndustries} sliderMax="20"/>
      </Grid>

      {/* &nbsp;&nbsp;&nbsp;  */}
    
      <Grid item xs={1.5} >
      <DropdownWithCheckboxes options={importantTitles} setOptions={saveImportantTitles} propogateOptionChanges={triggerSpecialDisplay} fieldName="Title"/>
      <br />
      </Grid>

      {/* &nbsp; &nbsp;&nbsp;&nbsp;&nbsp; */}
      <Grid item xs={4.5}>
      <Grid container rowSpacing={0} colSpacing={0}>
      <Grid item xs={6}>
        <FormControlLabel
          control={
            <Checkbox
              checked={status.prospect}
              onChange={handleChange}
              name="prospect"
            />
          }
          label={`Prospect (${counts.prospectCount})`}
        />
      </Grid>
      <Grid item xs={6}>
        <FormControlLabel
          control={
            <Checkbox
              checked={status.advanced}
              onChange={handleChange}
              name="advanced"
            />
          }
          label={`Advanced (${counts.advancedCount})`}
        />
      </Grid>
      <Grid item xs={6}>
        <FormControlLabel
          control={
            <Checkbox
              checked={status.declined}
              onChange={handleChange}
              name="declined"
            />
          }
          label={`Rejected (${counts.declinedCount})`}
        />
      </Grid>
      <Grid item xs={6}>
        <FormControlLabel
          control={
            <Checkbox
              checked={status.notReviewed}
              onChange={handleChange}
              name="notReviewed"
            />
          }
          label={`Not Reviewed (${counts.notReviewedCount})`}
        />
      </Grid>
    </Grid>
    </Grid>
    {/* &nbsp; &nbsp; &nbsp;  */}
    <Grid item xs={2}>
      <Grid container rowSpacing={0} colSpacing={0}>
        <Grid item xs={12}>
          <Box sx={{ display: "flex", alignItems: "center", paddingRight: "20px" }}>
            <Switch
            checked={missingDateSwitch}
            onChange={handleMissingDateSwitch}
             />
            <Typography
              style={{
                marginLeft: 8,
                color: trans ? '#4687CF' : '#000000' // Color changes based on the 'direct' state
              }}
            >
              Partial
            </Typography>          
          </Box>
        </Grid>
        <Grid item xs={12}>
          <span style={{ fontWeight: 'bold', color: 'black', paddingTop: '9px'}}>Total : {parsedResumes.length}</span>
        </Grid>
      </Grid>
    </Grid>
</Grid>

    {/* </div> */}
      <Grid container rowSpacing={1}>
        <Grid item xs={12} paddingBottom='0px' marginBottom='0px' >
          <Paper style={{ textAlign: 'center' }}> 
            <RangeSlider id='range-slider' data={data}  scoreDistribution={scoreDistrubution} scoreValues={scoreValues} scoreField = {"score"} onValueChange={updateRanges} range ={ranges}/>
          </Paper>
        </Grid>
        <Grid item xs={12} marginTop='0px' paddingTop='0px' >

          <Paper style={{backgroundColor:"#F5F7F9", paddingBottom:"0px"}}>
          <Grid container alignItems="center" justifyContent="space-between">
              
                <strong > Resumes</strong>
                <Grid item>
                <Button
                  variant="outlined"
                  onClick={(event) => handleOpenDecisionDialog(event)}
                  style={{
                    borderRadius: '4px',
                    border: '1px solid var(--bluejin-primary-color, #4787D2)',
                    backgroundColor: '#D0E1FA',
                    width: '180px'
                  }}
                  >
                  Reject &lt;=&nbsp;{ranges[0]}&nbsp;({numBulkDeclined})
                </Button>
              </Grid>
              <Grid item>
              </Grid>
              <Grid item>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  onClick={handleOpenUploadDialog}
                  style={{
                    borderRadius: '4px',
                    border: '1px solid var(--bluejin-primary-color, #4787D2)'
                  }}
                >
                  +Candidate
                </Button>
              </Grid>
            
            <div style={{ height: '36vh', border: 'none', overflow: 'auto', width:'100%' }} >            
            <StyledDataGrid apiRef={gridApi} rows={displayResumes} columns={columns}
                            sortModel={savedSortModel}
                            // sortComparator={specialDisplaySortComparator}
                            onSortModelChange={handleSortModelChange}
                            onFilterModelChange={(model) => setSortedFilteredplaceholder(gridApi.current.getSortedRows())} 
                            getRowId={(row) => row._id} pageSize={5} style={{ borderColor: '#F5F7F9' }}
                            getRowClassName={(params) => getClassNameFromSpecialDict(params.id)}
            />
            </div>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <UploadResumes show={uploadDialogOpen} handleClose={() => setUploadDialogOpen(false)} jobId={jobId} reqId={reqId}/>
      
      <CandidateView 
      show={open} 
      trans = {trans}
      handleskillsvireChange={handleskillsvireChange}
      selectedCandidate={selectedCandidate} 
      onClose={handleCloseDialog} 
      onNext={setSelectedCandidateToNext}
      alert = {alertVisible}
      alertBeg = {alertBegVisible}
      decisionchange = {decisionchange}
      onPrev = {setSelectedCandidateToPrev}
      setSelectedCandidate={setSelectedCandidate}
      jobTitle = {jobTitle}
      savedReason={savedReason} savedAdvancedSchedule={savedAdvancedSchedule} savedAdvancedTemplate={savedAdvancedTemplate} savedDeclinedSchedule={savedDeclinedSchedule} savedDeclinedTemplate={savedDeclinedTemplate}
      setSavedReason={setSavedReason} setSavedAdvancedSchedule={setSavedAdvancedSchedule} setSavedAdvancedTemplate={setSavedAdvancedTemplate} setSavedDeclinedSchedule={setSavedDeclinedSchedule} setSavedDeclinedTemplate={setSavedDeclinedTemplate} 
      myStorage={myStorage}
      resetCandidate={resetCandidate}
      jobDetails={jobDetails}
      />
    <RecordDecision show={decisionDialogOpen} trans={trans} handleClose={() => setDecisionDialogOpen(false)} propogateDecisionChange={decisionchange} decisionType="Declined" jobId={jobId} listCriteria={ranges} numBulkCandidates={numBulkDeclined} resumes={displayResumes} 
     savedReason={savedReason} savedAdvancedSchedule={savedAdvancedSchedule} savedAdvancedTemplate={savedAdvancedTemplate} savedDeclinedSchedule={savedDeclinedSchedule} savedDeclinedTemplate={savedDeclinedTemplate}
     setSavedReason={setSavedReason} setSavedAdvancedSchedule={setSavedAdvancedSchedule} setSavedAdvancedTemplate={setSavedAdvancedTemplate} setSavedDeclinedSchedule={setSavedDeclinedSchedule} setSavedDeclinedTemplate={setSavedDeclinedTemplate}
     myStorage={myStorage}
     jobDetails={jobDetails}
    />

  </div>
);

}

export default Resumes;
