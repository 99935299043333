import axios from 'axios';

// Assuming REACT_APP_API is set in your environment
const API_BASE = process.env.REACT_APP_API;

const instance = axios.create({
  baseURL: API_BASE,
  headers: {}
});

instance.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = token;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export const getUsers = async () => {
  try {
    const response = await instance.get('/main/get-users');
    return response.data.data;
  } catch (error) {
    console.error("Error in getUsers:", error);
    throw error;
  }
};

export const inviteuser = async (users) =>{
try{
const response = await instance.post('/main/invite-users',users);
return response
}
catch (error){
  console.error("error in inviting",error);
  throw error
}
}

export const deleteuser = async(userId) =>{
try{
  const Id = {"userId":userId}
  const response = await instance.post('/main/delete-user',Id);
  return response
  }
  catch (error){
    console.error("error in inviting",error);
    throw error
  }
  }

  export const updateuser = async(data) =>{
    try{
      const response = await instance.post('/main/update-user',data);
      return response
      }
      catch (error){
        console.error("error in inviting",error);
        throw error
      }
      }
    