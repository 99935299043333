import React, { useState } from 'react';
import { Box, Tab, Tabs, Typography, Container,Button } from '@mui/material';
import { Grid, TextField, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import DialogComponent from "../components/dialoguebox/dialogue";
import { makeStyles } from '@mui/styles';
import Profileform from './profileform';


const useStyles = makeStyles({
  tabPanelContainer: {
    display: 'flex',
    flexGrow: 1, 
    height: '100%', // Full viewport height
  },
  tabs: {
    '& .MuiTabs-indicator': {
      left: 0,
      width: '4px',
      backgroundColor: '#4787D2', // Active indicator color
    }
  },
  tab: {
    alignItems: 'flex-start',
    position: 'relative', // Required for the pseudo-element positioning
    '&:not(:last-of-type)': {
      marginBottom: '-4px', // Negative margin to connect indicators
    },
    '&:before': { // Pseudo-element for unselected indicator
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      width: '4px',
      backgroundColor: '#F8F8F8', // Grey color for unselected indicator
      zIndex: 0,
    },
    '&.Mui-selected:before': {
      backgroundColor: 'transparent', // Hide pseudo-element for selected tab
    },
    '&&': {
      textTransform: 'none',
    },
  },
  tabContent: {
    flexGrow: 1, // Make the content area take the remaining space
    backgroundColor: '#FBFBFB',
    maxWidth: '100%', // Ensure it doesn't exceed the parent's width
    height: '100%', // Set the height to 100%
    padding: '10px', // Directly define padding
  },
  
});

function TabPanel(props) {
  const { children, value, index, classes, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className={classes.tabContent}
    >
      {value === index && children}
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function CustomTabLabel({ label }) {
  return <span style={{ textTransform: 'none' }}>{label}</span>;
}

function Profile({ show, handleClose, user}) {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  

  const Actions =(
    <>
      <Button onClick={handleClose}>Cancel</Button>
      <Button
        variant="contained"
        style={{ backgroundColor: "#4787D2", color: "#FFF" }}
        onClick={handleClose}
      >
        Save
      </Button>
    </>
);


  return (
<DialogComponent show={show} title="User Settings" actions={Actions} >
      <Box className={classes.tabPanelContainer}>
        <Tabs
          orientation="vertical"
          variant="fullWidth"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          className={classes.tabs}
        >
          <Tab className={classes.tab} label={<CustomTabLabel label="Profile" />} {...a11yProps(0)} />
          <Tab className={classes.tab} label={<CustomTabLabel label="Notifications" />} {...a11yProps(1)} />
          {/* Add additional tabs here */}
        </Tabs>
        <Container >
          <TabPanel value={value} index={0} classes={classes}>
          <Container >
            <Profileform user={user}/>
            </Container>
          </TabPanel>
          <TabPanel value={value} index={1} classes={classes}>
            <Container>
            Notifications
            </Container>
          </TabPanel>
          {/* Additional TabPanels here */}
        </Container>
      </Box>
    </DialogComponent>
  );
}

export default Profile;
