import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import { useParams } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@mui/material/Paper';
import suitcase from "../assets/images/jobs.svg";
import analytics from "../assets/images/Group 812066.svg";
import chat from "../assets/images/chat.svg";
import help from "../assets/images/Headset.png";
import close from "../assets/images/Arrow-left.svg";
import right from "../assets/images/chevron-right.svg";
import blujin from "../assets/images/blujin shirt logo 9.png";
import settings from "../assets/images/Settings.svg";
import { useSelector, useDispatch } from 'react-redux';
import Collapse from '@mui/material/Collapse';

import { styled } from '@mui/system';

const ImageContainer = styled('div')({
  width: '100%',
  paddingTop: '100%',
  position: 'relative',
  backgroundSize: '100%',

});

const Image = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundImage: `url(${blujin})`, // Replace `blujin` with actual image URL or variable
  backgroundRepeat: "no-repeat",
  backgroundSize: 'contain',
  backgroundPosition: "center",
});

const CustomTypography = styled('div')({
  color: '#FFF',
  fontFamily: 'Montserrat',
  fontSize: '100%',
  fontStyle: 'normal',
  fontWeight: 800,
  textAlign: 'center',
  paddingLeft: "0.4%",
});

const Pellets = styled('span')({
  marginLeft: '5%',
});

export default function LeftNavBar({ opener = true }) {
  const [open, setOpen] = useState(opener);
  const [activeTab, setActiveTab] = useState(0);
  const [settingsExpanded, setSettingsExpanded] = useState(false);
  const setting = useSelector((state) => state.settings.basic);
  const [condition,setcondition] = useState(JSON.parse(localStorage.getItem("userDetails"))?.role==="admin" || setting?.inviteUsers === "all_users")
  const [companyset,setcompanyset] = useState(false)
  const [clientName,setclientname] = useState(null)

  const curr  = useParams();

  useEffect(() => {
    if ("*" in curr) {
      setActiveTab(curr['*'])
      console.log(curr)
      setcompanyset(Boolean(getElementIndexByIdsubmenu(activeTab)))
      setclientname(curr['clientName'])
    }
   
    if(JSON.parse(localStorage.getItem("userDetails"))?.role==="admin" || setting?.inviteUsers === "all_users"){
      setcondition(true)
    }
    else{
      console.log(setting)
      setcondition(false)
    }
  }

  
  ,[localStorage.getItem("userDetails"),setting])
  
  const submenu1 = [
    { path: "/"+clientName+"/Basic", tooltip: "Basic", label: "Basic", id: "Basic" },
    { path: "/"+clientName+"/manage-users", tooltip: "Manage Users", label: "Manage Users", id: "manage-users" },
    { path: "/"+clientName+"/departments", tooltip: "Departments", label: "Departments", id:'departments' },
    { path: "/"+clientName+"/hiring-managers", tooltip: "Hiring Managers", label: "Hiring Managers", id:"hiring-managers" },
  ]

  

  const tabs = [
    { path: "/"+clientName+"/"+"home", tooltip: "Home", image: suitcase, label: "Jobs", id:"home"  },
    { path: "/"+clientName+"/"+"health", tooltip: "Analytics", image: analytics, label: "Analytics", id : "health"},
    { path: "/"+clientName+"/"+"help", tooltip: "Help", image: help, label: "Help", id : "help"},
    ...(condition ? [{ path: "settings", tooltip: "Company Settings", image: settings, label: "Company Settings", submenu: submenu1 }] : [])
  ];

  function getElementIndexById(id) {
    return tabs.findIndex(tab => tab.id === id);
  }
  function getElementIndexByIdsubmenu(id) {
    return submenu1.findIndex(submenu1 => submenu1.id === id);
  }

  const handleExpandClick = () => {
    if(!settingsExpanded){
      setOpen(true)
    }
    setSettingsExpanded(!settingsExpanded);
    
  };

  return (
    <Box 
      sx={{ 
        width: open ? 'fit-content' : 'fit-content',
        minWidth: open? "250px": "",
        height: '100vh', 
        background: "linear-gradient(180deg, #418CD1 0%, #9C41D1 100%)", 
        boxShadow: '0px 4px 4px 0px', 
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'space-between' 
      }}
    >
      <List>
        <ListItemIcon  style={{ justify: "center", alignItems: "center", height: "23%", width: "57%", marginTop: '1%',minWidth: open ? "0px":"56px"}}>
          <Grid container spacing={1}>
            <Grid item xs={open ? 7 : 12} container alignItems="center" justifyContent="center">
              <ImageContainer>
                <Image></Image>
              </ImageContainer>
            </Grid>

            {open && <Grid item xs={5} container alignItems="center" justifyContent="center">
              <CustomTypography>blujin</CustomTypography>
            </Grid>}
          </Grid>
        </ListItemIcon>
        
        {tabs.map((tab, index) => {
  if (tab.path === "settings") {
    return (
      <React.Fragment key={tab.path}>
        <Paper
           sx={{ borderRadius: '8px', opacity: '0.6',backgroundColor: 'inherit','marginBottom':"1px" }} elevation={0}
        >
          <Tooltip title={tab.tooltip}>
            <ListItem
              button
              onClick={() => {
                handleExpandClick();
                
              }}
            >
              <ListItemIcon  style={{ minWidth: open ? "57px" : "0px" }}>
              <Pellets>
                <img src={tab.image} alt={tab.label} style={{ transform: settingsExpanded ? 'rotate(90deg)' : 'none' }} />
                </Pellets>
                {open && (<>&nbsp; &nbsp; <ListItemText primary={tab.label} sx={{ color: '#fff' }} /> </>)}
              </ListItemIcon>
            </ListItem>
          </Tooltip>
        </Paper>
        <Collapse in={settingsExpanded} timeout="auto" >
          <List component="div" disablePadding>
            {tab.submenu.map((subItem) => (
               <Link
               key={subItem.path}
               href={subItem.path}
               underline="none"
               color="#FFFFFF"
               // Here we simply link to the submenu item path}
             >
              <Paper
          elevation={index === getElementIndexByIdsubmenu(activeTab) ? 3 : 0} sx={{ borderRadius: '8px', opacity: index === getElementIndexByIdsubmenu(activeTab) ? '1' : '0.6',backgroundColor: index === getElementIndexByIdsubmenu(activeTab) ? 'transparent':'inherit','marginBottom':"1px" }}>
               <Tooltip title={subItem.tooltip}>
                 <ListItem button >
                  {settingsExpanded && (<>&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; <ListItemText primary={subItem.label} sx={{ color: '#fff' }} /> </>)}
                   
                 </ListItem>
               </Tooltip>
               </Paper>
             </Link>
            ))}
          </List>
        </Collapse>
      </React.Fragment>
    );
  } else {
    return (
      <Link
      key={tab.path}
      href={tab.path}
      underline="none"
      color="#FFFFFF"
    >
      <Paper  elevation={index === getElementIndexById(activeTab) ? 3 : 0} sx={{ borderRadius: '8px', opacity: index === getElementIndexById(activeTab) ? '1' : '0.6',backgroundColor: index === getElementIndexById(activeTab) ? 'transparent':'inherit','marginBottom':"1px" }}>
        <Tooltip title={tab.tooltip}>
          <ListItem button>
          <ListItemIcon  style={{ minWidth: open ? "57px" : "0px" }}>
              <Pellets>
                <img src={tab.image} alt={tab.label} />
                </Pellets>
                {open && (<>&nbsp; &nbsp; <ListItemText primary={tab.label} sx={{ color: '#fff' }} /> </>)}
              </ListItemIcon>

          </ListItem>
        </Tooltip>
      </Paper>
    </Link>
    );
  }
})}
      </List>

      <Link underline="none" color="#FFFFFF" onClick={() => {if(open){setSettingsExpanded(false);}setOpen(!open)}}>
        {open 
          ? ( 
            <Tooltip title="Close">
              <ListItem button>
                <ListItemIcon  style={{ minWidth: open ? "24px" : "0px" }}>
                <Pellets>
                  <img src={close} alt="close" />
                  </Pellets>
                </ListItemIcon>close
              </ListItem>
            </Tooltip>
          ) 
          : ( 
            <Tooltip title="open">
              <ListItem button>
                <ListItemIcon  style={{ minWidth: open ? "24px" : "0px" }}>
                <Pellets>
                  <img src={right} alt="open" />
                  </Pellets>
                </ListItemIcon>
              </ListItem>
            </Tooltip>
          )
        }
      </Link>
    </Box>
  );
}
