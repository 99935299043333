// Ensure that localStorage.getItem("clientName") is set before this code runs
const clientName = localStorage.getItem("clientName");
const postLogoutRedirectUri = clientName ? `${process.env.REACT_APP_postLogoutRedirectUri}${clientName}/` : process.env.REACT_APP_postLogoutRedirectUri;

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_clientId,
    authority: process.env.REACT_APP_authority,
    knownAuthorities: [process.env.REACT_APP_knownAuthorities], 
    redirectUri: process.env.REACT_APP_redirectUri,
    postLogoutRedirectUri: postLogoutRedirectUri,
    navigateToLoginRequestUrl: false, 
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
    claimsBasedCachingEnabled: true
  }
};
