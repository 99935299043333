import { createSlice } from "@reduxjs/toolkit";
import { getProfilesThunk, editProfileThunk, addProfileThunk } from "../services/profilethunk";

const initialState = {
  profiles: [],
  status: 'idle',
  error: null,
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getProfilesThunk.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getProfilesThunk.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.profiles = action.payload;
      })
      .addCase(getProfilesThunk.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(editProfileThunk.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(editProfileThunk.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(editProfileThunk.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(addProfileThunk.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addProfileThunk.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(addProfileThunk.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

export default profileSlice.reducer;
