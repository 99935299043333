import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Slide, Box } from '@mui/material';
import { styled } from '@mui/system';

import Paper from '@mui/material/Paper';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const DefaultPaper = styled(Paper)({
  position: 'relative',
  left: '11.9%',
  width: '79%',
  height: '100%',
//  justifyContent: 'flex-end',
  paddingRight: '25px',  // Adding padding to ensure scrollbar visibility
  maxHeight: 'calc(100% - 3px) !important'
});

const DialogComponent = ({ show, handleClose, title, handleTitleChange, children, handleSubmit, PaperComponent, actions }) => {
  const defaultActions = (
    <>
      <Button variant="contained" style={{ backgroundColor: '#FFF', color: '#4787D2' }} onClick={handleClose}>
        Cancel
      </Button>
      <Button variant="contained" style={{ backgroundColor: '#4787D2', color: '#FFF' }} onClick={handleSubmit}>
        Submit
      </Button>
    </>
  );
  return (
    <Dialog open={show} onClose={handleClose} maxWidth={false} PaperComponent={PaperComponent || DefaultPaper} TransitionComponent={Transition}>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {title}
          <DialogActions>
          {actions || defaultActions}
          </DialogActions>
        </Box>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

export default DialogComponent;
