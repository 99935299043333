import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Paper,
  Tabs,
  Tab,
  Button,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField, // Import TextField from Material-UI
} from '@mui/material';
import axios from 'axios';

const API_BASE = process.env.REACT_APP_API;

const API = `${API_BASE}/main/profile/`;

const instance = axios.create({
  baseURL: API,
  headers: {},
});

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

function AdminPage() {
  const [activeTab, setActiveTab] = useState(0);
  const [advancedOpsTab, setAdvancedOpsTab] = useState(0);
  const [data, setData] = useState([]);
  const [cNames, setcNames] = useState([]);
  const [dbNames, setDbNames] = useState([]);
  const [JobIds, setJobIds] = useState([]);
  const [selectedCloneDb, setSelectedCloneDb] = useState('');
  const [selectedJobId, setSelectedJobId] = useState('');
  const [selectedCurrentClient, setselectedCurrentClient] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openCreateForm, setOpenCreateForm] = useState(false);

  useEffect(() => {
    fetchDbNames();
  }, [activeTab]);

  useEffect(() => {
    if (selectedCurrentClient) {
      fetchCollectionNames();
      fetchJobIds();
      fetchData(activeTab);
    }
  }, [selectedCurrentClient, activeTab]);

  const fetchCollectionNames = async () => {
    try {
      const response = await instance.get('adminlist', { params: { from: selectedCurrentClient } });
      setcNames(response.data.collection_names);
    } catch (error) {
      console.error('Error fetching collection names:', error);
    }
  };

  const fetchDbNames = async () => {
    try {
      const response = await instance.get('dblist');
      setDbNames(response.data.db_names);
    } catch (error) {
      console.error('Error fetching database names:', error);
    }
  };

  const fetchJobIds = async () => {
    try {
      const response = await instance.get('jobidlist', { params: { from: selectedCurrentClient } });
      console.log(response.data.jobids)
      setJobIds(response.data.jobids);
    } catch (error) {
      console.error('Error fetching database names:', error);
    }
  };

  const handleAdvancedOpsTabChange = (event, newValue) => {
    setAdvancedOpsTab(newValue);
    fetchData(newValue); // Update the Advanced Operations tab
  };

  const fetchData = async (tabIndex) => {
    try {
      const cName = cNames[tabIndex];
      const response = await instance.get(`data/${cName}`, { params: { from: selectedCurrentClient } });
      setData(response.data.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    fetchData(newValue);
  };

  const handleCloneClick = (item) => {
    setSelectedCloneDb('');
    setOpenDialog(true);
  };

  const handleJobIdCloneClick = (item) => {
    setSelectedCloneDb('');
    setSelectedJobId('');
    setOpenDialog(true);
  };

  const handleDeleteClick = () => {
    setOpenDeleteDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleDeleteConfirm = async () => {
    // Handle the delete confirmation
    
    if (selectedCurrentClient) {
      // Make a POST request to delete data
      console.log("deleting inside")
      try {
        console.log( {
          from: selectedCurrentClient,
        })
        const response = await instance.post('clientdelete', {
          from: selectedCurrentClient,
        });
        console.log('Delete response:', response.data);
        // Handle the response or show a success message
      } catch (error) {
        console.error('Error deleting data:', error);
      }
      setOpenDeleteDialog(false); // Close the delete dialog box
    }
  };

  const handleCreateClick = () => {
    setOpenCreateForm(true);
  };

  const handleCreateFormClose = () => {
    setOpenCreateForm(false);
  };

  const handleCreateSubmit = async (event) => {
    event.preventDefault();
    // Handle the form submit for creating a new client
    const formData = new FormData(event.target);
    const collectionName = formData.get('collectionName');
    const dbName = formData.get('dbName');

    // Make a POST request to create a new client using the template
    try {
      const response = await instance.post('create', {
        collectionName,
        dbName,
      });
      console.log('Create response:', response.data);
      // Handle the response or show a success message
    } catch (error) {
      console.error('Error creating client:', error);
    }

    setOpenCreateForm(false); // Close the create form dialog
  };

  const handleCloneConfirm = async () => {
    // Handle the clone confirmation for "Advanced Operations" tab
    const itemToClone = { dbName: selectedCloneDb, from: selectedCurrentClient};
    console.log(itemToClone)
    const item = data[activeTab];
    if (cNames[activeTab] === "job_components") {
      itemToClone.id = item._id; // Replace with the correct property name
    } else if (cNames[activeTab] === "job_profiles_resume_scores") {
      itemToClone.id = item.jobProfileId; // Replace with the correct property name
    } else {
      itemToClone.id = item.jobId; // Replace with the correct property name
    }
    if (selectedCloneDb && selectedCurrentClient) {
      // Make a POST request to clone data
      try {
        const response = await instance.post('clone', itemToClone);
        console.log('Clone response:', response.data);
        // Handle the response or show a success message
      } catch (error) {
        console.error('Error cloning data:', error);
      }
      setOpenDialog(false); // Close the dialog box
    }
  };  

  const handleClientCloneConfirm = async () => {
    // Handle the clone confirmation for "Clone Client" tab
    const itemToClone = { dbName: selectedCloneDb, from: selectedCurrentClient};
    console.log(itemToClone)
    if (selectedCloneDb && selectedCurrentClient) {
      // Make a POST request to clone data
      try {
        const response = await instance.post('clientclone', itemToClone);
        console.log('Clone response:', response.data);
        // Handle the response or show a success message
      } catch (error) {
        console.error('Error cloning data:', error);
      }
      setOpenDialog(false); // Close the dialog box
    }
  };

  const handleJobIdCloneConfirm = async () => {
    // Handle the clone confirmation for "Clone using JobId" tab
    const itemToClone = {
      dbName: selectedCloneDb,
      jobId: selectedJobId,
      from: selectedCurrentClient
    };
    console.log(itemToClone)
    if (selectedCloneDb && selectedJobId && selectedCurrentClient) {
      // Make a POST request to clone data using JobId
      try {
        const response = await instance.post('jobidclone', itemToClone);
        console.log('Clone with JobId response:', response.data);
        // Handle the response or show a success message
      } catch (error) {
        console.error('Error cloning data with JobId:', error);
      }
      setOpenDialog(false); // Close the dialog box
    }
  };

  return (<>
  <br/>
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        maxHeight: 'calc(100vh - 64px)',
        backgroundColor: '#F5F7F9',
        flexDirection: 'column',
        marginTop:"1px"
      }}
    >
      <Container maxWidth="lg">
        <Typography variant="h4" gutterBottom align="center">
          ADMIN PAGE
        </Typography>
        <Typography variant="subtitle1" gutterBottom align="center">
          You can clone collections from one database to another
        </Typography>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="Clone Client" />
          <Tab label="Clone using JobId" />
          <Tab label="Delete Client Data" />
          <Tab label="Advanced Operations" />
          <Tab label="Create Client using Template" />
        </Tabs>
        <Select
                  label="Select the current client"
                  value={selectedCurrentClient}
                  onChange={(e) => setselectedCurrentClient(e.target.value)}
                  fullWidth
                >
                  <MenuItem value="">
                    <em>Select the current client</em>
                  </MenuItem>
                  {dbNames.map((dbName) => (
                    <MenuItem value={dbName} key={dbName}>
                      {dbName}
                    </MenuItem>
                  ))}
        </Select>
        {activeTab === 0 && (
          <div>
            {/* Clone Client Tab */}
            <Button
              variant="outlined"
              onClick={() => handleCloneClick(data[activeTab])}
            >
              Clone
            </Button>
          </div>
        )}
        {activeTab === 1 && (
          <div>
            {/* Clone using JobId Tab */}
            <Button
              variant="outlined"
              onClick={() => handleJobIdCloneClick(data[activeTab])}
            >
              Clone
            </Button>
          </div>
        )}
        {activeTab === 2 && (
          <div>
            {/* Delete Client Data Tab */}
            <Button variant="outlined" onClick={handleDeleteClick}>
              Delete
            </Button>
          </div>
        )}
        {activeTab === 3 && (
  <div>
    {/* Advanced Operations Tab */}
    <Grid container spacing={3}>
      <Grid item xs={3}>
        {/* Vertical navbar with tabs */}
        <Paper>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={advancedOpsTab}
            onChange={handleAdvancedOpsTabChange}
          >
            {/* Generate tabs based on collection names */}
            {cNames.map((cName, index) => (
              <Tab label={cName} key={index} />
            ))}
          </Tabs>
        </Paper>
      </Grid>
      <Grid item xs={9}>
        {/* Display data as cards */}
        <div
          style={{
            maxHeight: '400px',
            overflowY: 'auto',
          }}
        >
          <Grid container spacing={3}>
            {data && data.length > 0 ? (
              data.map((item) => (
                <Grid item xs={4} key={item._id}>
                  <Paper alignItems="center">
                    <div>{item._id}</div>
                    <Button
                      variant="outlined"
                      onClick={() => handleCloneClick(item)}
                    >
                      Clone
                    </Button>
                  </Paper>
                </Grid>
              ))
            ) : (
              <Grid item xs={12}>
                <Paper>
                  <div>No Data</div>
                </Paper>
              </Grid>
            )}
          </Grid>
        </div>
      </Grid>
    </Grid>
  </div>
)}
  {activeTab === 4 && (
          <div>
            {/* Create Client using Template Tab */}
            <Button variant="outlined" onClick={handleCreateClick}>
              Create
            </Button>
          </div>
        )}

        {/* Dialog box for cloning */}
        <Dialog open={openDialog} onClose={handleDialogClose}>
          <DialogTitle>Clone Data</DialogTitle>
          <DialogContent>
            {(activeTab === 0 || activeTab === 1 || activeTab === 3) && (
              <div>
                <Select
                  label="Select a Database"
                  value={selectedCloneDb}
                  onChange={(e) => setSelectedCloneDb(e.target.value)}
                  fullWidth
                >
                  <MenuItem value="">
                    <em>Select a Database</em>
                  </MenuItem>
                  {dbNames.map((dbName) => (
                    <MenuItem value={dbName} key={dbName}>
                      {dbName}
                    </MenuItem>
                  ))}
                </Select>
                {activeTab === 1 && (
                  <Select
                    label="Select a JobId"
                    value={selectedJobId}
                    onChange={(e) => setSelectedJobId(e.target.value)}
                    fullWidth
                  >
                    <MenuItem value="">
                      <em>Select a JobId</em>
                    </MenuItem>
                    {JobIds.map((JobId) => (
                    <MenuItem value={JobId} key={JobId}>
                      {JobId}
                    </MenuItem>
                    ))}
                  </Select>
                )}
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose}>Cancel</Button>
            <Button
              onClick={
                activeTab === 0
                  ? handleClientCloneConfirm
                  : activeTab === 1
                  ? handleJobIdCloneConfirm
                  : activeTab === 3
                  ? handleCloneConfirm
                  : null
              }
            >
              Clone
            </Button>
          </DialogActions>
        </Dialog>

        {/* Dialog box for delete */}
        <Dialog open={openDeleteDialog} onClose={handleDeleteConfirm}>
          <DialogTitle>Delete Client Data</DialogTitle>
          <DialogContent>
            <div>Are you sure you want to delete this client's data?</div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteConfirm}>Delete</Button>
            <Button onClick={() => setOpenDeleteDialog(false)}>Cancel</Button>
          </DialogActions>
        </Dialog>

        {/* Create Client using Template Form */}
        <Dialog open={openCreateForm} onClose={handleCreateFormClose}>
          <DialogTitle>Create Client using Template</DialogTitle>
          <DialogContent>
            <form onSubmit={handleCreateSubmit}>
              <TextField
                label="Collection Name"
                name="collectionName"
                required
                fullWidth
              />
              <Select
                label="Select a Database"
                value={selectedCloneDb}
                onChange={(e) => setSelectedCloneDb(e.target.value)}
                fullWidth
              >
                <MenuItem value="">
                  <em>Select a Database</em>
                </MenuItem>
                {dbNames.map((dbName) => (
                  <MenuItem value={dbName} key={dbName}>
                    {dbName}
                  </MenuItem>
                ))}
              </Select>
              <Button type="submit">Create</Button>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCreateFormClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      </Container>
    </div>
    </>
  );
}

export default AdminPage;