import { createAsyncThunk } from "@reduxjs/toolkit";
import * as service from "./createjobservice";

export const createJobThunk = createAsyncThunk(
  'jobs/createJob',
  async ({job, file}) => {
      const j = await service.createJob(job, file);
      return j;
  }
);

export const extractJdThunk = createAsyncThunk(
  'jobs/extractJob',
  async({file}) => {
    const j = await service.extractJd(file);
    return j;
  }
);

export const postResumesThunk = createAsyncThunk(
  'jobs/uploadResumes',
  async ({jobId, resumeBlob}) => {
    const j = await service.postResumes(jobId, resumeBlob);
    return j;
  }
);

export const getJobThunk = createAsyncThunk(
  'jobs/getJob',
  async () => {
    const j = await service.getJob();
    return j;
  }
);

export const getJobDetailsThunk = createAsyncThunk(
  'jobs/getJobDetails',
  async (jobId) => {
    const j = await service.getJobDetails(jobId);
    return j;
  }
);

export const getJobDecisionsThunk = createAsyncThunk(
  'jobs/getJobDecisions',
  async (jobId) => {
    const j = await service.getJobdecisions(jobId);
    return j;
  }
);

export const updatejobThunk = createAsyncThunk(
  'jobs/updateJob',
  async({job_id,action}) => {
    console.log("log from update ", job_id,action)
    const j = await service.updatejob(job_id,action);
    return j;
  }
);

export const updaterangeThunk = createAsyncThunk(
  'jobs/updaterange',
  async({job_id,action}) => {
    console.log("log from update ", job_id,action)
    const j = await service.updatejobrange(job_id,action);
    return j;
  }
);