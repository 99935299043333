import React, { useState, useEffect, useRef } from 'react';
import InputBase from '@mui/material/InputBase';
import { Typography, Menu, MenuItem } from '@material-ui/core';
import { Popover } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { useLocation } from "react-router-dom";

// Inside the SearchComponent

import { ReactComponent as BlujinSearchIcon } from '../assets/images/searchIcon.svg';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@material-ui/core/styles';

import { searchJobsThunk } from '../services/searchThunk';
import { useDispatch } from 'react-redux';


const useStyles = makeStyles((theme) => ({
  searchTypography: {
    color: '#8F8F8F',
    fontFamily: 'Poppins',
    fontSize: '1rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '1.375rem', 
    letterSpacing: '0.01rem',
    textAlign: 'left',
    paddingLeft: "0.4%",
  },
  searchResultText: {
    color: '#000',
  },
  menuPaper: {
    minWidth: '70%',
   
    marginTop: theme.spacing(1),
    borderRadius: '0.25rem',
  },
}));

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: '0.25rem',
  backgroundColor: `rgba(217, 217, 217, 0.04)`,
  '&:hover': {
    backgroundColor: `rgba(217, 217, 217, 0.14)`,
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

const SearchComponent = () => { const dispatch = useDispatch();
  
  const navigate = useNavigate();
  const location = useLocation();

  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  
  // Introduced a separate state to determine if the Menu should be open
  const [menuOpen, setMenuOpen] = useState(false);
  const handlesearch = (event) => {
    if (searchResults?.jobs || searchResults?.resume) {
      navigate("/"+localStorage.getItem("clientName")+`/search-results/${searchTerm}`);
    }
  };

  const handleItemClick = (jobId) => {
    navigate("/"+localStorage.getItem("clientName")+`/job-detail/${jobId}`, { state: { loc: location.pathname } });
    
  };
  const goToJobDetails = (jobId, candidateId) => {
    navigate("/"+localStorage.getItem("clientName")+`/job-detail/${jobId}?tab=resumes&candidate=${candidateId}`, { state: { loc: location.pathname } });
    console.log("search",searchResults)
  };

  const handleClick = (event) => {
    if(searchResults?.jobs || searchResults?.resume){
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);} // Open the menu on click
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMenuOpen(false); // Close the menu
  };

  useEffect(() => {
    if (searchTerm.length >= 3) {
      dispatch(searchJobsThunk(searchTerm))
        .unwrap()
        .then(jobs => {
          setSearchResults(jobs);
          
          if (jobs.jobs.length || jobs.resume.length) {
            setMenuOpen(true); // Open the menu if there are results
          } else {
            setMenuOpen(false); // Close the menu if there are no results
          }
        })
        .catch(error => {
          console.error("Error while searching:", error);
        });
    } else {
      setSearchResults([]);
      setMenuOpen(false); // Close the menu if searchTerm length is less than 3
    }
    console.log("searchresults",searchResults)
  }, [searchTerm, dispatch]);

  return (
    <Search style={{ backgroundColor: `rgba(217, 217, 217, 0.04)`, width: '100%' }}>
      <SearchIconWrapper>
        <BlujinSearchIcon />
      </SearchIconWrapper>
      <Typography component="div" className="searchText-style">
        <StyledInputBase
          placeholder="Search…"
          inputProps={{ 'aria-label': 'search' }}
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
        }}
          onClick={handleClick}
        />
      </Typography>
      <Menu
  open={menuOpen}
  onClose={handleClose}
  style={{marginTop:"25px", marginLeft:"12%"}}
  classes={{ paper: classes.menuPaper }}
  getContentAnchorEl={null}
>
  {/* First menu item with search icon and the search term */}
  <MenuItem className={classes.searchResultText} onClick={handlesearch}>
    <BlujinSearchIcon style={{ marginRight: '10px' }} />
    {searchTerm}&nbsp; <span style={{color: "grey", fontSize: "0.75em"}}>press enter to view all results...</span>

  </MenuItem>

  {/* Greyed out "People" title */}
  {searchResults?.resume?.length > 0 && (
    <MenuItem >
      <span style={{color: "grey", fontSize: "0.85em"}}>people</span>
    </MenuItem>
  )}

  {/* Top 2 People search results */}
  {searchResults?.resume?.slice(0, 2).map((result, idx) => (
    <MenuItem key={idx} className={classes.searchResultText} onClick={() => goToJobDetails(result.jobId, result.resumeId)}>
      {result.personalDetails.name} in {result.jobTitle}
    </MenuItem>
  ))}

  {/* "Job" title */}
  {searchResults?.jobs?.length > 0 && (
    <MenuItem style={{ fontWeight: 'bold' }} >
      
      <span style={{color: "grey", fontSize: "0.85em"}}>Jobs</span>
    </MenuItem>
  )}

  {/* Top 2 Job search results */}
  {searchResults?.jobs?.slice(0, 2).map((result, idx) => (
    <MenuItem key={idx} className={classes.searchResultText} onClick={() => handleItemClick(result._id.$oid.toString())}>
      {result.jobTitle}
    </MenuItem>
  ))}
</Menu>

    </Search>
  );
}

export default SearchComponent;
