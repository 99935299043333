import { createSlice } from "@reduxjs/toolkit";
import { getnotifcationThunk } from "../services/notificationthunk";

const initialState = {

  status: 'idle',
  error: null,
  notifications: []
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
  },
  extraReducers: builder => {
    builder
      .addCase(getnotifcationThunk.pending, (state) => {
        state.status = 'loading';
        state.specificState = 'loading';
      })
      .addCase(getnotifcationThunk.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.notifications = action.payload;
        state.specificState = 'succeeded';
      })
      .addCase(getnotifcationThunk.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
  }
});
export default notificationSlice.reducer;

