import React, { useEffect, useState } from 'react';
import { useSelector} from "react-redux";
import { useDispatch } from "react-redux";
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import { alpha, styled } from '@mui/material/styles';
import { IconButton } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useLocation } from "react-router-dom";
import { getJobThunk, updatejobThunk, getJobDecisionsThunk } from '../services/jobthunk';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import more from "../assets/images/more.svg";
import * as XLSX from 'xlsx';
import { Settings } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
  noRowBorders: {
    '& .MuiDataGrid-row': {
      borderBottom: 'none', // Remove the bottom border for all rows
    },
  },
}));
const ODD_OPACITY = 0.2;


const StyledDataGrid = styled(DataGrid)(({ theme }) => ({


  [`& .${gridClasses.row}`]: {
    backgroundColor: "#ffffff",
    borderRadius: '10px',
    border: '0px solid #F5F7F9',
    marginBottom: '10px',
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.css-1724yxx-MuiDataGrid-root, &.MuiDataGrid-withBorderColor' :{
      border:'none'
    },
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity,
      ),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity,
        ),
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  },
  [`& .${gridClasses.row}:after`]: {
    content: '""',
    display: 'block',
    height: '0.5%',
    width: '100%',
    
  },
}));

function Joblist({ rows, onScroll }) {
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings.basic);
  const [hover, setHover] = useState(null);
  const [parsedRows, setParsedRows] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [selectedReqId, setSelectedReqId] = useState(null);
  const [syncJobDisabled, setSyncJobDisabled] = useState(true);
  const [resyncJobDisabled, setReSyncJobDisabled] = useState(null);
  const [atsJob, setAtsJob] = useState(false);
  const [savedJobsSortModel, setSavedJobsSortModel] = useState([]);
  const location = useLocation();
  const handleClick = (event, id,reqId) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowId(id);
    setSelectedReqId(reqId)
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNewCandidates = async () => {
    if (selectedRowId != null) {
      const response= await dispatch(updatejobThunk({ job_id: selectedRowId , action: 'NewCandidates' }));
      console.log(response)
    }
    handleRefresh()
    handleClose();
  };

 const handleJobSync = async () => {
    if (selectedRowId != null) {
      const response= await dispatch(updatejobThunk({ job_id: selectedRowId , action: 'JobSync' }));
      console.log(response)
    }
    handleRefresh()
    handleClose();
  };

  const handleJobReSync = async () => {
    if (selectedRowId != null) {
      const response= await dispatch(updatejobThunk({ job_id: selectedRowId , action: 'JobReSync' }));
      console.log(response)
    }
    handleRefresh()
    handleClose();
  }; 

  useEffect( () => {
//    localStorage.removeItem('savedJobsSortModel');
    const savedModel =  localStorage.getItem('savedJobsSortModel');
    if (savedModel) {
      setSavedJobsSortModel(JSON.parse(savedModel));
    }
    else {
      setSavedJobsSortModel([{field: "date", sort: "desc"}]);
    }
   }, []);

  const handleJobsSortModelChange = (model) => {
    console.log('Jobs Sort model changed:', model);
    setSavedJobsSortModel(model);
    localStorage.setItem('savedJobsSortModel', JSON.stringify(model));
  }; 

  const handleJobClose = async () => {
    if (selectedRowId != null) {
      const response= await dispatch(updatejobThunk({ job_id: selectedRowId , action: 'Close' }));
      console.log(response)
    }
    handleRefresh()
    handleClose();
  };

  const handledownload = async () => {
    if (selectedRowId != null) {
      const response= await dispatch(getJobDecisionsThunk(selectedRowId));
      console.log("deciions", response)
      const ws = XLSX.utils.json_to_sheet(response.payload); // Adjust 'response.payload' as needed based on your actual response structure

      // Create a new workbook
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "ResponseData"); // "ResponseData" is the sheet name
  
      // Generate an XLSX file and trigger download
      XLSX.writeFile(wb, "Candidate_Decisions_"+selectedReqId+".xlsx");
    }
    handleClose();
  };

  const classes=useStyles()
  useEffect(() => {
    setParsedRows(rows.map(row => JSON.parse(row)));
  }, [rows]);

  const handleRefresh = () => {
    dispatch(getJobThunk());

  };

  // Declare intervalId

  let intervalId;

  // Use useEffect to trigger handleRefresh every 15 seconds
  useEffect(() => {
    intervalId = setInterval(handleRefresh, 15000); // 15 seconds in milliseconds

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array ensures this effect runs only once after the component mounts

  // Cleanup function to clear the interval when the user navigates away from the page
  useEffect(() => {
    const clearIntervalOnPageUnload = () => {
      clearInterval(intervalId);
    };

    window.addEventListener('beforeunload', clearIntervalOnPageUnload);

    return () => {
      window.removeEventListener('beforeunload', clearIntervalOnPageUnload);
    };
  }, []);

  const columns = [
    { 
      field: 'reqId', 
      headerName: 'ID',
      width: 80
    },
    { 
      field: 'jobTitle', 
      headerName: 'Job Title',
      width: 200,
      renderCell: (params) => (
        <Link to={`/${localStorage.getItem("clientName")}/job-detail/${params.row._id}`} state={{ loc: location.pathname }}>
          {params.value}
        </Link>
      )
    },
    {
      field: 'department',
      headerName: 'Department',
      width: 180
    },
    {
      field: 'hiringManager',
      headerName: 'Hiring Manager',
      width: 180
    },
    {
      field: 'date',
      headerName: 'Date Created',
      width: 160
    },
    {
      field: 'status',
      headerName: 'Job Status',
      width: 100,
      renderCell: (params) => {
        return (
          <div style={{ borderRadius: '3px', background: '#E8F6F4', padding: "0px 4px" }}>
            {params.value}
          </div>
        );
      },
      
    },
    {
      field: 'profileCount',
      headerName: 'Profiles',
      width: 70,
    },
    {
      field: 'candidateCount',
      headerName: 'Candidates',
      width: 100,
    },

    {
      field: 'more',
      headerName: '',
      width: 50,
      renderCell: (params) => (
        <>
          <IconButton 
            aria-label="more" 
            onClick={(event) => handleClick(event, params.row.jobId, params.row.reqId)}
          >
            <img src={more} alt="more icon" />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
                        <MenuItem onClick={handleNewCandidates} disabled={settings?.ATS !== true}>Sync from ATS to Blujin</MenuItem>
                        <MenuItem onClick={handleJobSync} disabled={syncJobDisabled}>Sync from Blujin to ATS</MenuItem>
                        <MenuItem onClick={handleJobReSync} disabled={resyncJobDisabled}>ReSync Applicants from ATS to Blujin</MenuItem>
                        <MenuItem onClick={handledownload}>Download Candidate Report</MenuItem>
                        <MenuItem onClick={handleJobClose}>Close</MenuItem>
          </Menu>
        </>
      ),
    },
    
  ];

  return (
    <div style={{ maxHeight: '70vh', width: '100%', position: 'relative', paddingTop: '1%' }}>
      <IconButton 
        aria-label="refresh"
        style={{ marginBottom: 2 }}
        onClick={handleRefresh}
      >
        <RefreshIcon />
      </IconButton>
      
      <StyledDataGrid
        rows={parsedRows}
        columns={columns}
        sortModel={savedJobsSortModel}
        // sortComparator={specialDisplaySortComparator}
        onSortModelChange={handleJobsSortModelChange}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 7,
            },
          },
        }}
        pageSizeOptions={[7]}
        getRowId={(row) => row._id}
        onScroll={onScroll}
        style={{ borderColor: '#F5F7F9' }}
        getRowClassName={(params) =>
           'even'
        }
      />
      {hover && (
        <div style={{ 
          position: "absolute",
          width: "200px", 
          backgroundColor: "#555",
          color: "#fff",
          textAlign: "center",
          borderRadius: "6px",
          padding: "5px 0",
          right: "10px",
          top: "50%",
          transform: "translateY(-50%)",
          zIndex: 2,
        }}>
          {JSON.stringify(hover, null, 2)}
        </div>
      )}
    </div>
  );
}

export default Joblist;
