import React, { useRef,useState,useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, Autocomplete,Chip,TextField,Box, DialogActions, Button, Typography, Link, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { inviteThunk } from '../services/userserviceThunk';
import { useDispatch } from 'react-redux';
function InviteUsersDialog({ open, onClose }) {
    const [basicUsers, setBasicUsers] = useState([]);
    const [admins, setAdmins] = useState([]);
    const [customMessage, setCustomMessage] = useState('');
    const fileInputRef = useRef(null);
    const dispatch = useDispatch()
    const handleBasicUsersChange = (event, value) => {
        setBasicUsers(value);
    };

    const handleAdminsChange = (event, value) => {
        setAdmins(value);
    };

    
    const handleclose = ()=>{
        setBasicUsers([]);
        setAdmins([]);
        setCustomMessage('');
        onClose();

    }

    const handlesend = () => {
        if (admins.length < 1 && basicUsers.length < 1 ) {
            
        } else {
            const users = {"admins":admins,"users": basicUsers, "message": customMessage}
            dispatch(inviteThunk(users)) 
            handleclose()
        }
    }

    const handleCustomMessageChange = (event) => {
        setCustomMessage(event.target.value);
    };
    

    const handleFileUpload = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            console.log("Uploading file:", file.name);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="invite-users-dialog"  fullWidth >
            <DialogTitle>
                Invite Users
                <IconButton style={{ position: 'absolute', right: 8, top: 8 }} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
            <Box sx={{ width: '100%', maxWidth: 500 }}> 
                <Autocomplete
                    multiple
                    id="basic-user-email"
                    options={[]}
                    style={{ width: '100%' }}
                    fullWidth
                    freeSolo
                    value={basicUsers}
                    onChange={handleBasicUsersChange}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                            <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                        ))
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            autoFocus
                            margin="dense"
                            id="basic-user-email"
                            label="Who would you like to invite as a Basic user?"
                            placeholder="Enter user email id"
                            type="email"
                            fullWidth
                        />
                    )}
                />
                <Autocomplete
                    multiple
                    id="admin-user-email"
                    options={[]}
                    freeSolo
                    value={admins}
                    onChange={handleAdminsChange}
                    style={{ width: '100%' }}
                    fullWidth
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                            <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                        ))
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            margin="dense"
                    id="admin-user-email"
                    label="Who would you like to invite as an Administrator?"
                    placeholder="Enter administrator email id"
                    type="email"
                    fullWidth
                        />
                        )}
                        />
        
                <Typography variant="body2" component="p">
                    Or <Link component="button" variant="body2" onClick={handleFileUpload}>upload users in bulk</Link>
                </Typography>
                <input
                    type="file"
                    style={{ display: 'none' }}
                    ref={fileInputRef}
                    onChange={handleFileChange}
                />
                <TextField
                margin="dense"
                id="custom-message"
                label="Message"
                multiline
                rows={4}
                fullWidth
                value={customMessage} // Set the value of the TextField
                onChange={handleCustomMessageChange} // Update state on change
            />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleclose} color="primary">
                    Cancel
                </Button>
                <Button variant="contained" onClick={handlesend} color="primary">
                    Send Invitation
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default InviteUsersDialog;
