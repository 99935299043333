import React, { useState, useEffect, useRef } from 'react';
import {
  Box, Grid, Button, Paper, Dialog, DialogTitle, DialogContent, DialogActions, Menu, MenuItem, Slide,
  FormControl, Select, InputLabel
} from '@mui/material';
import { styled } from '@mui/system';
import './recordDecision.css';
import { updatedecisionThunk, bulkUpdatedecisionThunk } from '../services/resume_thunk';

import { makeStyles } from '@material-ui/core/styles';
import DialogComponent from '../components/dialoguebox/dialogue';
import { useSelector, useDispatch } from "react-redux";
import { getJobThunk, postResumesThunk } from '../services/jobthunk';
import EmailTemplatePopup from '../candidate Details/emailTemplatePopup';
import { local } from 'd3';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});



const DefaultPaper = styled(Paper)({
  position: 'relative',
  left: '11.9%',
  width: '79%',
  height: '100%',
  justifyContent: 'flex-end',
  maxHeight: 'calc(100% - 3px) !important'
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginLeft: 0,
    backgroundColor: 'rgba(71, 135, 210, 0.10)',
    padding: '2%',
    paddingRight: '50%',
    textAlign: 'left',
  },
  formControl: {
    marginTop: theme.spacing(1),
  },
  inputLabel: {
    background: 'white',
    padding: '0 5px',
    marginLeft: 5,
  },
  selectMenu: {
    fontSize: '10px',
  }
}));

const RecordDecision = ({ show, decisionType, trans, candidate, currentStage, numBulkCandidates, listCriteria, handleClose, myDecisionChange, propogateDecisionChange, handleDecision, parentPosition, jobId, resumes,
 savedReason, savedAdvancedSchedule, savedAdvancedTemplate, savedDeclinedSchedule, savedDeclinedTemplate,
 setSavedReason, setSavedAdvancedSchedule, setSavedAdvancedTemplate, setSavedDeclinedSchedule, setSavedDeclinedTemplate,
 myStorage, jobDetails
}) => {
  const [myDecisionType, setMyDecisionType] = useState();
  const [selectedStage, setSelectedStage] = useState(null);
 
  const [selectedReason, setSelectedReason] = useState();
  const notApplicable = "0"; //key of the Not Applicable item for reason

  const [activeTemplates, setActiveTemplates] = useState({});
  const [selectedEmailTemplate, setSelectedEmailTemplate] = useState();

  const [selectedSchedule, setSelectedSchedule] = useState();

  const [settingsLoaded, setSettingsLoaded] = useState(false);
  const settings = useSelector((state) => state.settings.basic);

  const [decisionDetailsSelected, setDecisionDetailsSelected] = useState(false);

  const [emailTemplateOpen, setEmailTemplateOpen] = useState(false); // State to control the email template popup

  const [titleValue, setTitleValue]=useState('');
  const [actionValue, setActionValue]=useState('');

  const dispatch = useDispatch();
  const classes = useStyles();
  const [thisCandidateScore, setThisCandidateScore]=useState(0);
  const [thisCandidateName, setThisCandidateName]=useState('');
  const [thisCandidateJobId, setThisCandidateJobId]=useState('');

  useEffect(() => {setMyDecisionType(decisionType);}, [decisionType])
  useEffect(() => {
    if (myDecisionType === 'Declined') {
      if (findReasonById(savedReason)) {
        setSelectedReason(savedReason);
      }
      else {
        setSelectedReason(0);
      }

      setActiveTemplates(settings.Declined_Templates);

      if (savedDeclinedTemplate && savedDeclinedTemplate !== '[object Object]') {
        setSelectedEmailTemplate(savedDeclinedTemplate);
      }
      else {
        setSelectedEmailTemplate(0); //reset template to 0 No Email
        setSavedDeclinedTemplate(0);
        myStorage.setItem('savedDeclinedTemplate', 0);
      }

      setSelectedSchedule(savedDeclinedSchedule);
    }
    else {
      setSelectedReason(notApplicable);
      if (savedAdvancedTemplate && savedAdvancedTemplate !== '[object Object]') {
        setSelectedEmailTemplate(savedAdvancedTemplate);
      }
      else {
        setSelectedEmailTemplate(0); //reset template to 0 No Email
        setSavedAdvancedTemplate(0);
        myStorage.setItem('savedAdvancedTemplate', 0);
      }
      setSelectedSchedule(savedAdvancedSchedule);   
      setActiveTemplates(settings.Advanced_Templates);
    }
    console.log('handled decision type', myDecisionType);
  },[show, myDecisionType]);

  const findReasonById = (id) => {
    if (id === '' || id === null) return null; 
    if (!settings.RejectionReasons) return null;
    return settings.RejectionReasons.find(entry => entry.id.toString() === id.toString());
  }

  const findTemplateById = (id) => { 
    if (!activeTemplates) return null;
    return activeTemplates.find(entry => entry.id.toString() === id.toString());
  }

  const handleReasonChange = (event) => {
    console.log('Reason was previously set to ', selectedReason)
    setSelectedReason(event.target.value);
    setSavedReason(event.target.value);
    myStorage.setItem('savedReason', event.target.value);
    console.log('Selected Reason is now set to ', event.target.value);
  };

  const handleTemplateHover = (event, item) => {
    if (emailTemplateOpen) {
      setupTemplate(event,item);
    }
  };
 
  const handleDropdownOpen = () => {
    setEmailTemplateOpen(true);
  };

  const handleDropdownClose = () => {
    setEmailTemplateOpen(false);
  }

  const handleMouseLeave = () => { 
    if (!selectedEmailTemplate) {
      setEmailTemplateOpen(false);
    }
  };

  const setupTemplate = (event,item) => { 
    console.log('Email template was previously set to ', selectedEmailTemplate);
    setSelectedEmailTemplate(item.id);
    if (decisionType === 'Declined') {
      setSavedDeclinedTemplate(item.id);
      myStorage.setItem('savedDeclinedTemplate', item.id);
    }
    else {
      setSavedAdvancedTemplate(item.id);
      myStorage.setItem('savedAdvancedTemplate', item.id);
    }
    console.log('Email template is now set up as', item.id)
  };

  const handleMouseClick = (event, item) => { 
     setEmailTemplateOpen(false);
     setupTemplate(event,item);
   };


  const handleEmailTemplateChange = (event) => {
    setEmailTemplateOpen(false);
    console.log('Email template was previously set to ', selectedEmailTemplate);
    setSelectedEmailTemplate(event.target.value);
    if (decisionType === 'Declined') {
      setSavedDeclinedTemplate(event.target.value);
      myStorage.setItem('savedDeclinedTemplate', event.target.value);
    }
    else {
      setSavedAdvancedTemplate(event.target.value);
      myStorage.setItem('savedAdvancedTemplate', event.target.value);
    }
    console.log('Email template is now set up as', event.target.value)
  };

  const handleScheduleChange = (event) => {
    console.log('schedule was previously set to', selectedSchedule)
    setSelectedSchedule(event.target.value);
    if (decisionType === 'Declined') {
      setSavedDeclinedSchedule(event.target.value);
      myStorage.setItem('savedDeclinedSchedule', event.target.value);
    }
    else {
      setSavedAdvancedSchedule(event.target.value);
      myStorage.setItem('savedAdvancedSchedule', event.target.value);
    }
  console.log('schedule has been set to', event.target.value)
  };


  useEffect(() => {
    if (settings) {
      setSettingsLoaded(true);
    }
  }, [settings]);

  useEffect(() => {      
    if (trans) {
      setThisCandidateScore(candidate?.resumeData?.totalTransferable);
     }
     else {
      setThisCandidateScore(candidate?.resumeData?.totalDirect);
     }
  }, [candidate, trans]);

  useEffect(() => {      
    if (candidate?.resumeData?.extracted?.personalDetails?.name) {
     setThisCandidateName(candidate?.resumeData?.extracted?.personalDetails?.name);
     }
    else {
     setThisCandidateName(candidate?.resumeData?.components?.atsCandidateInfo?.first_name +" "+candidate?.resumeData?.components?.atsCandidateInfo?.last_name);
    }
  }, [candidate]);

  useEffect(() => {      
    setThisCandidateJobId(candidate?.resumeData?.components.jobId);
  }, [candidate]);

  useEffect(() => { 
    let decisionDisplay = '';
    switch(decisionType) {
      case 'Declined':
        decisionDisplay = 'Reject';
        break;
      case 'Undeclined':
        decisionDisplay = 'Unreject';
        break;
      case 'Advanced':
        decisionDisplay = 'Advance';
        break;
      case 'Undeclined and Advanced':
        decisionDisplay = 'Unreject and Advance';
        break;
      default:
        // Handle default case
        decisionDisplay = 'Unknown Decision';
    };      
    
      if (listCriteria && listCriteria.length > 0) {
        setTitleValue(
        <div>
          {decisionDisplay}&nbsp;{numBulkCandidates} candidates with score &lt;= {listCriteria[0]}
        </div>
        );
        }
      else {
        setTitleValue(
          <div>
            {decisionDisplay}&nbsp;{thisCandidateName} with score of {thisCandidateScore}
            <br />
            {currentStage?.name}
         </div>
        );
      }
    }, [decisionType, listCriteria, numBulkCandidates, thisCandidateName, thisCandidateScore, currentStage]);

  const saveDecision= async (thisCandidate, decision,reason, template, schedule, targetStage, unrejectFirst) => {
    const currentDate = new Date();  
    const formattedDate = currentDate.toDateString(); 
    const userDetails = JSON.parse(localStorage.getItem('userDetails')) 
    const userName = userDetails.userName;
    let dataUpdate = {};
    if (['Declined','Advanced'].includes(decision)) {
      dataUpdate = { 'decision': decision, 'reason': reason?.name, 'decisionDate': formattedDate, 'userName': userName,
      'jobId': thisCandidate?.jobId , 'resumeId':thisCandidate?._id, 
      'reasonId': reason?.id , 'templateName': template?.name, 'templateId': template?.id, 'schedule': schedule};
    }
    else {
      dataUpdate = { 'decision': decision, 'decisionDate': formattedDate, 'userName': userName,
      'jobId': thisCandidate?.jobId , 'resumeId':thisCandidate?._id, 
       };
    }
    if (decision === 'Advanced') {
      dataUpdate['targetStage'] = targetStage
      dataUpdate['unrejectFirst'] = unrejectFirst
    }
    const response = await dispatch(updatedecisionThunk(dataUpdate));
  };

  const bulkSaveDecision= async (jobId, resumeIds, decision,reason, template, schedule, bulkAction) => {
    const currentDate = new Date();  
    const formattedDate = currentDate.toDateString(); 
    const userDetails = JSON.parse(localStorage.getItem('userDetails')) 
    const userName = userDetails.userName;
    const dataUpdate = { 'decision': decision, 'reason': reason?.name, 'decisionDate': formattedDate, 'userName': userName,
                        'jobId': jobId , 'resumeIds': resumeIds, 'bulkAction': bulkAction,
                        'reasonId': reason?.id , 'templateName': template?.name, 'templateId': template?.id, 'schedule': schedule};
    const response = await dispatch(bulkUpdatedecisionThunk(dataUpdate));

  };



  const handleSaveAction = async (event) => {
    let countDecision = 0;
    let reason = findReasonById(selectedReason);
    let template = findTemplateById(selectedEmailTemplate);
    let stage = selectedStage;
    let schedule = selectedSchedule;
    let jobId = "";
    if (listCriteria && resumes) { 
        jobId = resumes[0].jobId;
        let resumeIds = [];
        if (decisionType === 'Declined') {
            if (listCriteria.length > 0) {
              // Handle bulk Decline one candidate at a time
                resumes.forEach(item => {
                  let local_score = trans? parseFloat(item.resumeData?.totalTransferable) : parseFloat(item.resumeData?.totalDirect);
    //              console.log("at candidate", item);
                  if (local_score <= listCriteria[0]) {
    //                 console.log("Declining candidate with score", local_score, "which is at or below ", listCriteria[0]);
                    resumeIds.push(item._id);
                    countDecision++;
                  }
                });
                const bulkAction = "Declined candidates with score <= " + String(listCriteria[0]);
                await bulkSaveDecision(jobId, resumeIds, decisionType, reason, template, selectedSchedule, bulkAction);  
                // update resume (if called directly or indirectly through candidateView)
                if (propogateDecisionChange) {
                  await propogateDecisionChange();
                }   
            }
          }
          // Don't handle any other type of bulk decisions for now
      }
      else {
        // Single candidate decision scenario
          jobId = candidate.jobId;
          if (decisionType === 'Declined') {
            // Decline one candidate
            await saveDecision(candidate, decisionType, reason, template, selectedSchedule);
            if (candidate.resumeData.decision) {
              candidate.resumeData.decision.decision = decisionType
            }
            else {
              candidate.resumeData.decision = {'decision': decisionType}
            }
            countDecision++;  
          }
          else if (decisionType === 'Advanced') {
            await saveDecision(candidate, decisionType, reason, template,selectedSchedule, selectedStage);
            if (candidate.resumeData.decision) {
              candidate.resumeData.decision.decision = decisionType
            }
            else {
              candidate.resumeData.decision = {'decision': decisionType}
            }
            countDecision++;  
          }
          else if (decisionType === 'Undeclined') {
            await saveDecision(candidate, decisionType, reason, template, selectedSchedule);
            if (candidate.resumeData.decision) {
              candidate.resumeData.decision.decision = decisionType
            }
            else {
              candidate.resumeData.decision = {'decision': decisionType}
            }
            countDecision++;          
          }
          else if (decisionType === 'Undeclined and Advanced') {
            await saveDecision(candidate, "Undeclined", reason, template, selectedSchedule);          
            await saveDecision(candidate, "Advanced", reason, template, selectedSchedule, selectedStage, true);
            if (candidate.resumeData.decision) {
              candidate.resumeData.decision.decision = "Advanced"
            }
            else {
              candidate.resumeData.decision = {'decision': "Advanced"}
            }
            countDecision++;          
          }

          if (myDecisionChange) {
            await myDecisionChange(candidate);
          }
      }
      handleClose();
    };
    
useEffect( () => {
  if (decisionType === 'Declined') {
    if  ( selectedReason && 
          ( (selectedEmailTemplate === 0) || (selectedEmailTemplate && selectedSchedule) ) 
          ) {
        setDecisionDetailsSelected(true);
    }
  }
  else if (decisionType === 'Advanced' || decisionType === 'Undeclined and Advanced') {
    if ( (selectedEmailTemplate === 0) || (selectedEmailTemplate && selectedSchedule) ) {
      setDecisionDetailsSelected(true);
    }
  }
  else if (decisionType === 'Undeclined') {
    setDecisionDetailsSelected(true);
  }
 }, [decisionType, selectedReason, selectedEmailTemplate, selectedSchedule]); 


const StageButtons = ({ stages, currentStage}) => { 
  if (currentStage) {
  console.log("With currentStage as ", currentStage);
  console.log("Creating stage buttons for stages: ", stages);
  if (!Array.isArray(stages) || stages.length ===0 ) {
    return <div>No stages available</div>;
  }
  const sortedStages = [...stages].sort((a, b) => a.priority - b.priority);
  let reachedActiveStage = false;
  return (
  <div>
  { sortedStages.map((stage) => {
      const isActive = reachedActiveStage;
      if (stage.id === currentStage.id) { 
        reachedActiveStage = true;
      }
      const isSelected = stage.id === selectedStage;
      return (
        <>
        <button 
          key={stage.id}
          className={`stage-button ${isActive ? 'active' : ''} ${isSelected ? 'selected' : ''}` }
          disabled ={!isActive}
          onClick={() => {
              if (isActive) {
                console.log(`Selected stage: ${stage.name}`);
                setSelectedStage(stage.id); // Set the selected stage
                setDecisionDetailsSelected(true);
              }
            }}
        >
        {stage.name}
        </button>
        <br />
        </>
        );
      })}
  </div>
  );
}; 
}

const actions = [
  <Button onClick={handleClose} color="primary">
    Cancel
  </Button>,
  <Button  disabled={!decisionDetailsSelected} onClick={handleSaveAction} color="primary">
  Save
</Button>
];

  return (
    <DialogComponent
      show={show}
      open={show}
      title={titleValue}
      actions={actions}
      TransitionComponent={Transition}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle>
          <div style={{ width: "1100px"}}>
          {(decisionType === 'Declined') && (
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={4}>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel className={classes.inputLabel} shrink variant="standard" htmlFor="decline-reason">Reason</InputLabel>
                <Select
                  disabled={decisionType !== 'Declined'}
                  value={selectedReason}
                  onChange={handleReasonChange}
                  inputProps={{name:"reason", id:"decline-reason" }}
                  MenuProps={{ classes: { paper: classes.selectMenu } }}
                  style={{ width: "100%" }}  
                >
                  <MenuItem default key="0" value='0' disabled>{decisionType==='Declined' ? 'Select...' : 'Not Applicable'}</MenuItem>
                  {settingsLoaded && settings?.RejectionReasons?.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel className={classes.inputLabel} shrink variant="standard" htmlFor="send-schedule">Schedule</InputLabel>
                <Select
                  disabled={decisionType === 'Undeclined' || (selectedEmailTemplate === 0)}
                  value={selectedSchedule}
                  onChange={handleScheduleChange}
                  inputProps={{name:"schedule", id:"send-schedule" }}
                  MenuProps={{ classes: { paper: classes.selectMenu } }} 
                  style={{ width: "100%" }} 
                >
                  <MenuItem key="" value="" disabled>Select...</MenuItem>
                  <MenuItem key="Now" value="Now">Now</MenuItem>
                  <MenuItem key="In 2 Hours" value="In 2 Hours">In 2 Hours</MenuItem>
                  <MenuItem key="Today 6 PM" value="Today 6 PM">Today 6 PM</MenuItem>
                  <MenuItem key="Tomorrow 10 AM" value="Tomorrow 10 AM">Tomorrow 10 AM</MenuItem>
                  <MenuItem key="Tomorrow 6 PM" value="Tomorrow 6 PM">Tomorrow 6 PM</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel className={classes.inputLabel} shrink variant="standard" htmlFor="email-template">Email Template</InputLabel>
                <Select
                  disabled={decisionType === 'Undeclined'}
                  value={selectedEmailTemplate}
                  onChange={handleEmailTemplateChange}
                  onClose={handleDropdownClose}
                  onOpen={handleDropdownOpen}
                  onMouseLeave={handleMouseLeave}
                  inputProps={{ name:"template", id:"email-template" }}
                  MenuProps={{ classes: { paper: classes.selectMenu } }} 
                  style={{ width: "100%", maxWidth: 450 }} 
                >
                  <MenuItem value="" disabled>Select...</MenuItem>
                  {settingsLoaded && activeTemplates?.map((item) => (
                    <MenuItem key={item.id} value={item.id}
                    onMouseOver={(event) => handleTemplateHover(event,item)} 
                    onClick={(event) => handleMouseClick(event,item)} 
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          )}
       </div>
    </DialogTitle>
      <>
      {(decisionType === 'Declined') &&  (selectedEmailTemplate === 0 || selectedEmailTemplate) && (
        <Grid container justifyContent="left">
          <Grid item xs={5} maxWidth='xs'>
            <EmailTemplatePopup encodedHtml={findTemplateById(selectedEmailTemplate)?.fill_body} />
          </Grid>
        </Grid>
      )}
      {((decisionType === 'Advanced') ||  (decisionType === 'Undeclined and Advanced') ) && (
        <StageButtons stages={jobDetails?.job_stages} currentStage={currentStage} />
      )}
     </>      
    </DialogComponent>
  );
};

export default RecordDecision;
