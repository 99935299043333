import { createAsyncThunk } from "@reduxjs/toolkit";
import * as service from "./profileservice";

export const getProfilesThunk = createAsyncThunk(
  'profiles/getProfiles',
  async (jobId) => {
    const profiles = await service.getProfiles(jobId);
    console.log(profiles);
    return profiles;
  }
);

export const editProfileThunk = createAsyncThunk(
  'profiles/editProfile',
  async (profile) => {
    const updatedProfile = await service.profileEdit(profile);
    console.log(updatedProfile);
    return updatedProfile;
  }
);

export const addProfileThunk = createAsyncThunk(
  'profiles/addProfile',
  async (profile) => {
    const addedProfile = await service.profileAdd(profile);
    console.log(addedProfile);
    return addedProfile;
  }
);
