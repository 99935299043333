// BlujinAppBar.js
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
} from "@material-ui/core";
import { useMsal } from "@azure/msal-react";

import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import { useDispatch, useSelector } from "react-redux";
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MoreIcon from '@mui/icons-material/MoreVert';
import CreateJob from '../create_job/createjob';
import UploadJob from '../create_job/uploadjob';
import Greenhouse from "../create_job/Ats";
import NotificationComponent from "./notifications";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {ReactComponent as BlujinSearchIcon} from '../assets/images/searchIcon.svg';
import {ReactComponent as PlusIcon } from '../assets/images/PlusSign.svg';
import {ReactComponent as DownArrowIcon } from '../assets/images/DownArrow.svg';
import {ReactComponent as AnonymousUserIcon } from '../assets/images/anonymousUser.svg';
import {ReactComponent as NotificationsIcon } from '../assets/images/NotificationsIcon.svg';
import SearchComponent from "./SearchComponent";
import Profile from "./Profile";
import IconButtonWithAvatar from "./IconButtonWithAvatar";
  


import { makeStyles } from '@material-ui/core/styles';

import './blujinAppBar.css';

const useStyles = makeStyles((theme) => ({
  searchTypography: {
    color: '#8F8F8F',
    fontFamily: 'Poppins',
    fontSize: '1rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '1.375rem', /* 137.5% */
    letterSpacing: '0.01rem',
    textAlign: 'left',
    paddingLeft: "0.4%",
  },
}));

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: '0.25rem',
  backgroundColor: `rgba(217, 217, 217, 0.04)`,
  // backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: `rgba(217, 217, 217, 0.14)`,
    // backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
}));



const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

export default function BlujinAppBar({setLogInStatus}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorProfileEl, setAnchorProfileEl] = React.useState(null);
  const [anchorJobCreateEl, setAnchorJobCreateEl] = React.useState(null);
  const [numNotifications, setNumNotifications] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const isProfileMenuOpen = Boolean(anchorProfileEl);
  const isJobCreateMenuOpen = Boolean(anchorJobCreateEl);
  const [showDialog, setShowDialog] = React.useState(false);
  const [showATSDialog, setShowATS] = React.useState(false);
  const [showUploadDialog, setShowUploadDialog] = React.useState(false);
  const { instance } = useMsal();
  const [userDetails, setUserdetails] = React.useState(null);
  const [profileopen, setprofileopen]= useState(false);
  const settings = useSelector((state) => state.settings.basic);
  
useEffect(() => {
    const storedUserDetails = localStorage.getItem("userDetails");
    if (storedUserDetails) {
      setUserdetails(JSON.parse(storedUserDetails));

    }
}, []);

 
  const handleProfileMenuOpen = (event) => {
    setAnchorProfileEl(event.currentTarget);
  };

  const handleProfileMenuClose = (event) => {
    setAnchorProfileEl(null);
  };

  const handleprofile = (event) =>{
    setprofileopen(true)
    handleProfileMenuClose(event)

  }

  const handleJobCreateMenuOpen = (event) => {
    setAnchorJobCreateEl(event.currentTarget);
  };

  const handleJobCreateMenuClose = (event) => {
    setAnchorJobCreateEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCreateNewJob = () => {
    setShowDialog(true);
    handleJobCreateMenuClose();
  };
  const handleimportATS = () => {
    setShowATS(true);
    handleJobCreateMenuClose();
  };

  const handleImportNewJob = () => {
    setShowUploadDialog(true);
    handleJobCreateMenuClose();    
  };

  const handleClickCreateJob = (event) => {
    setAnchorJobCreateEl(event.currentTarget);
  };

  const handleCloseCreateJob = () => {
    setAnchorJobCreateEl(null);
  };

  const handleClickProfile = (event) => {
    setAnchorProfileEl(event.currentTarget);
  };

  const handleCloseProfile = () => {
    setAnchorProfileEl(null);
  };

  const handleCloseImportJob = () => {
    setAnchorEl(null);
  };

  const profileMenuId = 'primary-search-account-menu';
  const jobCreateMenuId = 'job-create-menu';

    const handleLogout = () => {
      console.log("Logging out")
      localStorage.removeItem("userDetails");
      localStorage.removeItem("token");
      const clientName = localStorage.getItem("clientName");  
      instance.logout({
        postLogoutRedirectUri: `${process.env.REACT_APP_postLogoutRedirectUri}${clientName}/`
      }).catch(e => {
        console.error(e);
      })
      setLogInStatus(false);
      console.log("Logged out")
    };

  const renderProfileMenu = (
    <Menu
      anchorEl={anchorProfileEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={profileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isProfileMenuOpen}
      onClose={handleProfileMenuClose}
    >
      <MenuItem onClick={handleprofile}>Profile</MenuItem>
      <MenuItem onClick={handleLogout}>Logout</MenuItem>
    </Menu>
  );

  const renderJobCreateMenu = (
    <>
    <Menu
      anchorEl={anchorJobCreateEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={jobCreateMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isJobCreateMenuOpen}
      onClose={handleJobCreateMenuClose}
    >
      <MenuItem onClick={handleCreateNewJob}>Manually Add Job</MenuItem>
      <MenuItem onClick={handleImportNewJob}>Import Job from File</MenuItem>
      {settings.ATS ? (
    
    <MenuItem onClick={handleimportATS}>Import Job from ATS</MenuItem>
  ) : (
    <Typography className="searchText-style">
    <MenuItem onClick={handleMenuClose}>Import Job from ATS</MenuItem>
    </Typography>
  )}
    </Menu>
    
    </>
  );
 
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar className="blujin-appbar">
          
          <SearchComponent/>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'none', md: 'flex' , alignItems: 'center'} }}>
            <IconButton 
  size="small" // Set the size to small
  aria-label="add a new job" 
  aria-controls={jobCreateMenuId} 
  aria-haspopup="true" 
  onClick={handleJobCreateMenuOpen} 
  color="inherit"
  sx={{ 
    borderRadius: 1, // Apply border-radius to make it rectangular
    backgroundColor: '#4787D2', // Set background color
    width: '3.7rem', // Set the width to make it small
    height: '1.8rem', // Set the height to make it small
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
    transition: 'box-shadow 0.3s',
    '&:hover::before': {
      content: '""',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '150%', // Adjust width to create oval shape
      height: '100%', // Height remains 100%
      borderRadius: '50%',
      background: 'rgba(71, 135, 210, 0.5)', // Adjust the color and opacity of the halo effect
      zIndex: 1,
    },
    '&:hover': { 
      backgroundColor: '#4787D2 !important',
    },
    '& .icon': {
      visibility: 'visible',
      transition: 'visibility 0s linear 0.3s',
    },
  }}
>
  <PlusIcon className="icon"/>
  <DownArrowIcon className="icon" style={{marginLeft: '0.5rem'}} />
</IconButton>
&nbsp; &nbsp;&nbsp; &nbsp;
            <NotificationComponent/>
            <IconButton
      size="large"
      edge="end"
      aria-label="account of current user"
      aria-controls={profileMenuId}
      aria-haspopup="true"
      onClick={handleProfileMenuOpen}
      color="inherit"
    >
            <IconButtonWithAvatar
            userDetails={userDetails}
          />
          </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderProfileMenu}
      {renderJobCreateMenu}
      <CreateJob show={showDialog} handleClose={() => setShowDialog(false)} />
      <UploadJob show={showUploadDialog} handleClose={() => setShowUploadDialog(false)} />
      <Greenhouse show = {showATSDialog} handleClose={() => setShowATS(false)}/>

      <Profile show={profileopen} handleClose={() => setprofileopen(false)} user={userDetails} key = {userDetails}/>

    </Box>
  );
}

