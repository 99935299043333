import React, { useState, useEffect } from 'react';
import { Button, Typography, Box } from '@mui/material';
import { Document, Page, pdfjs } from 'react-pdf';
import mammoth from 'mammoth/mammoth.browser';
import { useDropzone } from 'react-dropzone';
import { styled } from '@mui/system';
import Tooltip from '@mui/material/Tooltip';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const CustomTypography = styled(Typography)`
  color: var(--lt-text-dark, #2F3645);
  font-family: 'Poppins', sans-serif; /* Make sure the Poppins font is available */
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* equivalent to 183.333% */
`;
const SupportedFormatsText = styled('div')`
  color: var(--text-colour-light-text, #8F8F8F);
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
`;

const FileProcessor = ({ onAnalyzeText, onFileLoad }) => {
  const [file, setFile] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [wordContent, setWordContent] = useState('');
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: '.pdf,.docx, .doc',
    onDrop: (acceptedFiles) => {
      handleFileChange(acceptedFiles[0]);
    },
  });

  

  const handleFileChange = (e) => {
    const fileURL = URL.createObjectURL(e);
    setFile(fileURL);
    if (onFileLoad) {
      onFileLoad(e);
      
    }
    if (e.name.endsWith('.pdf')) {
      handlePDF(fileURL);
      setFileType('pdf');
    } else if (e.name.endsWith('.docx') || e.name.endsWith('.doc')) {
      handleWord(e);
      setFileType('docx');
    }

  };

  const handlePDF = (fileURL) => {
    pdfjs.getDocument(fileURL).promise.then((pdf) => {
      let content = '';

      for (let i = 1; i <= pdf.numPages; i += 1) {
        pdf.getPage(i).then((page) => {
          page.getTextContent().then((textContent) => {
            let lastY, text = '';
            for (let item of textContent.items) {
              if (lastY === item.transform[5] || !lastY) {
                text += item.str;
              } else {
                text += '<br/>' + item.str;
              }
              lastY = item.transform[5];
            }
            content += '<p>' + text + '</p>';

            if (i === pdf.numPages) {
              setNumPages(i);
              // onAnalyzeText(content);
            }
          });
        });
      }
    });
  };

  const handleWord = (file) => {
    const reader = new FileReader();
    reader.onload = function (event) {
      mammoth.convertToHtml({ arrayBuffer: event.target.result })
        .then(displayResult)
        .catch(handleError);
    };
    reader.readAsArrayBuffer(file);
    
  };

  const displayResult = (result) => {
    setWordContent(result.value);
    // Create a temporary DOM element
    var tempElement = document.createElement('div');
    tempElement.innerHTML = result.value;
    // Find and remove all <img> tags
    var imgTags = tempElement.getElementsByTagName('img');
    for (var i = imgTags.length - 1; i >= 0; i--) {
        imgTags[i].parentNode.removeChild(imgTags[i]);
    }

    // Get the cleaned HTML as a string
    var cleanedHtml = tempElement.innerHTML;
    console.log(cleanedHtml);
  
    // onAnalyzeText(cleanedHtml);
  };

  const handleError = (error) => {
    console.log(error);
  };

  return (
    <div className="jobAndResumesUploader">
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <Typography variant="subtitle1" gutterBottom>
          Job Description 
        </Typography>
        <Box
            sx={{
              border: '2px dashed #ccc',
              padding: 3,
              minHeight: 100,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              bgcolor: isDragActive ? '#f0f0f0' : 'rgba(71, 135, 210, 0.10)',
            }}
          >
            <Box sx={{ textAlign: 'center'}}>
              <svg width="24" height="24" viewBox="0 0 24 24">
                <circle cx="12" cy="12" r="10" fill="#4787D2" />
                <line x1="12" y1="6" x2="12" y2="18" stroke="white" strokeWidth="2" />
                <line x1="6" y1="12" x2="18" y2="12" stroke="white" strokeWidth="2" />
              </svg>
              {isDragActive ? (
              <CustomTypography variant="body1"> Drop the files here ...</CustomTypography>
            ) : (
              
              <CustomTypography variant="body1">  Drag & drop job description here or <span style={{ color: '#4787D2' }}>browse</span>
              <br/>
              <Tooltip title="pdf and word" placement="top">
          <SupportedFormatsText>
            View supported formats ⓘ
          </SupportedFormatsText>
        </Tooltip>
              </CustomTypography>
            )}
          </Box>
              
            </Box>
          
      </div>
      {file && fileType === 'pdf' && (
        <div style={{ backgroundColor: 'grey', overflowY: 'scroll', height: '500px', padding: '20px', margin: '20px 0' }}>
          <Document file={file} renderMode="canvas">
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document>
        </div>
      )}
      {file && fileType === 'docx' && (
        <div>
          <iframe title="docx" srcDoc={wordContent} style={{ width: '100%', height: '500px' }} />
        </div>
      )}
    </div>
  );
};

export default FileProcessor;
