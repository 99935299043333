import { createAsyncThunk, updatebasic } from "@reduxjs/toolkit";
import * as service from "./settingsservice";

export const basicThunk = createAsyncThunk(
    'settings/getbasic',
    async () => {
      const j = await service.getbasic();
      return j;
    }
  );

export const updatebasicThunk = createAsyncThunk(
  'settings/updatebasic',
  async({update}) => {
    console.log("log from update ",update)
    const j = await service.updatebasic(update);
    return j;
  }
);