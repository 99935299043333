import { createAsyncThunk } from "@reduxjs/toolkit";
import * as service from "./userservice";

export const userThunk = createAsyncThunk(
    'user/getallusers',
    async () => {
      const j = await service.getUsers();
      return j;
    }
  );

export const inviteThunk = createAsyncThunk(
  'user/inviteusers',
  async(users)=>{
    const j = await service.inviteuser(users)
    return j;
  }
)
export const deleteThunk = createAsyncThunk(
  'user/deleteuser',
  async(userId)=>{
    const j = await service.deleteuser(userId)
    return j;
  }
)

export const updateThunk = createAsyncThunk(
  'user/updateuser',
  async(data)=>{
    const j = await service.updateuser(data)
    return j;
  }
)