import React, { useState, useEffect  } from 'react';
import { Button, Box, Typography,  MenuItem, FormControl,  OutlinedInput,Backdrop, CircularProgress } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Menu from '@mui/material/Menu';
import CreateJob from '../create_job/createjob';
import Joblist from '../joblist/joblist';
import UploadJob from '../create_job/uploadjob';
import { getJobThunk } from '../services/jobthunk';
import { useSelector, useDispatch } from "react-redux";
import {ReactComponent as PlusIcon } from '../assets/images/PlusSign.svg';
import nojobs from "../assets/images/WelcomeBlujinRoundIcon.svg";
import { makeStyles } from '@material-ui/core/styles';
import Insighthome from '../insights/insights';
import { basicThunk } from '../services/settingsThunk';

const Home = () => {
  const dispatch = useDispatch();

  const useStyles = makeStyles((theme) => ({
    
    welcome: {
      color: '#172B4D !important',
      fontSize: '15px !important',
      fontWeight: '550 !important',
    },
    Add:{
      color: 'var(--text-colour-midtone-grey, #626F86) !important',
      fontSize: '20px !important',
      fontWeight: '400 !important',

    },
    searchTypography: {
      color: '#8F8F8F',
      fontFamily: 'Poppins',
      fontSize: '1rem',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '1.375rem', /* 137.5% */
      letterSpacing: '0.01rem',
      textAlign: 'left',
      paddingLeft: "0.4%",
    },

}));


 
  const currentjob = useSelector((state) => state.job);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showDialog, setShowDialog] = React.useState(false);
  const [showUploadDialog, setShowUploadDialog] = React.useState(false);
  const [job, setjob] = React.useState(currentjob.jobs);
  const classes = useStyles();
  const [showInsightHome, setShowInsightHome] = useState(true);
  const [scrollPosition, setScrollPosition] = useState(0);
  


  if(currentjob.jobs && currentjob.jobs.length > 1){
    console.log(JSON.parse(currentjob.jobs[0]));
  }else{
    console.log('Data not available');
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    dispatch(getJobThunk());
    dispatch(basicThunk());
  }, []); 

  const handleClose = () => {
    console.log(currentjob.jobs)
    setAnchorEl(null);
  };

  if (currentjob.specficstate==='loading') {
    
    return (<Box sx={{ height: '100%' }}>
    <Backdrop open={true} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: '#fff' }}>
      <CircularProgress color="inherit" />
    </Backdrop>
  </Box>);
  }

  const handleCreate = () => {
    setShowDialog(true);
    handleClose();
  };

  const handleImport = () => {
    setShowUploadDialog(true);
    handleClose();
    
  };
  const handleScroll = (e) => {
    const currentScrollPosition = e.target.scrollTop;
    console.log("scrolling")

    if (currentScrollPosition > scrollPosition && currentScrollPosition > 10) {
      showInsightHome(false);
    } else {
      showInsightHome(true);
    }

    setScrollPosition(currentScrollPosition);
  };
  
  
  
  return (
     
    <Box sx={{ height: '100%',  }}>
      <Box>
      {
  showInsightHome && <Insighthome />
}
        
        {
          (!currentjob.jobs || currentjob.jobs.length === 0) ?
            (
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', position: 'relative', marginTop: '2%' }}>
                <Typography className={classes.welcome}>
                  Welcome to blujin !
                </Typography>
                <br />
                <img src={nojobs} alt="No Jobs"/>
                <br />
                <Typography className={classes.Add}>
                Add your first Job
                </Typography>
                <br />
                <Button variant="contained" style={{ backgroundColor: '#4787D2' }} onClick={handleClick} startIcon={<PlusIcon />}>
                  Job
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                >
                  <MenuItem onClick={handleCreate}>Manually Add Job</MenuItem>
                  <MenuItem onClick={handleImport}>Import Job from File</MenuItem>
                  <MenuItem onClick={handleClose} className={classes.searchTypography}>Import Job from ATS</MenuItem>
                </Menu>
                <CreateJob show={showDialog} handleClose={() => setShowDialog(false)} />
                <UploadJob show={showUploadDialog} handleClose={() => setShowUploadDialog(false)} />
              </Box>
            ) :
            (
              <Joblist rows={currentjob?.jobs} onScroll={handleScroll}/>
            )
        }
      </Box>
    </Box>
  );
}

export default Home;
