import { createAsyncThunk } from "@reduxjs/toolkit";
import * as service from "./getnotifications";


export const getnotifcationThunk = createAsyncThunk(
    'notification/getnotifcation',
    async () => {
      const n = await service.getnotifications();
      const parsedNotifications = n.map(item => JSON.parse(item));
      return parsedNotifications
    }
  );