import React, { useState, useEffect } from 'react';
import {
    Card,
    CardContent,
    Typography,
    Chip,
    Container,
    Grid,
    IconButton,
    Tooltip, Box
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import BarChartIcon from '@mui/icons-material/BarChart';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import ChartComponent from '../../components/charts/ChartComponent';
import ParallelChartComponent from '../../components/charts/ParallelChartComponent';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import ProfileComponent from '../../profiles/createprofile/profile';
import { useDispatch, useSelector } from 'react-redux';
import { getProfilesThunk, editProfileThunk, addProfileThunk } from '../../services/profilethunk';

function Profiles({ jobId }) {
    const dispatch = useDispatch();
    const [currentChart, setCurrentChart] = useState('ChartComponent');
    const [editingProfileIndex, setEditingProfileIndex] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [isAdding, setIsAdding] = useState(false);

    const settings = useSelector((state) => state.settings.basic);
    console.log("settings",settings)

    const [profiles, setprofile] = useState([]);

    useEffect(() => {
        dispatch(getProfilesThunk(jobId));
    }, [dispatch, jobId]);


    const profileSet = useSelector((state) => state.profile);


    useEffect(() => {
        setprofile(profileSet.profiles);
    }, [profileSet.profiles]);

    const toggleChart = () => {
        setCurrentChart((prev) => (prev === 'ChartComponent' ? 'ParallelChartComponent' : 'ChartComponent'));
    };

    const handleProfileSubmit = async (skills, title) => {
        const updatedProfiles = [...profiles];
        const profileToEdit = {
            ...updatedProfiles[editingProfileIndex]
        };

        profileToEdit.skills = skills;
        profileToEdit.profileName = title;
        updatedProfiles[editingProfileIndex] = profileToEdit
        await setprofile(updatedProfiles)
        await dispatch(editProfileThunk(profileToEdit));
        
        await dispatch(getProfilesThunk(jobId))
        setEditingProfileIndex(null);
        setIsEditing(false);
    };

    const handleAdd = async (skills, title) => {
        const updatedProfiles = JSON.parse(JSON.stringify(profiles));
        updatedProfiles[editingProfileIndex].skills = skills;
        updatedProfiles[editingProfileIndex].profileName = title;
        updatedProfiles[editingProfileIndex].profileType = "user";
        await dispatch(addProfileThunk(updatedProfiles[editingProfileIndex]));
        await dispatch(getProfilesThunk(jobId))

        setEditingProfileIndex(null);
        setIsAdding(false);
        
    };

    const getFilteredProfiles = (profiles, settings) => {
        return settings.multipleProfile 
            ? profiles.filter(profile => profile.profileType === 'default') 
            : profiles;
    };

    return (

        <Container >

            <div style={
                {
                    display: 'flex',
                    justifyContent: 'flex-end'
                }
            }>

                {settings?.multipleprofiles && (
                    <IconButton
                        onClick={() => {
                            const index = profiles.findIndex(obj => obj.profileType === "system");
                            setEditingProfileIndex(index);
                            setIsAdding(true);
                        }}
                    >
                        Add
                        <Tooltip title="ADD Profile" placement="left">
                            <AddIcon />
                        </Tooltip>
                    </IconButton>
                )}
            </div>


            <Box maxHeight="calc(100vh - 21rem)" style={{ display: 'flex', flexDirection: 'column', height: '100%', overflow: 'auto' }}>
                <Box >
                    {
                        getFilteredProfiles(profiles, settings).map((profile, index) => (
                            <Card key={index}
                                variant="outlined"
                                style={
                                    { marginBottom: '20px' }
                                }>
                                <Grid container >
                                    <Grid item
                                        xs={6}>
                                        <CardContent>
                                            <Typography variant="h5"
                                                style={
                                                    { paddingBottom: '16px' }
                                                }>
                                                {
                                                    profile['jobDetails']['jobTitle']
                                                }
                                                {
                                                    profile['profileType'] && (
                                                        <>
                                                            <ArrowForwardIcon style={
                                                                { paddingLeft: '10px' }
                                                            } /> {
                                                                profile['profileName']
                                                            } </>
                                                    )
                                                } </Typography>
                                            {/* High Priority Skills */}
                                            <div style={
                                                { paddingBottom: '16px' }
                                            }>
                                                <Typography variant="h5"
                                                    style={
                                                        { paddingBottom: '16px', color: '#003366' }
                                                    }>
                                                    High Priority
                                                </Typography>
                                                {
                                                    profile.skills.filter((skill) => skill.criticality === 'High').map((skill, i) => (
                                                        <Chip key={i}
                                                            label={
                                                                skill.skillName
                                                            }
                                                            style={
                                                                { margin: '5px' }
                                                            } />
                                                    ))
                                                } </div>
                                            {/* Medium Priority Skills */}
                                            <div style={
                                                { paddingBottom: '16px' }
                                            }>
                                                <Typography variant="h5"
                                                    style={
                                                        { paddingBottom: '16px', color: '#8EDBEC' }
                                                    }>
                                                    Medium Priority
                                                </Typography>
                                                {
                                                    profile.skills.filter((skill) => skill.criticality === 'Medium').map((skill, i) => (
                                                        <Chip key={i}
                                                            label={
                                                                skill.skillName
                                                            }
                                                            style={
                                                                { margin: '5px' }
                                                            } />
                                                    ))
                                                } </div>
                                            {/* Low Priority Skills */}
                                            <div style={
                                                { paddingBottom: '16px' }
                                            }>
                                                <Typography variant="h5"
                                                    style={
                                                        { paddingBottom: '16px', color: '#DDDDDD' }
                                                    }>
                                                    Low Priority
                                                </Typography>
                                                {
                                                    profile.skills.filter((skill) => skill.criticality === 'Low').map((skill, i) => (
                                                        <Chip key={i}
                                                            label={
                                                                skill.skillName
                                                            }
                                                            style={
                                                                { margin: '5px' }
                                                            } />
                                                    ))
                                                } </div>
                                        </CardContent>
                                    </Grid>
                                    <Grid item
                                        xs={6}>
                                        <div style={
                                            {
                                                display: 'flex',
                                                justifyContent: 'flex-end'
                                            }
                                        }>
                                            <IconButton onClick={
                                                () => {
                                                    setEditingProfileIndex(index);
                                                    setIsEditing(true);
                                                }
                                            }>
                                                <Tooltip title="Edit Profile" placement="left">
                                                    <EditIcon />
                                                </Tooltip>
                                            </IconButton>

                                        </div>
                                        {
                                            currentChart === 'ChartComponent' ? (
                                                <div style={
                                                    {
                                                        height: '500px',
                                                        width: '100%'
                                                    }
                                                }>
                                                    <ChartComponent key={JSON.stringify(profile.skills)} skillsData={
                                                        profile.skills
                                                    } />
                                                </div>
                                            ) : (
                                                <div>
                                                    <ParallelChartComponent key={JSON.stringify(profile.skills)} skillsData={
                                                        profile.skills
                                                    } />
                                                </div>
                                            )
                                        } </Grid>
                                </Grid>
                            </Card>
                        ))
                    }
                </Box>
            </Box>
            {
                isEditing && (
                    <ProfileComponent show={isEditing}
                        handleClose={
                            () => setIsEditing(false)
                        }
                        initialSkillsData={
                            profiles[editingProfileIndex].skills
                        }
                        initialTitle={
                            profiles[editingProfileIndex].profileName
                        }
                        type={"edit"}
                        onSubmit={handleProfileSubmit} />
                )
            }
            {
                isAdding && (
                    <ProfileComponent show={isAdding}
                        handleClose={
                            () => setIsAdding(false)
                        }
                        initialSkillsData={
                            profiles[editingProfileIndex].skills
                        }
                        initialTitle={
                            profiles[editingProfileIndex].profileName
                        }
                        onSubmit={handleAdd} />
                )
            } </Container>


    );
}

export default Profiles;
