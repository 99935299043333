import { createAsyncThunk } from "@reduxjs/toolkit";
import * as service from "./atsservice";


export const getatsJoblistThunk = createAsyncThunk(
  'jobs/get-ats-jobs',
  async () => {
    const j = await service.getatsJoblist();
    return j;
  }
);

export const submitJobThunk = createAsyncThunk(
  'jobs/submit-ats-jobs',
  async (job) => {
    const j = await service.submitjob(job);
    return j;
  }
);
