import React, { useEffect, useState } from 'react';
import { useParams, Navigate,useNavigate } from 'react-router-dom';
import { useMsal } from "@azure/msal-react";
import axios from "axios";

const ClientRouteValidator = ({ children }) => {


  const { clientName } = useParams();
  const { instance } = useMsal();
  const navigate = useNavigate()
  // const allowedClientNames = ['testinc', 'clientdemo', 'inspring'];
  const currentPath = window.location.pathname;
  const API_BASE = process.env.REACT_APP_API
  const clientApiUrl = `${API_BASE}/main/is-valid-client`;
  const [isClientAllowed, setIsClientAllowed] = useState(null);

  useEffect(() => {
    const previousClientName = localStorage.getItem("clientName");
    if (previousClientName && previousClientName === '404') {
      localStorage.setItem("clientName", clientName);
      localStorage.removeItem("userDetails");
      localStorage.removeItem("token");
      // instance.loginRedirect({
      //   redirectUri: `${process.env.REACT_APP_postLogoutRedirectUri}`
      // }).catch(e => {
      instance.logout({
          postLogoutRedirectUri: `${process.env.REACT_APP_postLogoutRedirectUri}${clientName}/`
        }).catch(e => {
          console.error(e);
      });
    }
    else if (previousClientName && previousClientName !== clientName) {
      localStorage.setItem("clientName", clientName);
      localStorage.removeItem("userDetails");
      localStorage.removeItem("token");
      instance.logout({
        postLogoutRedirectUri: `${process.env.REACT_APP_postLogoutRedirectUri}${clientName}/`
      }).catch(e => {
        console.error(e);
      });
    } else {
      const targetPath = `/${clientName}`;
      if(!localStorage.getItem("userDetails")){
      if (currentPath !== `/${clientName}`) {
        navigate(targetPath);
    }
    localStorage.setItem("clientName", clientName);
  }
  }
}, [clientName, instance]);

useEffect(() => {
  // Function to check if the client name is allowed
  const checkClientName = async () => {
    try {
      const response = await axios.get(clientApiUrl, { params: { "clientName": clientName } });
      setIsClientAllowed(response.data.data); // Update state based on API response
    } catch (error) {
      console.error('Error fetching client validation:', error);
      setIsClientAllowed(false); // Assume not allowed on error
    }
  };

  checkClientName(); // Call the function
}, [clientName]); // Dependency array

  // Redirect if the client is not allowed
  if (isClientAllowed === false) {
    return <Navigate to="/404" replace />;
  }


  return children;
};

export default ClientRouteValidator;
