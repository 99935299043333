// IconButtonWithAvatar.js
import React from 'react';
import { IconButton, Avatar } from '@mui/material';

const IconButtonWithAvatar = ({ userDetails,sx }) => {
    const getInitials = (name) => {
        if(name){
        return name
          .split(" ")
          .slice(0, 2)
          .map((word) => word[0])
          .join("")
          .toUpperCase();}
      };
      
    const getColorForName = (name) => {
        if(name){
        let hash = 0;
        let i;
      
        for (i = 0; i < name.length; i += 1) {
          hash = name.charCodeAt(i) + ((hash << 5) - hash);
        }
      
        let color = "#";
      
        for (i = 0; i < 3; i += 1) {
          const value = (hash >> (i * 8)) & 0xff;
          color += `00${value.toString(16)}`.slice(-2);
        }
      
        return color;
      }
      };
      

  return (
    
      <Avatar
        src={userDetails?.image}
        sx={{
          backgroundColor: getColorForName(userDetails?.userName),
          marginRight: "8px",
          ...sx, 
        }}
      >
        {getInitials(userDetails?.userName)}
      </Avatar>

  );
};

export default IconButtonWithAvatar;
