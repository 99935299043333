import React, { useState, useRef } from 'react';
import { Grid, TextField, MenuItem, Select, InputLabel, FormControl, Avatar, IconButton, Box } from '@mui/material';
import edit from "../assets/images/edit.svg";
import IconButtonWithAvatar from "./IconButtonWithAvatar";

const Profileform = ({ user }) => {
  const [firstName, setFirstName] = useState(user?.firstName);
  const [lastName, setLastName] = useState(user?.lastName);
  const [username, setUsername] = useState(user?.userName);
  const [email, setEmail] = useState(user?.email);
  const [pronouns, setPronouns] = useState(user?.pronouns);
  const [phoneNumber, setPhoneNumber] = useState(user?.phoneNumber);
  const [timeZone, setTimeZone] = useState(user?.timeZone);
  const [avatarUrl, setAvatarUrl] = useState(user?.image); // State to hold the avatar URL

  const fileInputRef = useRef(null);

  const handleAvatarClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setAvatarUrl(reader.result); // Update the avatar URL state
      };
      reader.readAsDataURL(file);
    }
  };
  
  const handleChange = (setter) => (event) => {
    setter(event.target.value);
  };

  return (
    <Grid container spacing={3} style={{backgroundColor:"inherit"}}>
      <Grid item xs={12}>
        <Box position="relative" display="inline-flex">
          {avatarUrl ? (
            <Avatar
              src={avatarUrl}
              sx={{ width: 80, height: 80 }}
            />
          ) : (
            <IconButtonWithAvatar
              userDetails={user}
              sx={{ width: 80, height: 80 }}
            />
          )}
          <IconButton
            onClick={handleAvatarClick}
            size="small"
            sx={{
              position: 'absolute',
              bottom: -3,
              right: 5,
            }}
          >

        <img src={edit} alt={"edit"}/>
          </IconButton>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            accept=".jpg, .png"
            onChange={handleFileChange}
          />
        </Box>
      </Grid>
      {/* Text fields for user information */}
      <Grid item xs={12} sm={6}>
        <TextField label="First Name" variant="outlined" fullWidth value={firstName} onChange={handleChange(setFirstName)} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField label="Last Name" variant="outlined" fullWidth value={lastName} onChange={handleChange(setLastName)} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField label="User Name" variant="outlined" fullWidth value={username} onChange={handleChange(setUsername)} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField disabled label="Email" variant="outlined" fullWidth value={email} onChange={handleChange(setEmail)} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField label="Pronouns" variant="outlined" fullWidth value={pronouns} onChange={handleChange(setPronouns)} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField label="Phone Number" variant="outlined" fullWidth value={phoneNumber} onChange={handleChange(setPhoneNumber)} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <InputLabel id="time-zone-label">Time Zone</InputLabel>
          <Select
            labelId="time-zone-label"
            id="time-zone-select"
            value={timeZone}
            label="Time Zone"
            onChange={handleChange(setTimeZone)}
          >
            <MenuItem value={'GMT-08:00'}>(GMT-08:00) Eastern Time - New York</MenuItem>
            {/* Add more time zones as MenuItem components */}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default Profileform;
