import React, { useState, useEffect } from 'react';
import { getCandidateonScreenThunk } from '../../services/resume_thunk';
import { useDispatch } from 'react-redux';
import { Typography, IconButton, Box, Paper } from '@mui/material';
import DepartmentIcon from "../../assets/images/department.svg";
import Experience from "../../assets/images/Experience.svg";
import location from "../../assets/images/location.svg";
import user from "../../assets/images/user.svg";

function JobDescription({ jobDetails }) {
  const [pdfSrc, setPdfSrc] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    // Define the async function within the useEffect
    const fetchPDF = async () => {
      try {
        const pdf = await dispatch(getCandidateonScreenThunk(jobDetails?.blobInfo?.path));
        setPdfSrc(pdf.payload);
//        console.log("PDF Source:", pdf);
      } catch (error) {
        console.error("Error while fetching PDF:", error);
        // Perhaps update some state here to show an error message to the user
      }
    };

    // Now call the function
    fetchPDF();

  }, [jobDetails?.blobInfo?.path, dispatch]);  // Added dependencies


  return (
    <div style={{padding:"0px"}}>
      <Paper square elevation={3} style={{padding:"2%", 
  display: 'flex', 
  alignItems: 'center', 
  justifyContent: 'center', 
  width: '100%',

}} >
      <Box> 
        <Typography variant="h6">
        <Box width="100%">
  <Typography variant="h6">
    <Box 
      display="flex" 
      justifyContent="flex-start"
      flexWrap="wrap" 
      width="100%"
      gap={10}
    >
      <Box display="flex" alignItems="center" gap={1} >
        <img src={DepartmentIcon} alt="Department Icon" /> <span>{jobDetails?.department} <br/> Department</span>
        
      </Box>
      <Box display="flex" alignItems="center" gap={1} >
        <img src={Experience} alt="Job Type Icon" /> <span>{jobDetails?.jobType} <br/> Job Type </span>
      </Box>
      <Box display="flex" alignItems="center" gap={1} >
        <img src={location} alt="location Icon" /> <span>{jobDetails?.location} <br/> Location </span>
      </Box>
      <Box display="flex" alignItems="center" gap={1} >
        <img src={user} alt="user" /> <span>{jobDetails?.hiringManager} <br/> Hiring Manager</span>
      </Box>
    </Box>
  </Typography>
</Box>

        </Typography>
      </Box>
      </Paper>
      <br/>
      <Paper square elevation={3} style={{padding:"0.5%", 
  display: 'flex', 
  alignItems: 'center', 
  justifyContent: 'center', 
  width: '100%',  
}} >
      <iframe
  src={pdfSrc}
  style={{ width: '95%', height: '54vh', border: 'none', overflow: 'auto' }}
  title="Embedded content"
></iframe>
</Paper>

    </div>
  );
}


export default JobDescription;
