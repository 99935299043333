import axios from 'axios';

//const API_BASE = 'http://127.0.0.1:8000';
const API_BASE = process.env.REACT_APP_API;
const API = `${API_BASE}/main/profile/`;

// Create an axios instance with default headers
const instance = axios.create({
  baseURL: API,
  headers: {}
});
// Add a request interceptor to the instance
instance.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = token;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export const getProfiles = async (jobId) => {
  try {
    const response = await instance.get('get-profile', { params: { jobId: jobId }});
    return response.data.data;
  } catch (error) {
    console.error("Error in getProfiles:", error);
    throw error;
  }
};

export const profileEdit = async (profile) => {
  const prof = { profile };
  const response = await instance.post('update-profile', prof);
  console.log(response);
  return response;
};

export const profileAdd = async (profile) => {
  const prof = { profile };
  const response = await instance.post('add-profile', prof);
  console.log(response);
  return response;
};
